import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-step-progress-bar/styles.css';
import classnames from 'classnames';
import { ProgressBar as ReactProgressBar, Step } from 'react-step-progress-bar';
import DocProgressBarStyles from './__styles__/DocProgressBar.styles';

import { DOC_REVISION_VERBOSE_STATUS } from '../../../../constants/enums';

export class DocProgressBar extends PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  determineStyle = () => {
    const { status, classes, theme } = this.props;
    const pageStyles = DocProgressBarStyles(theme);

    const validSteps = Object.keys(DOC_REVISION_VERBOSE_STATUS);

    const barStyle = {
      step1: '',
      step2: '',
      barColor: validSteps.includes(status) ? pageStyles[status].backgroundColor : pageStyles.notDoneColor.backgroundColor,
      percentComplete: 0
    };

    const doneColorClass = classes[status];

    switch (status) {
      case 'approved':
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.endCircles, doneColorClass);
        barStyle.percentComplete = 100;
        break;
      case 'revoked':
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.endCircles, classes.revokedColor);
        barStyle.percentComplete = 100;
        break;
      default:
        barStyle.step1 = classnames(classes.endCircles, doneColorClass);
        barStyle.step2 = classnames(classes.endCircles, classes.notDoneStep);
        barStyle.percentComplete = 0;
        break;
    }

    return barStyle;
  };

  render() {
    const barStyle = this.determineStyle();

    return (
      <div>
        <ReactProgressBar
          percent={barStyle.percentComplete}
          stepPositions={[0, 100]}
          unfilledBackground={'rgba(235,238,241, 0.8)'}
          filledBackground={barStyle.barColor}
          height={4}
        >
          <Step>{() => <div className={barStyle.step1} />}</Step>
          <Step>{() => <div className={barStyle.step2} />}</Step>
        </ReactProgressBar>
      </div>
    );
  }
}
export default withStyles(DocProgressBarStyles)(DocProgressBar);

export const columns = [
  {
    title: 'Proveedor',
    name: 'supplierName'
  },
  {
    title: 'Categoría',
    name: 'categoryName'
  },
  {
    title: 'zona geográfica',
    name: 'geographicLocationName'
  }
];

export const mapData = scoringExcludes =>
  scoringExcludes.map(scoring => {
    return {
      id: scoring.id,
      supplierName: scoring.supplier ? scoring.supplier.name : '--',
      categoryName: scoring.category ? scoring.category.name : '--',
      geographicLocationName: scoring.geographicLocation
        ? scoring.geographicLocation.description
        : '--',
      supplierId: scoring.supplier ? scoring.supplier.id : '',
      categoryId: scoring.category ? scoring.category.id : '',
      geographicLocationId: scoring.geographicLocation
        ? scoring.geographicLocation.id
        : ''
    };
  });

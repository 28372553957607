import _pickBy from 'lodash/pickBy';

export const listSanitizer = params => {
  const { page, perPage } = params;
  const sanitizedParams = {
    page,
    perPage
  };

  return _pickBy(sanitizedParams, filter => !!filter);
};

export const createSanitizer = ({
  supplierId,
  categoryId,
  geographicLocationId
}) => {
  const sanitizedParams = {
    supplierId: supplierId && String(supplierId),
    categoryId: Number(categoryId),
    geographicLocationId: Number(geographicLocationId)
  };

  return _pickBy(sanitizedParams, filter => !!filter);
};

export const updateSanitizer = ({
  supplierId,
  categoryId,
  geographicLocationId
}) => {
  const sanitizedParams = {
    supplierId: supplierId && String(supplierId),
    categoryId: Number(categoryId),
    geographicLocationId: Number(geographicLocationId)
  };

  return _pickBy(sanitizedParams, filter => !!filter);
};

import _uniq from 'lodash/uniq';
import { history } from './history';

import PortalRegisterFinancingPage from '../components/pages/PortalRegisterFinancingPage';
import PortalContractInformationPage from '../components/pages/PortalContractInformationPage';
import PortalSelectArticlesPage from '../components/pages/PortalSelectArticlesPage';
import PortalPromissoryPage from '../components/pages/PortalPromissoryPage';
import PortalIDCheck from '../components/pages/PortalIDCheck';
import PortalValidateInvoicePage from '../components/pages/PortalValidateInvoice';
import PortalCosignerDataPage from '../components/pages/PortalCosignerData';
import PortalSupplierDataPage from '../components/pages/PortalSupplierDataPage';
import PortalEndSalePage from '../components/pages/PortalEndSale';
import Scoring from '../components/pages/Scoring';

import TeleshoppingRegistration from '../components/pages/TeleshoppingRegistration';
import TeleshoppingEndPage from '../components/pages/TeleshoppingEndPage';

import DigitalSaleIdentityValidation from '../components/pages/DigitalSaleIdentityValidation';
import DigitalSaleListContracts from '../components/pages/DigitalSaleListContracts';
import DigitalSaleOTPSale from '../components/pages/DigitalSaleOTPSale';
import DigitalSaleRegisterIdentity from '../components/pages/DigitalSaleRegisterIdentity';
import DigitalSaleUnmaterializedPromissory from '../components/pages/DigitalSaleUnmaterializedPromissory';
import DigitalSaleOTPPromissory from '../components/pages/DigitalSaleOTPPromissory';
import DigitalSaleContactData from '../components/pages/DigitalSaleContactData';
//digitalSaleWithUUP = When user is on facial DB and has Unmaterialized Unique Promissory
//digitalSaleWithouFaceId = When user is not on DB
//digitalSaleWithoutUUPonContract = digital sale without Unmaterialized Unique Promissory on contract specified

import InsuranceContractValidation from '../components/pages/InsuranceContractValidation';
import InsuranceClientValidation from '../components/pages/InsuranceClientValidation';
import InsuranceSummary from '../components/pages/InsuranceSummary';
import RemoteSaleEnrollmentOTP from '../components/pages/RemoteSaleEnrollmentOTP';

export function generateRoutes(process, globalSettings) {
  const processRoute = isDigitalSale(process)
    ? 'digital-sale'
    : isDigitalSaleDocumentRevision(process)
      ? 'digital-sales-document-revision'
      : isRemoteDigitalSale(process)
        ? 'remote-sale'
        : isRemoteTeleshopping(process)
          ? 'remote-teleshopping'
          : process;
  const routeList = [
    {
      step: 'Inicio',
      path: `/${processRoute}/validate-debtor`,
      component: PortalRegisterFinancingPage,
      allowedProcesses: [
        'portal',
        'promissory',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'Validación Facial',
      path: `/${processRoute}/validate-identity`,
      component: DigitalSaleIdentityValidation,
      allowedProcesses: [
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract'
      ]
    },
    {
      step: 'Registrar Identidad',
      path: `/${processRoute}/register-identity`,
      component: DigitalSaleRegisterIdentity,
      allowedProcesses: ['digitalSaleWithouFaceId']
    },
    {
      step: 'Cupo',
      path: `/${processRoute}/contract-information`,
      component: PortalContractInformationPage,
      allowedProcesses: [
        'portal',
        'promissory',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract'
      ]
    },
    {
      step: 'Codeudor',
      path: `/${processRoute}/validate-cosigner`,
      component: PortalCosignerDataPage,
      allowedProcesses: ['portal', 'promissory', 'remoteDigitalSaleWithUUP', 'remoteDigitalSaleWithoutUUP'],
      optional: true
    },
    {
      step: 'Proveedor',
      path: `/${processRoute}/supplier-data`,
      component: PortalSupplierDataPage,
      allowedProcesses: [
        'portal',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'Validación identidad',
      path: `/${processRoute}/identification-check`,
      component: PortalIDCheck,
      allowedProcesses: [
        'portal',
        'promissory',
        'remoteDigitalSaleWithoutUUP',
        'digitalSaleWithouFaceId'
      ],
      showIgnoringProcess: globalSettings.identityValidationWithEnrollmentClients
    },
    {
      step: 'Validación identidad',
      path: `/${processRoute}/otp-enrollment`,
      component: RemoteSaleEnrollmentOTP,
      allowedProcesses: [
        'remoteDigitalSaleWithUUP'
      ],
      hideIgnoringProcess: globalSettings.identityValidationWithEnrollmentClients
    },
    {
      step: 'Scoring',
      path: `/${processRoute}/scoring`,
      component: Scoring,
      allowedProcesses: [
        'portal',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP'
      ],
      allowedConfiguration:
        !!globalSettings.showScoringStep && globalSettings.showScoringStep,
      optional: true
    },
    {
      step: 'Facturas',
      path: `/${processRoute}/validate-invoice`,
      component: PortalValidateInvoicePage,
      allowedProcesses: [
        'portal',
        'promissory',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithoutUUP'
      ],
      allowedConfiguration:
        process === 'portal'
          ? globalSettings.validateSaleInvoices
          : process === 'remoteDigitalSaleWithUUP' ||
            process === 'remoteDigitalSaleWithoutUUP'
            ? globalSettings.validateRemoteSaleInvoices
            : process === 'digitalSaleWithUUP' ||
              process === 'digitalSaleWithouFaceId' ||
              process === 'digitalSaleWithoutUUPonContract'
              ? globalSettings.validateDigitalSaleInvoices
              : globalSettings.validatePromissoryInvoices
    },
    {
      step: 'Datos Pagaré',
      path: `/${processRoute}/unmaterialized-promissory`,
      component: DigitalSaleUnmaterializedPromissory,
      allowedProcesses: [
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'OTP Pagaré',
      path: `/${processRoute}/otp-promissory`,
      component: DigitalSaleOTPPromissory,
      allowedProcesses: [
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'Selección Artículos',
      path: `/${processRoute}/select-articles`,
      component: PortalSelectArticlesPage,
      allowedProcesses: [
        'portal',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'Validación de contrato',
      path: `/${processRoute}/contract-validation`,
      component: TeleshoppingRegistration,
      allowedProcesses: ['teleshopping', 'remoteTeleshopping']
    },
    {
      step: isRemoteTeleshopping(process) ? 'Resumen' : 'Creación de televenta',
      path: `/${processRoute}/register`,
      component: TeleshoppingEndPage,
      allowedProcesses: ['teleshopping', 'remoteTeleshopping']
    },
    {
      step: 'Datos de contacto',
      path: `/${processRoute}/contact-data`,
      component: DigitalSaleContactData,
      allowedProcesses: [
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP',
        'remoteTeleshopping',
      ]
    },
    {
      step: 'OTP Venta',
      path: `/${processRoute}/otp-sale`,
      component: DigitalSaleOTPSale,
      allowedProcesses: [
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP',
        'remoteTeleshopping',
      ]
    },
    {
      step: 'Pagaré',
      path: `/${processRoute}/promissory-details`,
      component: PortalPromissoryPage,
      allowedProcesses: ['portal', 'promissory']
    },
    {
      step: 'Fin',
      path: `/${processRoute}/end-sale`,
      component: PortalEndSalePage,
      allowedProcesses: [
        'portal',
        'digitalSaleWithUUP',
        'digitalSaleWithouFaceId',
        'digitalSaleWithoutUUPonContract',
        'remoteDigitalSaleWithUUP',
        'remoteDigitalSaleWithoutUUP'
      ]
    },
    {
      step: 'Validación de contrato',
      path: `/${processRoute}/validate-contract`,
      component: InsuranceContractValidation,
      allowedProcesses: ['insurance']
    },
    {
      step: 'Validación de cliente',
      path: `/${processRoute}/validate-client`,
      component: InsuranceClientValidation,
      allowedProcesses: ['insurance']
    },
    {
      step: 'Resumen de servicio',
      path: `/${processRoute}/summary`,
      component: InsuranceSummary,
      allowedProcesses: ['insurance']
    }
  ];
  return routeList.filter(route => {
    if (
      ((route.allowedConfiguration == null || route.allowedConfiguration) &&
      route.allowedProcesses.includes(process) || route.showIgnoringProcess) && !route.hideIgnoringProcess
    ) {
      return route;
    }
    return null;
  });
}

export function isDigitalSale(process) {
  return (
    process === 'digitalSaleWithUUP' ||
    process === 'digitalSaleWithouFaceId' ||
    process === 'digitalSaleWithoutUUPonContract'
  );
}

export function isDigitalSaleDocumentRevision(process) {
  return (
    process === 'digitalSalesDocumentRevision' ||
    process === 'digitalSalesDocumentRevisionPromissory' ||
    process === 'digitalSalesDocumentRevisionSale'
  );
}

export function isRemoteDigitalSale(process) {
  return (
    process === 'remoteDigitalSaleWithUUP' ||
    process === 'remoteDigitalSaleWithoutUUP'
  );
}

export function isRemoteTeleshopping(process) {
  return process === 'remoteTeleshopping';
}

export function getProcessRoute(process) {
  return isDigitalSale(process)
    ? 'digital-sale'
    : isDigitalSaleDocumentRevision(process)
      ? 'digital-sales-document-revision'
      : isRemoteDigitalSale(process)
        ? 'remote-sale'
        : isRemoteTeleshopping(process)
          ? 'remote-teleshopping'
          : process;
}

export function getSteps(routes) {
  return routes.map(item => {
    return item.step;
  });
}

export function getRoutes(routes) {
  return routes.map(item => {
    return item.path;
  });
}

export function getOptionals(routes) {
  return routes.reduce((array, item, index) => {
    if (item.optional) {
      array.push(index);
    }
    return array;
  }, []);
}

export function goBack(routes, skippedRoutes, currentRoute) {
  const skips = _uniq(skippedRoutes);
  const availableRoutes = routes.filter(r => !skips.includes(r));
  const previousRoute = availableRoutes.indexOf(currentRoute) - 1;
  history.push(availableRoutes[previousRoute]);
}

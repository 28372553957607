import { CLIENTS_SET, CLIENT_INFO_SET } from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';
import clientFiltersSanitizer from '../helpers/sanitizers/clientFilters';
import RequestService from '../services/request_service';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
import _get from 'lodash/get';

//* PURE ACTION FUNCTIONS
function success(items, pagi) {
  return {
    type: CLIENTS_SET,
    data: items,
    meta: pagi
  };
}

function setClientInfo(info) {
  return {
    type: CLIENT_INFO_SET,
    data: info
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchClients = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/clients?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'clients',
      clientFiltersSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchClientInfo = (id) => async dispatch => {
  dispatch(sendRequest('ClientInfo'));
  try {
    const payload = await requestServiceNode.get(`clients/${id}`);
    dispatch(setClientInfo(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('ClientInfo'));
  }
};

export const updateClientInfo = (id, id_sale, identification, name, last_name) => async dispatch => {
  dispatch(sendRequest('UpdateClient'));
  try {
    const payload = await requestService.put(`document_revisions/clients/${id}`, {
      client: {
        id: id,
        identification: identification,
        name: name,
        last_name: last_name,
        accepted: 1
      },
      sale_id: id_sale
    });
    dispatch(setClientInfo(payload.data));
    _get(payload, 'data.message') &&
      dispatch(newAlert('success', 'success', payload.data.message));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('UpdateClient'));
  }
};

import moment from 'moment-timezone';
import _get from 'lodash/get';
import { CommissionTypes } from '../../../constants/enums';

export const columns = [
  {
    title: 'Tipo',
    name: 'type'
  },
  {
    title: 'Categoría',
    name: 'category'
  },
  {
    title: 'Proveedor',
    name: 'supplier'
  },
  {
    title: 'Articulo',
    name: 'article'
  },
  {
    title: 'Medio de Recepción',
    name: 'saleChannel'
  },
  {
    title: 'Cobro',
    name: 'recoveryPercentage'
  },
  {
    title: 'Pago',
    name: 'paymentPercentage'
  },
  {
    title: 'Inicio',
    name: 'initialDate'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      status: datum.active ? 'Activa' : 'Inactiva',
      type: CommissionTypes[datum.commissionTypeId],
      category: _get(datum, 'category.name') || '--',
      categoryName: _get(datum, 'category.name'),
      supplier: _get(datum, 'supplier.name') || '--',
      supplierName: _get(datum, 'supplier.name'),
      article: _get(datum, 'article.name') || '--',
      articleName: _get(datum, 'article.name'),
      saleChannel: _get(datum, 'saleChannel.name') || '--',
      saleChannelName: _get(datum, 'saleChannel.name'),
      categoryId: datum.categoryId || '',
      supplierId: datum.supplierId || '',
      recoveryPercentage: datum.recoveryPercentage || '--',
      paymentPercentage: datum.paymentPercentage || '--',
      initialDate: datum.initialDate
        ? moment(datum.initialDate, 'YYYY-MM-DD').format('YYYY/MM/DD')
        : '--'
    };
  });

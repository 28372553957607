import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../shared/Modals/Modal';
import TextFieldInput from '../../../shared/Inputs/TextField';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import _forEach from 'lodash/forEach';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import FormValidation from './Form.validation';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  }
});

export class FormModal extends Component {
  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.erros !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = async item => {
    const { onSave } = this.props;

    onSave(item);
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v[0]));
    return errors;
  };

  _getFieldType = (field, form, values, handleChange) => {
    if (values.code == "company_name") {
      return (
        <SimpleSelect
          label="Valor"
          field={field}
          form={form}
          name="value"
          options={this.props.itemList}
          value={values.value}
          disabled={false}
          fullWidth
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
      )
    }
    if (values.settingType == 'boolean') {
      return (
        <SimpleSelect
          label="Valor"
          field={field}
          form={form}
          name="value"
          options={[
            { key: 1, label: 'Verdadero', value: 'Verdadero' },
            { key: 2, label: 'Falso', value: 'Falso' }
          ]}
          value={values.value}
          disabled={false}
          fullWidth
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
      )
    } else {
      return (
        <TextFieldInput
          value={values.value}
          label={'Valor'}
          fullWidth
          field={field}
          form={form}
          type={values.settingType == 'string' ? 'text' : 'number'}
          disabled={values.value == '-- Ver en Consola Administrativa'}
        />
      )
    }
  }

  render() {
    const { classes, open = true, isSubmitting, item = {} } = this.props;
    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={FormValidation}
        initialValues={{
          ...item
        }}
        onSubmit={values => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          dirty
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            classes={{
              footer: classes.modalFooter
            }}
            body={
              <Fragment>
                <div className={classes.titleWrapper}>
                  <p className={classnames(classes.headline6, classes.title)}>
                    Editar Parámetro Global
                  </p>
                  </div>
                  <Grid container spacing={16}>
                    <GridItem xs={12}>
                      <Field
                        name="name"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <TextFieldInput
                              value={values.name}
                              label={'Nombre'}
                              fullWidth
                              field={field}
                              form={form}
                              disabled={true}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="description"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <TextFieldInput
                              value={values.description}
                              label={'Descripción'}
                              fullWidth
                              field={field}
                              form={form}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="value"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return this._getFieldType(field, form, values, handleChange)
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="tags"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <ChippedAutocompleteInput
                              values={values.tags.map((tag)=>{return {label: tag};})}
                              label={'Etiquetas'}
                              fullWidth
                              field={field}
                              form={form}
                              readOnly
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              }
              footer={
                <Fragment>
                  <div className={classes.spacer} />
                  <Button
                    className={classes.button}
                    onClick={handleReset}
                    color={'secondary'}
                    classes={{ label: classes.buttonLabel }}
                  >
                    {'Cancelar'}
                  </Button>
                  <ProgressButton
                    onClick={handleSubmit}
                    className={classes.button}
                    classes={{
                      disabled: classes.buttonDisabled,
                      label: classes.buttonLabel
                    }}
                    color={'secondary'}
                    disabled={!dirty || isSubmitting}
                    isSubmitting={isSubmitting}
                  >
                    {'Guardar'}
                  </ProgressButton>
                </Fragment>
              }
            />
          )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);

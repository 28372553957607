import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import DocRevisionFilters from './DocRevisionFilters';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import DocRevision from './modal/DocRevisionForm';
import { history } from '../../../helpers/history';
import RequestService from '../../../services/request_service';

const requestServiceNode = new RequestService('node');

class DocRevisionList extends React.Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    documentalRevision: null
  };

  async componentDidMount() {
    this.fetchItems();

    const {
      location: { state }
    } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this.fetchItem(state.entityId);
        if (item) {
          this.setState({ documentalRevision: item });
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const wasApproved = prevProps.requestApproveDocRevisionSend && !this.props.requestApproveDocRevisionSend;
    const wasRevoked = prevProps.requestRevokeDocRevisionSend && !this.props.requestRevokeDocRevisionSend;

    if (wasApproved || wasRevoked) this.handleCloseForm();

    const { location: { state } } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this.fetchItem(state.entityId);
        if (item) {
          this.setState({ documentalRevision: item });
        }
      }
    }
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchDocRevisions({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  fetchItem = async id => {
    try {
      const payload = await requestServiceNode.get(
        'sinisters',
        {},
        true
      );
      return payload.data[0];
    } catch (errors) {
      return null;
    }
  };

  handleRowClick = documentalRevision => {
    this.setState({ documentalRevision });
  };

  handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  handleCloseForm = () => this.setState({ documentalRevision: null });

  handleRevoke = params => {
    const query = parseQuerystring(this.props) || {};
    this.props.revokeDocRevision(params, {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  handleApprove = params => {
    const query = parseQuerystring(this.props) || {};
    this.props.approveDocRevision(params, {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  render() {
    const {
      classes,
      docRevisions = [],
      meta = {},
      requestSend,
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            Revisiones documentales
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <DocRevisionFilters
              onChangeFilter={this.fetchItems}
              suppliers={this.props.suppliers}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Revisiones documentales'}
            columns={columns}
            data={mapData(docRevisions)}
            onChangePage={this.handleChangePage}
            onChangePageRowsPerPage={this.handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={this.handleRowClick}
            totalCount={0}
            loading={requestSend}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
        {!!this.state.documentalRevision && (
          <DocRevision
            documentalRevision={this.state.documentalRevision}
            canApproveDocRevisions={this.props.canApproveDocRevisions}
            canRevokeDocRevisions={this.props.canRevokeDocRevisions}
            onClose={this.handleCloseForm}
            onRevoke={this.handleRevoke}
            onApprove={this.handleApprove}
            isSaving={this.props.requestApproveDocRevisionSend}
            isRevoking={this.props.requestRevokeDocRevisionSend}
          />
        )}
      </div>
    );
  }
}

DocRevisionList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  docRevisions: PropTypes.array.isRequired,
  fetchDocRevisions: PropTypes.func.isRequired,
  meta: PropTypes.object
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
  )
)(DocRevisionList);

import _get from 'lodash/get';

export const columns = [
  {
    title: 'Corporación',
    name: 'corporation'
  },
  {
    title: '# Mínimo',
    name: 'min_value'
  },
  {
    title: '# Máximo',
    name: 'max_value'
  },
  {
    title: 'Estado',
    name: 'enable'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      id: datum.id,
      corporation: _get(datum, 'corporation.name', '--'),
      corporation_id: _get(datum, 'corporation.id', '--'),
      min_value: datum.minValue,
      max_value: datum.maxValue,
      enable: datum.enable ? 'Activo' : 'Inactivo'
    };
  });

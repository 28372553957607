import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import _get from 'lodash/get';

import ActivityIndicator from '../../shared/ActivityIndicator';
import { history } from '../../../helpers/history';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ClientProfile from './Profile';
import ExpandCardClient from '../../shared/Core/ExpandCardClient';

import Table from '../../shared/Table/Table';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import { salesColumns, salesMapData } from './Tables/SalesMetadata';
import {
  promissoriesColumns,
  promissoriesMapData
} from './Tables/PromissoriesMetadata';

class ClientDetail extends React.Component {
  componentDidMount() {
    this._fetchDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this._fetchDetails();
    }
  }

  _fetchDetails = () => {
    this.props.fetchClientInfo(this.props.match.params.id);
  };

  _toPromissoryDetail = id => {
    this.props.canViewPromissories && history.push(`/promissories/${id}`);
  };

  _toSaleDetail = id => {
    this.props.canViewSales && history.push(`/sales/${id}`);
  };

  render() {
    const {
      classes,
      match,
      clientInfo = {},
      clientLoading = true
    } = this.props;

    if (!clientInfo) {
      return <ActivityIndicator />;
    }

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1
            className={classnames(
              classes.headline4,
              classes.noMarginBottom,
              classes.title
            )}
          >
            {`Cliente #${match.params.id}`}
          </h1>
        </div>
        <Grid container spacing={24}>
          <Grid item xs={12} lg={4}>
            <ClientProfile client={clientInfo} isLoading={clientLoading} />
            {clientInfo.clientDetail && (
              <ExpandCardClient client={{}} detail={clientInfo.clientDetail} />
            )}
          </Grid>
          <Grid item xs={12} lg={8}>
            <FormItemContainer
              title={'Ventas'}
              classes={{
                container: classes.formItemContainer
              }}
              customContents={classes.contents}
            >
              {clientInfo.debtorSales.length > 0 ? (
                <Table
                  columns={salesColumns}
                  data={salesMapData(_get(clientInfo, 'debtorSales'))}
                  loading={clientLoading}
                  withSelection={false}
                  onClickOnRow={props => this._toSaleDetail(props.id)}
                  classes={{
                    root: classes.tableRoot,
                    tableWrapper: classes.tableWrapper,
                    tableHeadRowRoot: classes.tableHeadRowRoot,
                    tableCellHead: classes.tableCellHead
                  }}
                  customContents={classes.contents}
                  withToolbar={false}
                  withColumnChooser={false}
                  withPagination={false}
                />
              ) : (
                <p className={classnames(classes.noItems, classes.body2)}>
                  El cliente no tiene ventas.
                </p>
              )}
            </FormItemContainer>
            <FormItemContainer
              title={'Pagarés'}
              customContents={classes.contents}
            >
              {clientInfo.promissories.length > 0 ? (
                <Table
                  columns={promissoriesColumns}
                  data={promissoriesMapData(_get(clientInfo, 'promissories'))}
                  loading={clientLoading}
                  withSelection={false}
                  onClickOnRow={props => this._toPromissoryDetail(props.id)}
                  classes={{
                    root: classes.tableRoot,
                    tableWrapper: classes.tableWrapper,
                    tableHeadRowRoot: classes.tableHeadRowRoot
                  }}
                  customContents={classes.contents}
                  withToolbar={false}
                  withColumnChooser={false}
                  withPagination={false}
                />
              ) : (
                <p className={classnames(classes.noItems, classes.body2)}>
                  El cliente no tiene pagarés.
                </p>
              )}
            </FormItemContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '45vh'
  },
  formItemContainer: {
    boxShadow:
      '0 1px 1px 0 rgba(12,31,44,0.12), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(12,31,44,0.2)'
  },
  contents: {
    padding: '0 !important'
  },
  tableRoot: {
    boxShadow: 'none'
  },
  tableWrapper: {
    overflow: 'auto',
    maxHeight: 224
  },
  tableCellHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1
  },
  noItems: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '68px',
    margin: 0,
    padding: '0 24px !important'
  }
});

ClientDetail.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newAlert: PropTypes.func
};

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(ClientDetail);

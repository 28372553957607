import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core';

import Modal from '../../../shared/Modals/Modal';
import AutoSuggestInput from '../../../shared/AutoSuggestInput';
import CustomSelect from '../../../shared/Selects/CustomSelect';
import {
  pointOfSaleValidation,
  saleChannelValidation
} from './SupplierValidations';
import LoadingButton from '../../../shared/Buttons/LoadingButton';
import ConfirmDialogModal from '../../../shared/Modals/ConfirmDialog';
import {
  promissoryTypes,
  processesOrder
} from '../constants/SupplierPreferences';

class SupplierEditConfig extends React.Component {
  constructor(props) {
    super(props);
    const {
      orderOfProcesses,
      defaultSaleChannel,
      defaultPointOfSale,
      allowedPromissoryType,
      siteDelivery,
      isBigBox,
      canSelectSaleChannel,
      canSelectPointOfSale,
      canChangeSiteDelivery,
      canReprintSaleDocuments,
      canSellWithSecureSale,
      canSkipInvoiceValidation,
      requiresApprovalBeforeSaleCancel,
      requiresApprovalBeforeSaleReturn,
      reportsShowVoluntaryInsurance,
      requiresBin,
      canValidateMobileOnSale,
      canSellVoluntaryInsuranceLayerTwo,
      canSellWithMegaquota,
      canValidatePetSale,
      canSellToCommercial,
      docRevisionPercentage,
    } = this.props.supplierInfo;

    this.state = {
      orderOfProcesses,
      defaultSaleChannelId: defaultSaleChannel
        ? defaultSaleChannel.id
        : null,
      defaultPointOfSaleId: defaultPointOfSale
        ? defaultPointOfSale.id
        : null,
      defaultPointOfSale: defaultPointOfSale ? defaultPointOfSale.name : '',
      defaultSaleChannel: defaultSaleChannel ? defaultSaleChannel.name : '',
      allowedPromissoryType,
      siteDelivery,
      isBigBox,
      canSelectSaleChannel,
      canSelectPointOfSale,
      canChangeSiteDelivery,
      canReprintSaleDocuments,
      canSkipInvoiceValidation,
      requiresApprovalBeforeSaleCancel,
      requiresApprovalBeforeSaleReturn,
      reportsShowVoluntaryInsurance,
      canSellWithSecureSale,
      requiresBin: requiresBin || false,
      canValidateMobileOnSale,
      canSellVoluntaryInsuranceLayerTwo,
      canSellWithMegaquota,
      canValidatePetSale,
      canSellToCommercial,
      docRevisionPercentage,
      isOpen: false
    };
  }

  handleCheckBox = name => event => {
    this.setState({ [name]: event.target.checked });
    if (name == 'requiresBin' && event.target.checked) {
      this._handleOpenModal();
    }
  };

  _handleChangeSelect = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  _handleAutoSuggest = name => (event, { newValue }) => {
    let id = null;
    let saleChannels = this.props.saleChannels;
    name === 'defaultSaleChannel' &&
      saleChannels.forEach(c => {
        if (c.name === newValue) {
          id = c.id;
        }
      });
    name === 'defaultPointOfSale' &&
      this.props.supplierInfo.pointOfSales.forEach(c => {
        if (c.name === newValue) {
          id = c.id;
        }
      });

    this.setState({
      [name]: newValue,
      [`${name}Id`]: id,
      api_errors: null
    });
  };
  _handleOpenModal = () => this.setState({ isOpen: true });
  _handleCloseModal = () => this.setState({ isOpen: false });

  _handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  _validations = () => {
    const {
      defaultSaleChannel,
      defaultSaleChannelId,
      canSelectSaleChannel,
      defaultPointOfSale,
      defaultPointOfSaleId,
      canSelectPointOfSale,
      docRevisionPercentage
    } = this.state;
    const { api_errors } = this.props;
    const posVal = pointOfSaleValidation(
      defaultPointOfSale,
      defaultPointOfSaleId,
      canSelectPointOfSale,
      api_errors,
      true
    );
    const scVal = saleChannelValidation(
      defaultSaleChannel,
      defaultSaleChannelId,
      canSelectSaleChannel,
      api_errors,
      true
    );
    if (posVal || scVal) {
      return true;
    }

    if (docRevisionPercentage < 0 || docRevisionPercentage > 100) {
      return true;
    }

    return false;
  };
  render() {
    const { supplierInfo, api_errors, loading, classes, onClose } = this.props;

    const docRevisionPercentageError = this.state.docRevisionPercentage < 0 || this.state.docRevisionPercentage > 100;

    return (
      <Modal
        open={true}
        onClose={onClose}
        classes={{
          footer: classes.modalFooter,
          root: classes.modalRoot
        }}
        body={
          <Fragment>
            <Typography className={classes.cardTitle}>
              CONFIGURACIÓN REGISTRO DE VENTA
            </Typography>
            <Grid container spacing={24}>
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    id="allowedPromissoryType"
                    name="allowedPromissoryType"
                    required
                    label="Tipo de pagaré permitido"
                    disabled={loading}
                    value={this.state.allowedPromissoryType}
                    onChange={this._handleChangeSelect}
                    menuItems={promissoryTypes}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    id="orderOfProcesses"
                    name="orderOfProcesses"
                    required
                    label="Orden de los procesos"
                    disabled={loading}
                    value={this.state.orderOfProcesses}
                    onChange={this._handleChangeSelect}
                    menuItems={processesOrder}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSuggestInput
                    id="defaultPointOfSale"
                    name="defaultPointOfSale"
                    label="Punto de venta por defecto"
                    disabled={loading}
                    value={this.state.defaultPointOfSale}
                    onChange={this._handleAutoSuggest('defaultPointOfSale')}
                    attr="name"
                    suggestions={
                      supplierInfo.pointOfSales.length > 0
                        ? supplierInfo.pointOfSales
                        : []
                    }
                    error={pointOfSaleValidation(
                      this.state.defaultPointOfSale,
                      this.state.defaultPointOfSaleId,
                      this.state.canSelectPointOfSale,
                      api_errors,
                      true
                    )}
                    helperText={pointOfSaleValidation(
                      this.state.defaultPointOfSale,
                      this.state.defaultPointOfSaleId,
                      this.state.canSelectPointOfSale,
                      api_errors
                    )}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoSuggestInput
                    id="defaultSaleChannel"
                    name="defaultSaleChannel"
                    label="Canal de venta por defecto"
                    disabled={loading}
                    value={this.state.defaultSaleChannel}
                    onChange={this._handleAutoSuggest('defaultSaleChannel')}
                    attr="name"
                    suggestions={this.props.saleChannels}
                    margin="normal"
                    variant="outlined"
                    error={saleChannelValidation(
                      this.state.defaultSaleChannel,
                      this.state.defaultSaleChannelId,
                      this.state.canSelectSaleChannel,
                      api_errors,
                      true
                    )}
                    helperText={saleChannelValidation(
                      this.state.defaultSaleChannel,
                      this.state.defaultSaleChannelId,
                      this.state.canSelectSaleChannel,
                      api_errors
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="docRevisionPercentage"
                    name="docRevisionPercentage"
                    value={this.state.docRevisionPercentage}
                    onChange={this._handleChange}
                    label="% de ventas con rev. documental"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={docRevisionPercentageError}
                    helperText={docRevisionPercentageError ? 'El porcentaje debe estar entre 0 y 100' : ''}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="isBigBox"
                      checked={this.state.isBigBox}
                      onChange={this.handleCheckBox('isBigBox')}
                    />
                  }
                  label="Grande superficie"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canReprintSaleDocuments"
                      checked={this.state.canReprintSaleDocuments}
                      onChange={this.handleCheckBox(
                        'canReprintSaleDocuments'
                      )}
                    />
                  }
                  label="Puede re-imprimir documentos de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requiresBin"
                      checked={this.state.requiresBin}
                      onChange={this.handleCheckBox('requiresBin')}
                    />
                  }
                  label="Generación de BIN"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSelectPointOfSale"
                      checked={this.state.canSelectPointOfSale}
                      onChange={this.handleCheckBox('canSelectPointOfSale')}
                    />
                  }
                  label="Puede seleccionar punto de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="siteDelivery"
                      checked={this.state.siteDelivery}
                      onChange={this.handleCheckBox('siteDelivery')}
                    />
                  }
                  label="Entrega en punto"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canChangeSiteDelivery"
                      checked={this.state.canChangeSiteDelivery}
                      onChange={this.handleCheckBox('canChangeSiteDelivery')}
                    />
                  }
                  label="Puede cambiar entrega en punto"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requiresApprovalBeforeSaleCancel"
                      checked={this.state.requiresApprovalBeforeSaleCancel}
                      onChange={this.handleCheckBox(
                        'requiresApprovalBeforeSaleCancel'
                      )}
                    />
                  }
                  label="Requiere aprobación antes de cancelar ventas"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSelectSaleChannel"
                      checked={this.state.canSelectSaleChannel}
                      onChange={this.handleCheckBox('canSelectSaleChannel')}
                    />
                  }
                  label="Puede seleccionar canales de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="requiresApprovalBeforeSaleReturn"
                      checked={this.state.requiresApprovalBeforeSaleReturn}
                      onChange={this.handleCheckBox(
                        'requiresApprovalBeforeSaleReturn'
                      )}
                    />
                  }
                  label="Requiere aprobación antes de devolver ventas"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="reportsShowVoluntaryInsurance"
                      checked={this.state.reportsShowVoluntaryInsurance}
                      onChange={this.handleCheckBox(
                        'reportsShowVoluntaryInsurance'
                      )}
                    />
                  }
                  label="Seguro voluntario se muestra en los reportes"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSellWithSecureSale"
                      checked={this.state.canSellWithSecureSale}
                      onChange={this.handleCheckBox(
                        'canSellWithSecureSale'
                      )}
                    />
                  }
                  label="Puede vender con fianza"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSkipInvoiceValidation"
                      checked={this.state.canSkipInvoiceValidation}
                      onChange={this.handleCheckBox(
                        'canSkipInvoiceValidation'
                      )}
                    />
                  }
                  label="Puede omitir paso de validación de factura"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canValidateMobileOnSale"
                      checked={this.state.canValidateMobileOnSale}
                      onChange={this.handleCheckBox(
                        'canValidateMobileOnSale'
                      )}
                    />
                  }
                  label="Puede validar celular en registro de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSellVoluntaryInsuranceLayerTwo"
                      checked={this.state.canSellVoluntaryInsuranceLayerTwo}
                      onChange={this.handleCheckBox(
                        'canSellVoluntaryInsuranceLayerTwo'
                      )}
                    />
                  }
                  label="Puede vender seguro voluntario complementario"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSellWithMegaquota"
                      checked={this.state.canSellWithMegaquota}
                      onChange={this.handleCheckBox(
                        'canSellWithMegaquota'
                      )}
                    />
                  }
                  label="Puede vender con Megacupo"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canValidatePetSale"
                      checked={this.state.canValidatePetSale}
                      onChange={this.handleCheckBox(
                        'canValidatePetSale'
                      )}
                    />
                  }
                  label="Puede validar mascotas en registro de venta"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      name="canSellToCommercial"
                      checked={this.state.canSellToCommercial}
                      onChange={this.handleCheckBox(
                        'canSellToCommercial'
                      )}
                    />
                  }
                  label="Puede vender a comercial"
                />
              </Grid>
            </Grid>
            <ConfirmDialogModal
              open={this.state.isOpen}
              title={'Advertencia:'}
              text={`Al activar la generación del BIN para este proveedor estás solicitando implicitamente una configuración general para este, si deseas que este trámite se realize satisfactoriamente, debes contactar a tu administrador.`}
              buttonTextConfirm={'OK'}
              onClose={this._handleCloseModal}
              onConfirm={this._handleCloseModal}
              customBase={classes.customBase}
            />
          </Fragment>
        }
        footer={
          <Fragment>
            <div className={classes.spacer} />
            <LoadingButton
              size="large"
              color="secondary"
              loading={loading}
              disabled={this._validations()}
              onClick={() => this.props.updateSupplier(this.state)}
            >
              GUARDAR
            </LoadingButton>
          </Fragment>
        }
      />
    );
  }
}
const styles = theme => ({
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: '#CACACA',
    paddingBottom: theme.spacing.unit * 3
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  customBase: {
    width: 500
  }
});

SupplierEditConfig.propTypes = {
  supplierInfo: PropTypes.object,
  updateSupplierConfig: PropTypes.func,
  pointOfSales: PropTypes.array,
  api_errors: PropTypes.object
};

export default withStyles(styles)(SupplierEditConfig);

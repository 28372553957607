import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GridItem from '../../../shared/GridItem';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import { Formik, Field } from 'formik';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../ModalStyles';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import CustomDialog from '../../../shared/Modals/CustomDialog';

class FormModal extends Component {
  _getSuggestions = (array, value) => {
    const items = array.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return suggestions;
  };

  _handleSave = params => {
    if (this._configIsNew()) {
      this.props.createConfig(params.values);
    } else {
      this.props.updateConfig(params.values);
    }
  };

  _modalProps = (handleSubmit, onDelete, handleReset, onClose, values) => {
    if (!this._configIsNew() && this.props.canDestroyScoringExclusions) {
      return {
        onConfirm: () => handleSubmit(),
        title: 'Modificar Exclusión',
        buttonText: 'Guardar',
        buttonSecondaryText: 'Eliminar',
        onConfirmSecondary: () => {
          onDelete({ id: values.id });
        }
      };
    }
    return {
      title: 'Nueva Exclusión',
      buttonText: 'Guardar',
      onConfirm: () => handleSubmit()
    };
  };

  _nameVerification = string => {
    return string === '--' ? '' : string;
  };

  _configIsNew = () => {
    const { config } = this.props;
    return config.supplierId === undefined;
  };

  render() {
    const {
      onClose,
      onDelete,
      classes,
      submitLoading,
      modalDestroyLoading,
      categories,
      geographicLocations,
      suppliers,
      rowsPerPage,
      currentPage,
      config
    } = this.props;

    return (
      <div>
        <Formik
          initialValues={{ ...config }}
          onSubmit={values =>
            this._handleSave({ values, rowsPerPage, currentPage })
          }
          onReset={(values, actions) => {
            actions.resetForm();
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            handleReset,
            dirty
          }) => {
            return (
              <CustomDialog
                open={true}
                loading={submitLoading}
                loadingSecondary={modalDestroyLoading}
                disabled={
                  submitLoading ||
                  !dirty ||
                  (!values.geographicLocationId &&
                    !values.categoryId &&
                    !values.supplierId)
                }
                disableBackdropClick={false}
                headerSecondary
                onClose={() => {
                  handleReset();
                  onClose();
                }}
                {...this._modalProps(
                  handleSubmit,
                  onDelete,
                  handleReset,
                  onClose,
                  values
                )}
                classes={{
                  root: classes.dialogRoot
                }}
                confirmDialog={true}
                titleConfirm="Mensaje Importante"
                textConfirm="Esta a punto de eliminar esta exclusión"
              >
                <Fragment>
                  <Grid container spacing={16}>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="category"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="supplier"
                              name="supplier"
                              label="Proveedor"
                              margin="normal"
                              variant="outlined"
                              error={touched.supplierId && !!errors.supplierId}
                              helperText={
                                touched.supplierId && errors.supplierId
                              }
                              value={this._nameVerification(
                                values.supplierName
                              )}
                              onChange={change => {
                                setFieldValue('supplierId', null);
                                setFieldValue('supplierName', change);
                              }}
                              suggestions={suppliers}
                              getSuggestions={value =>
                                this._getSuggestions(suppliers, value)
                              }
                              onSuggestionSelected={pointOfSale => {
                                setFieldValue(
                                  'supplierName',
                                  pointOfSale.label
                                );
                                setFieldValue('supplierId', pointOfSale.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="category"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="category"
                              name="category"
                              label="Categoría"
                              margin="normal"
                              variant="outlined"
                              error={touched.categoryId && !!errors.categoryId}
                              helperText={
                                touched.categoryId && errors.categoryId
                              }
                              value={this._nameVerification(
                                values.categoryName
                              )}
                              onChange={change => {
                                setFieldValue('categoryId', null);
                                setFieldValue('categoryName', change);
                              }}
                              suggestions={categories}
                              getSuggestions={value =>
                                this._getSuggestions(categories, value)
                              }
                              onSuggestionSelected={category => {
                                setFieldValue('categoryName', category.label);
                                setFieldValue('categoryId', category.value);
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <Field
                        name="geographic_location"
                        onChange={handleChange}
                        render={({ field, form }) => {
                          return (
                            <AutocompleteInput
                              id="geographic_location"
                              name="geographic_location"
                              label="Zona Geográfica"
                              margin="normal"
                              variant="outlined"
                              error={
                                touched.geographicLocationId &&
                                !!errors.geographicLocationId
                              }
                              helperText={
                                touched.geographicLocationId &&
                                errors.geographicLocationId
                              }
                              value={this._nameVerification(
                                values.geographicLocationName
                              )}
                              onChange={change => {
                                setFieldValue('geographicLocationId', null);
                                setFieldValue('geographicLocationName', change);
                              }}
                              suggestions={geographicLocations}
                              getSuggestions={value =>
                                this._getSuggestions(geographicLocations, value)
                              }
                              onSuggestionSelected={geographicLocation => {
                                setFieldValue(
                                  'geographicLocationName',
                                  geographicLocation.label
                                );
                                setFieldValue(
                                  'geographicLocationId',
                                  geographicLocation.value
                                );
                              }}
                              field={field}
                              form={form}
                              classes={{
                                root: classes.autocomplete
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                  </Grid>
                </Fragment>
              </CustomDialog>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(FormModal);

import ScoringConfigurationList from './ScoringConfigurationList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  fetchScoringConfigurationList,
  createScoringConfiguration,
  openScoringConfigurationFormModal,
  closeScoringConfigurationFormModal,
  updateScoringConfiguration,
  deleteScoringConfiguration
} from '../../../actions/scoring_configuration_actions';

function mapStateToProps(state) {
  return {
    configs: state.scoringConfigurations.configs,
    meta: state.scoringConfigurations.meta,
    loading: state.requestState.requestScoringConfigurationListSend,
    canCreateScoringExclusions:
      state.authentication.currentUser.canCreateScoringExclusions,
    canUpdateScoringExclusions:
      state.authentication.currentUser.canUpdateScoringExclusions,
    canDestroyScoringExclusions:
      state.authentication.currentUser.canDestroyScoringExclusions,
    categories: state.globalSetting.orgData.categories,
    geographicLocations: state.globalSetting.orgData.geographic_locations,
    suppliers: state.globalSetting.orgData.suppliers,
    modalSubmitLoading:
      state.requestState.requestScoringConfigurationCreateSend ||
      state.requestState.requestScoringConfigurationUpdateSend,
    modalDestroyLoading:
      state.requestState.requestScoringConfigurationDeleteSend,
    formModalIsOpen: state.scoringConfigurations.formModalIsOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchScoringConfigurationList: bindActionCreators(
      fetchScoringConfigurationList,
      dispatch
    ),
    createScoringConfiguration: bindActionCreators(
      createScoringConfiguration,
      dispatch
    ),
    openFormModal: bindActionCreators(
      openScoringConfigurationFormModal,
      dispatch
    ),
    closeFormModal: bindActionCreators(
      closeScoringConfigurationFormModal,
      dispatch
    ),
    updateScoringConfiguration: bindActionCreators(
      updateScoringConfiguration,
      dispatch
    ),
    deleteScoringConfiguration: bindActionCreators(
      deleteScoringConfiguration,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ScoringConfigurationList);

import _pickBy from 'lodash/pickBy';

export const supplierFilterSanitizer = filters => {
  const { name, corporation, status, page, rowsPerPage } = filters;
  const sanitizedFilters = {
    supplierName: name,
    corporation,
    page: page,
    rowsPerPage,
  };
  if (status === 'activos') {
    sanitizedFilters.active = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.active = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

export const supplierDashboardFilterSanitizer = filters => {
  const { selectedZones } = filters;
  const sanitizedFilters = {
    selectedZones: selectedZones.map(zone => zone.value).join(',')
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

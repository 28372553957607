import moment from 'moment-timezone';
import { CUSTOM_DATE } from './constants';

export const statusOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'registered', title: 'Pendiente revisión' },
  { value: 'approved', title: 'Aprobada' },
  { value: 'revoked', title: 'Rechazada' }
];

export const originalDateOptions = [
  { value: 'todas', title: 'Todas' },
  { value: moment().format('YYYY-MM-DD'), title: 'Hoy' },
  {
    value: `${moment()
      .startOf('week')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('week')
      .format('YYYY-MM-DD')}`,
    title: 'Esta semana'
  },
  {
    value: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')} - ${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`,
    title: 'Este mes'
  }
];

export const dateOptions = (customDate, option) => [
  ...originalDateOptions,
  {
    value: customDate !== CUSTOM_DATE ? option : CUSTOM_DATE,
    title: customDate !== CUSTOM_DATE ? option : 'Personalizada'
  }
];
import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import _forEach from 'lodash/forEach';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import ErrorIcon from '@material-ui/icons/ErrorRounded';

import Modal from '../../../shared/Modals/DialogForm';
import TextFieldInput from '../../../shared/Inputs/TextField';
import DateFieldInput from '../../../shared/Inputs/DateField';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import ProgressButton from '../../../shared/Buttons/ProgressButton';
import GridItem from '../../../shared/GridItem';
import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import PercentageIcon from '../../../icons/Percentage';
import TypographyStyles from '../../../../styles/typography';
import ButtonStyles from '../../../../styles/button';
import SpacingStyles from '../../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import CommissionFormValidation from './CommissionForm.validation';
import SimpleSelect from '../../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../../helpers/mapListToOptions';
import ToggleButton from '../../../shared/Buttons/Toggle';

const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    marginBottom: `${theme.spacing.unit * 2.125}px !important`,
    position: 'relative'
  },
  toggleSwitch: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    top: -10,
    right: 0
  },
  title: {
    flex: 1,
    fontWeight: 'bold !important'
  },
  resetLinkButton: {
    position: 'absolute',
    right: 0,
    top: -6
  },
  resetLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`
  },
  modalFooter: {
    marginTop: `${theme.spacing.unit * 5}px !important`
  },
  errorMessageContainer: {
    backgroundColor: '#FDEDED',
    borderRadius: '5px',
    margin: '10px',
    padding: '4px 12px',
    width: '100%',
    color: '#5F2120'
  },
  errorMessageText: {
    display: 'flex',
    alignItems: 'center'
  }
});

export class CommissionFormModal extends Component {
  state = {
    supplierName: '',
    categoryName: '',
    articleName: '',
    saleChannelName: ''
  };

  _handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidMount() {
    this._getFilterParams();
  }

  _getFilterParams = values => {
    const filterParams = _pick(
      _pickBy(values, filter => !!filter),
      'supplierName',
      'categoryName',
      'supplierId',
      'categoryId'
    );
    this.props.fetchInfoToCreate(filterParams);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnSave = values => {
    const { onSave, isCreate } = this.props;

    const params = {
      ...values,
      recoveryPercentage: Number(values.recoveryPercentage)
    };

    const falsyToRemove = [null, undefined, ''];
    onSave(isCreate, _pickBy(params, filter => !falsyToRemove.includes(filter)));
  };

  _getSuggestions = async (options, value) => {
    const items = options.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => {
      if (v instanceof Array) {
        errors[k] = v[0];
      } else {
        errors[k] = v;
      }
    });
    return errors;
  };

  _setCategoryValues = async (articles, id, setFieldValue) => {
    const article = await articles.filter(item => item.id == id)[0];
    const category = await this.props.categories.filter(
      item => item.id == article.categoryId
    )[0];

    setFieldValue('categoryId', category.id);
    setFieldValue('categoryName', category.name);
    this.setState({ categoryName: '' });
  };

  renderGDGError = classes => {
    return (
      <div className={classes.errorMessageContainer}>
        <p className={classes.errorMessageText}>
          <ErrorIcon style={{ marginRight: '8px' }} /> Esta acción no se
          encuentra disponible.
        </p>
      </div>
    );
  };

  filterArticles = (categoryId = null, supplierId = null) => {
    const filteredArticles = this.props.dataToCreateCommissions.data.filter(article => {
      const suppliers = article.suppliers.map(supplier => Number(supplier.id));
      return (
        (!categoryId || Number(article.categoryId) === categoryId) &&
        (!supplierId || suppliers.includes(supplierId))
      );
    });

    return filteredArticles;
  }

  render() {
    const {
      classes,
      open = true,
      isSubmitting,
      commission = {},
      commissionTypes,
      isCreate,
      companyName
    } = this.props;
    let initialValues = {
      active: true,
      articles: this.props.dataToCreateCommissions.data,
      initialDate: moment()
        .add(1, 'day')
        .format('YYYY/MM/DD'),
      recoveryPercentage: '0.00',
      supplierName: '',
      categoryName: '',
      articleName: '',
      supplierId: '',
      categoryId: '',
      articleId: '',
      saleChannelName: '',
      commissionTypeId: _get(commission, 'commissionType.id') || commissionTypes.find(c => c.code === 'sale').id,
      commissionTypeCode: _get(commission, 'commissionType.code', 'sale')
    };
    initialValues = !isCreate
      ? Object.assign(initialValues, commission)
      : initialValues;
    return (
      <Formik
        ref={ref => (this.formik = ref)}
        enableReinitialize={true}
        validationSchema={CommissionFormValidation(companyName)}
        initialValues={initialValues}
        onSubmit={this._handleOnSave}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        validateOnChange={false}
        validateOnBlur={false}
        render={({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setErrors,
          errors,
          touched
        }) => (
          <Modal
            open={open}
            onClose={this._handleOnClose}
            title={
              <div className={classes.titleWrapper}>
                <p className={classnames(classes.headline6, classes.title)}>
                  {isCreate ? 'Crear Comisión' : 'Editar Comisión'}
                </p>
                {values.commissionTypeCode === 'advertisement' && (
                  <div className={classes.toggleSwitch}>
                    <ToggleButton
                      value={values.active}
                      onChange={() => {
                        setFieldValue('active', !values.active);
                      }}
                    />
                  </div>
                )}
              </div>
            }
            footer={
              <Fragment>
                <div className={classes.spacer} />
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {'Guardar'}
                </ProgressButton>
              </Fragment>
            }
          >
            <Grid container spacing={16}>
              {companyName === 'GDG' &&
                values.commissionTypeCode === 'advertisement' &&
                this.renderGDGError(classes)}
              {!commission.id && (
                <GridItem item xs={12}>
                  <Field
                    render={({ field, form }) => {
                      return (
                        <SimpleSelect
                          name="commissionTypeId"
                          value={values.commissionTypeId}
                          label={'Tipo de Comisión'}
                          options={mapListToOptions(commissionTypes, {
                            raw: true,
                            property: 'name'
                          })}
                          onChange={change => {
                            const commissionType = commissionTypes.find(
                              c => c.id === change.target.value
                            );
                            setFieldValue(
                              'commissionTypeId',
                              change.target.value
                            );
                            setFieldValue(
                              'commissionTypeCode',
                              commissionType.code
                            );
                            commissionType.code === 'sale'
                              ? setFieldValue(
                                'initialDate',
                                moment()
                                  .add(1, 'day')
                                  .format('YYYY/MM/DD')
                              )
                              : setFieldValue('initialDate', null);
                            setErrors({});
                          }}
                          field={field}
                          form={form}
                          allowMargin={false}
                        />
                      );
                    }}
                  />
                </GridItem>
              )}
              {values.commissionTypeCode === 'sale' && (
                <GridItem xs={6}>
                  <Field
                    name="initialDate"
                    onChange={handleChange}
                    render={({ field, form }) => {
                      return (
                        <DateFieldInput
                          value={values.initialDate || null}
                          label={'Fecha inicial'}
                          fullWidth
                          onChange={change => {
                            setFieldValue('initialDate', change.target.value);
                          }}
                          field={field}
                          form={form}
                        />
                      );
                    }}
                  />
                </GridItem>
              )}
              <GridItem xs={values.commissionTypeCode === 'sale' ? 6 : 12}>
                <Field
                  name="recoveryPercentage"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <TextFieldInput
                        value={values.recoveryPercentage}
                        label={'Comisión de cobro'}
                        fullWidth
                        onChange={change => {
                          setFieldValue(
                            'recoveryPercentage',
                            Number(change.target.value)
                          );
                        }}
                        field={field}
                        form={form}
                        type="number"
                        step="0.01"
                        startAdornment={<PercentageIcon />}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="supplierName"
                  onChange={handleChange}
                  render={() => {
                    return (
                      <AutocompleteInput
                        id="supplierName"
                        name="supplierName"
                        label="Proveedor"
                        margin="normal"
                        variant="outlined"
                        error={touched.supplierName && (!!errors.supplierName || !!errors.supplierId)}
                        helperText={touched.supplierName && (errors.supplierName || errors.supplierId)}
                        value={this.state.supplierName || values.supplierName || ''}
                        onChange={change => {
                          setFieldValue('supplierName', '');
                          setFieldValue('supplierId', null);
                          this.setState({ supplierName: change });
                        }}
                        getSuggestions={value => this._getSuggestions(this.props.suppliers, value)}
                        onSuggestionSelected={supplier => {
                          setFieldValue('supplierName', supplier.label);
                          setFieldValue('supplierId', supplier.value);
                          setFieldValue('articleName', '');
                          setFieldValue('articleId', '');
                          this.setState({ articleName: '' });
                          setFieldValue('articles', this.filterArticles(values.categoryId, supplier.value));
                        }}
                      />
                    );
                  }}
                />
              </GridItem>
              {values.commissionTypeCode === 'sale' && (
                <>
                  <GridItem xs={12}>
                    <Field
                      name="categoryName"
                      onChange={handleChange}
                      render={() => {
                        return (
                          <AutocompleteInput
                            id="categoryName"
                            name="categoryName"
                            label="Categoría"
                            margin="normal"
                            variant="outlined"
                            error={touched.categoryName && (!!errors.categoryName || !!errors.categoryId)}
                            helperText={touched.categoryName && (errors.categoryName || errors.categoryId)}
                            disabled={values.articleId && values.articleId != ''}
                            value={this.state.categoryName || values.categoryName || ''}
                            onChange={change => {
                              setFieldValue('categoryName', '');
                              setFieldValue('categoryId', null);
                              this.setState({ categoryName: change });
                            }}
                            getSuggestions={value => this._getSuggestions(this.props.categories, value)}
                            onSuggestionSelected={category => {
                              setFieldValue('categoryName', category.label);
                              setFieldValue('categoryId', category.value);
                              setFieldValue('articleName', '');
                              setFieldValue('articleId', '');
                              setFieldValue('articles', this.filterArticles(category.value, values.supplierId));
                              this.setState({ articleName: '' });
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="articleName"
                      onChange={handleChange}
                      render={() => {
                        return (
                          <AutocompleteInput
                            id="articleName"
                            name="articleName"
                            label="Articulo"
                            margin="normal"
                            variant="outlined"
                            error={touched.articleName && (!!errors.articleName || !!errors.articleId)}
                            helperText={touched.articleName && (errors.articleName || errors.article)}
                            value={this.state.articleName || values.articleName || ''
                            }
                            onChange={change => {
                              setFieldValue('articleName', '');
                              setFieldValue('articleId', '');
                              this.setState({ articleName: change });
                            }}
                            getSuggestions={value => this._getSuggestions(this.filterArticles(values.categoryId, values.supplierId), value)}
                            onSuggestionSelected={article => {
                              setFieldValue('articleName', article.label);
                              setFieldValue('articleId', article.value);
                              this._setCategoryValues(
                                this.props.dataToCreateCommissions.data,
                                article.value,
                                setFieldValue
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <Field
                      name="saleChannelName"
                      onChange={handleChange}
                      render={() => {
                        return (
                          <AutocompleteInput
                            id="saleChannelName"
                            name="saleChannelName"
                            label="Medio de recepción"
                            margin="normal"
                            variant="outlined"
                            error={
                              touched.saleChannelName &&
                              (!!errors.saleChannelName || !!errors.saleChannelId || !!errors.sale_channel_id)
                            }
                            helperText={
                              touched.saleChannelName &&
                              (errors.saleChannelName || errors.saleChannelId || errors.sale_channel_id)
                            }
                            value={
                              this.state.saleChannelName ||
                              values.saleChannelName ||
                              ''
                            }
                            onChange={change => {
                              setFieldValue('saleChannelName', '');
                              setFieldValue('saleChannelId', '');
                              this.setState({ saleChannelName: change });
                            }}
                            getSuggestions={value =>
                              this._getSuggestions(this.props.dataToCreateCommissions.saleChannels, value)
                            }
                            onSuggestionSelected={saleChannel => {
                              setFieldValue('saleChannelName', saleChannel.label);
                              setFieldValue('saleChannelId', saleChannel.value);
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                </>
              )}
            </Grid>
          </Modal>
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(CommissionFormModal);

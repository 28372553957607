import React, { Fragment, PureComponent } from 'react';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../../helpers/combineStyles';
import { compose } from 'redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { PersonOutline } from '@material-ui/icons';
import TypographyStyles from '../../../styles/typography';
import classnames from 'classnames';
import StatusLabel from '../../pages/SaleDetailPage/Status/StatusLabel';
import { STATUSES } from '../../pages/SaleDetailPage/Status/constanst';

class CommentItem extends PureComponent {
  render() {
    const {
      classes,
      withAvatar,
      photo,
      name,
      date,
      username,
      comment,
      type,
      status
    } = this.props;

    let finalStatus = STATUSES[status] || '';;
    let finalType = '';

    if (type === 'SaleReturnGroup') {
      finalStatus = `Devolución ${finalStatus}`;
      finalType = 'RETURN_COMMENT';
    } else if (type === 'DocumentalRevision') {
      finalStatus = `Revisión soporte ${finalStatus}`.replace('Anulada', 'Rechazada');
      finalType = 'DOC_COMMENT';
    } else {
      finalType = finalStatus
        .replace('Devolución parcial', 'Devuelta')
        .replace('Anulación parcial', 'Anulada');
    }

    return (
      <ListItem alignItems="flex-start" classes={{ root: classes.listItem }}>
        {withAvatar && (
          <ListItemAvatar>
            <Avatar
              classes={{ root: classes.avatar }}
              src={photo ? photo : null}
            >
              {photo ? null : <PersonOutline />}
            </Avatar>
          </ListItemAvatar>
        )}
        <div className={classes.container}>
          <div className={classes.header}>
            <ListItemText
              classes={{
                primary: classnames(classes.subtitle2, classes.primary),
                secondary: classnames(classes.body2, classes.secondary)
              }}
              primary={
                <Fragment>
                  <span className={classes.name}>{name.toLowerCase()}</span>
                  {` @${username} `}
                  <span>{date ? `· ${moment(date).format('LL')}, ${moment(date).format('LT')}` : ''}</span>
                  {status && <div className={classes.comment}>
                    <StatusLabel
                      label={finalStatus}
                      type={finalType}
                      isMovement={Boolean(status)}
                    />
                  </div>}
                </Fragment>
              }
              secondary={comment}
            />
          </div>
        </div>
      </ListItem>
    );
  }
}

const styles = theme => ({
  listItem: {
    padding: '16px !important'
  },
  comment: {
    display: 'inline',
    marginLeft: '8px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  avatar: {
    margin: '0 !important'
  },
  primary: {
    margin: '0 !important',
    wordWrap: 'break-word',
    fontWeight: '500 !important'
  },
  secondary: {},
  name: {
    fontWeight: 'bold !important',
    color: theme.typography.color.main,
    textTransform: 'capitalize'
  }
});

export default compose(
  withStyles(combineStyles(styles, TypographyStyles), { withTheme: true })
)(CommentItem);

/* eslint-disable complexity */
import moment from 'moment-timezone';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _get from 'lodash/get';
import camelcaseKeys from 'camelcase-keys';

const _name = user => {
  if (!user) return '';
  const fullname = `${_get(user, 'firstName', '--')} ${_get(
    user,
    'lastName',
    '--'
  )}`;
  return 'Por: ' + fullname.toLowerCase();
};

const stateTranslations = {
  registered: 'Registrada',
  reviewed: 'Revisada',
  revoked: 'Rechazada',
  approved: 'Aprobada',
  deactivated: 'Desactivada'
};

export const MapSteps = (movements, disables = []) => {
  const steps = [];
  const groupedMovements = _groupBy(
    movements.map(m => camelcaseKeys(m, { deep: true })),
    'status'
  );

  const statesOrder = ['registered', 'reviewed', 'revoked', 'approved', 'deactivated'];

  for (const status of statesOrder) {
    if (disables.includes(status)) continue;

    const movementsForStatus = _sortBy(groupedMovements[status] || [], m =>
      moment(m.createdAt)
    );

    for (const movement of movementsForStatus) {
      if (steps.some(step => step.id === movement.id)) continue;

      steps.push({
        id: movement.id,
        isCompleted: true,
        status: stateTranslations[status] || status,
        createdBy: movement.user ? _name(movement.user) : '--',
        createdAt: movement.createdAt
          ? moment(movement.createdAt).format('YYYY/MM/DD - h:mm A')
          : '--'
      });
    };
  };

  const orderedSteps = _sortBy(steps, step => moment(step.createdAt, 'YYYY/MM/DD - h:mm A'));

  if (orderedSteps.length === 1) {
    const rejected = _find(movements, { status: 'revoked' });

    if (!rejected && !disables.includes('reviewed')) {
      orderedSteps.push({
        isCompleted: false,
        status: 'Revisada',
        createdBy: '--',
        createdAt: '--'
      });
    }

    if (!rejected && !disables.includes('approved')) {
      orderedSteps.push({
        isCompleted: false,
        status: 'Aprobada',
        createdBy: '--',
        createdAt: '--'
      });
    }
  }

  return orderedSteps;
};

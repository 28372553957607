import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DocRevisionList from './DocRevisionList';

import { fetchDocRevisions, approveDocRevision, revokeDocRevision } from '../../../actions/doc_revision_actions';

function mapStateToProps(state) {
  const { meta, docRevisions } = state.docRevisions;

  return {
    docRevisions,
    meta,
    suppliers: state.globalSetting.orgData.suppliers,
    requestSend: state.requestState.requestSend,
    requestRevokeDocRevisionSend: state.requestState.requestRevokeDocRevisionSend,
    requestApproveDocRevisionSend: state.requestState.requestApproveDocRevisionSend,
    canApproveDocRevisions: state.authentication.currentUser.canApproveDocRevisions,
    canRevokeDocRevisions: state.authentication.currentUser.canRevokeDocRevisions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocRevisions: bindActionCreators(fetchDocRevisions, dispatch),
    approveDocRevision: bindActionCreators(approveDocRevision, dispatch),
    revokeDocRevision: bindActionCreators(revokeDocRevision, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DocRevisionList);

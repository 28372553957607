import _pickBy from 'lodash/pickBy';

export const promissoryFiltersSanitizer = filters => {
  const {
    code,
    status,
    contract,
    registered_at,
    is_unique,
    page,
    per_page,
    is_unmaterialized
  } = filters;
  const registered_at_range = registered_at ? registered_at.split(' - ') : [];

  const sanitizedFilters = {
    registeredAtGteq: registered_at_range[0],
    registeredAtLteq: registered_at_range[1],
    codeCont: code,
    contractIdEq: contract,
    status: status,
    isUniqueEq: is_unique,
    isUnmaterializedEq: is_unmaterialized,
    page: page,
    perPage: per_page
  };
  return _pickBy(sanitizedFilters, filter => !!filter);
};

export const promissoryRangesFilterSanitizer = filters => {
  const { corporation, promissoryNumber, status, page, rowsPerPage } = filters;

  const sanitizedFilters = {
    range: promissoryNumber,
    corporation,
    page: page,
    rowsPerPage
  };
  if (status === 'activos') {
    sanitizedFilters.enable = true;
  }

  if (status === 'inactivos') {
    sanitizedFilters.enable = false;
  }
  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleRegisterIdentity from './DigitalSaleRegisterIdentity';
import { newAlert } from '../../../actions/alert_actions';
import {
  persistStepper,
  setCurrentStep
} from '../../../actions/stepper_actions';
import { setNextStep } from '../../../actions/portal_financing_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    process: state.stepper.process,
    contract: pf.debtor_contract_number,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    baseErrorOpen: pf.baseErrorOpen,
    routes: state.stepper.routes,
    debtorIdentification: pf.debtor_identification,
    debtorIdentificationType: pf.debtor_identification_type,
    isFrontCapturedDebtorDocument: pf.is_front_captured_debtor_document,
    isBackCapturedDebtorDocument: pf.is_back_captured_debtor_document,
    stepIsLoading: state.portalFinancing.stepIsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    persistStepper: bindActionCreators(persistStepper, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleRegisterIdentity);

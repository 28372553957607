import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { newAlert } from '../../../actions/alert_actions';
import {
  setNextStep,
  checkDigitalSaleSignedUnmaterializedPromissory,
  previewDocumentV2,
  resendOlimpiaOTP,
  reSendOtpV2,
  sendOtpV2,
  validateOtpV2,
  validateSignedDocumentV2
} from '../../../actions/portal_financing_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import DigitalSaleOTPPromissory from './DigitalSaleOTPPromissory';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const isMyPromissoriesV2Active =
    state.globalSetting.orgData.is_my_promissories_v2_active &&
    state.authentication.currentUser.canInitiateOlimpiaV2Process;

  let debtorUnmaterializedPromissoryUrl = '';
  let cosignerUnmaterializedPromissoryUrl = '';

  if (!isMyPromissoriesV2Active && pf.unmaterialized_promissory_url) {
    debtorUnmaterializedPromissoryUrl = pf.unmaterialized_promissory_url.debtor;
    cosignerUnmaterializedPromissoryUrl =
      pf.unmaterialized_promissory_url.cosigner;
  }

  return {
    process: state.stepper.process,
    digitalSaleWithUUPNext: state.stepper.digitalSaleWithUUPNext,
    digitalSaleWithouFaceIdNext: state.stepper.digitalSaleWithouFaceIdNext,
    digitalSaleWithoutUUPonContractNext:
      state.stepper.digitalSaleWithoutUUPonContractNext,
    serverErrors: pf.errors,
    contract: pf.debtor_contract_number,
    digitalSaleWithPUI: pf.digital_sale_with_pui,
    routes: state.stepper.routes,
    tempPromissory: pf.tempPromissory,
    debtorIdentification: pf.debtor_identification,
    debtorIdentificationType: pf.debtor_identification_type,
    debtorUnmaterializedPromissoryUrl,
    cosignerUnmaterializedPromissoryUrl,
    unmaterializedPromissoryProcessId: pf.unmaterialized_promissory_process_id,
    promissoryUrl: pf.promissory_url,
    isMyPromissoriesV2Active,
    otpV2TransaccionId: pf.otpV2TransaccionId,
    otpValidatorDebtor: pf.otpValidatorDebtor,
    otpValidatorCosigner: pf.otpValidatorCosigner,
    promissoryId: pf.promissory_id,
    loading: pf.stepIsLoading,
    documentSerial: pf.documentSerialV2,
    orgDataLoading: state.requestState.requestOrgDataSend,
    previewPromissoryLoading: state.requestState.requestPreviewDocumentSend,
    requestSendOtpV2: state.requestState.requestSendV2OtpSend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    checkDigitalSaleSignedUnmaterializedPromissory: bindActionCreators(
      checkDigitalSaleSignedUnmaterializedPromissory,
      dispatch
    ),
    resendOlimpiaOTP: bindActionCreators(resendOlimpiaOTP, dispatch),
    sendOtpV2: bindActionCreators(sendOtpV2, dispatch),
    reSendOtpV2: bindActionCreators(reSendOtpV2, dispatch),
    validateOtpV2: bindActionCreators(validateOtpV2, dispatch),
    validateSignedDocumentV2: bindActionCreators(
      validateSignedDocumentV2,
      dispatch
    ),
    previewDocumentV2: bindActionCreators(previewDocumentV2, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleOTPPromissory);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Card } from '@material-ui/core';
import _get from 'lodash/get';
import _find from 'lodash/find';
import ActivityIndicator from '../../shared/ActivityIndicator';
import classnames from 'classnames';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import SaleStatusHeader from './Status/Status';
import SaleCustomer from './Customer';
import SaleRegistererDetail from './RegistererDetail';
import SalePromissory from './Promissory/Promissory';
import Details from './Details/Details';
import Activity from './Activity/Activity';
import Document from './Document/Document';
import Comments from '../Comments';
import ProductDelivery from '../ProductDelivery';
import RegisterSinister from '../RegisterSinister';
import ApproveSale from '../ApproveSale';
import CancelSale from '../CancelSale';
import ReturnSale from '../ReturnSale';
import { STATUSES as SALE_STATUSES } from './Status/constanst';
import { MOVEMENT_STATUSES } from './Status/constanst';
import { DOC_REVISION_VERBOSE_STATUS } from '../../../constants/enums';

class SaleDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approveFormOpen: false,
      rejectFormOpen: false,
      quotaTransferOpen: false
    };
  }

  componentDidMount() {
    !this.props.companyCode && this.props.fetchSessionData();
    this.fetchSaleDetails();
  }

  fetchSaleDetails = () => {
    this.props.details(this.props.match.params.id).then(() => {
      this._getPDFs();
    });
  };

  _validatePromissoryStatus = promissory => {
    if (promissory) {
      if (!this.props.canApprovePromissory) return false;
      if (promissory.status == 'registered') return true;
      return false;
    }
    return false;
  };

  _canReturnSale = () => {
    if (!this.props.canReturnSale) return false;
    return this._canReturn();
  };

  _canReturnSaleManual = () => {
    if (
      this.props.canReturnSaleManual ||
      this.props.canReturnArticlesSaleManual
    ) {
      return this._canReturn();
    }
    return false;
  };

  _canReturn = () => {
    const { salesDetail } = this.props;
    if (!salesDetail) return false;
    if (salesDetail.hasPendingTotalReturn) return false;
    if (salesDetail['hasPendingQuotaTransfer']) return false;
    if (salesDetail['hasPendingQuotaUnion']) return false;
    if (
      salesDetail['hasPendingReturnProcess'] &&
      salesDetail['returnProcessType'] == 'total'
    ) {
      return false;
    }
    const something_to_return = _find(
      salesDetail.saleDetails,
      a => a.status == 'delivered'
    );
    if (!something_to_return) return false;
    return true;
  };

  handleModal = name => {
    this.setState({ [name]: !this.state[name] });
  };

  handleApprovePromissory = body => {
    const { salesDetail } = this.props;
    this.props.approvePromissory(
      body,
      salesDetail.promissory.id,
      salesDetail.id
    );
  };
  handleRejectPromissory = body => {
    const { salesDetail } = this.props;
    this.props.rejectPromissory(
      body,
      salesDetail.promissory.id,
      salesDetail.id
    );
  };

  _getActivities = () => {
    const { salesDetail } = this.props;
    const activities = [];

    activities.push({
      title: SALE_STATUSES.REGISTERED,
      user: salesDetail.registerer,
      date: salesDetail.registeredAt
    });

    if (salesDetail.approvedAt) {
      activities.push({
        title: SALE_STATUSES.APPROVED,
        user: salesDetail.approver,
        date: salesDetail.approvedAt
      });
    }

    const partiallyRevokedMovement = salesDetail.movements.find(
      element => element.status === MOVEMENT_STATUSES.PARTIALLY_REVOKED
    );

    if (partiallyRevokedMovement) {
      activities.push({
        title: SALE_STATUSES.PARTIALLY_REVOKED,
        user: partiallyRevokedMovement.user,
        date: partiallyRevokedMovement.createdAt
      });
    }

    if (salesDetail.deliveredAt) {
      activities.push({
        title: SALE_STATUSES.DELIVERED,
        date: salesDetail.deliveredAt,
        user: salesDetail.deliverer
      });
    }

    const partiallyReturnedManualMovement = salesDetail.movements.find(
      element => element.status === MOVEMENT_STATUSES.PARTIALLY_RETURNED_MANUAL
    );

    if (partiallyReturnedManualMovement) {
      activities.push({
        title: SALE_STATUSES.PARTIALLY_RETURNED_MANUAL,
        user: partiallyReturnedManualMovement.user,
        date: partiallyReturnedManualMovement.createdAt
      });
    }

    const returnedManualMovement = salesDetail.movements.find(
      element => element.status === MOVEMENT_STATUSES.RETURNED_MANUAL
    );

    if (returnedManualMovement) {
      activities.push({
        title: SALE_STATUSES.RETURNED_MANUAL,
        user: returnedManualMovement.user,
        date: returnedManualMovement.createdAt
      });
    }

    const partiallyReturnedMovement = salesDetail.movements.find(
      element => element.status === MOVEMENT_STATUSES.PARTIALLY_RETURNED
    );

    if (partiallyReturnedMovement) {
      activities.push({
        title: SALE_STATUSES.PARTIALLY_RETURNED,
        user: partiallyReturnedMovement.user,
        date: partiallyReturnedMovement.createdAt
      });
    }

    const returnedMovement = salesDetail.movements.find(
      element => element.status === MOVEMENT_STATUSES.RETURNED
    );

    if (returnedMovement) {
      activities.push({
        title: SALE_STATUSES.RETURNED,
        user: returnedMovement.user,
        date: returnedMovement.createdAt
      });
    }

    if (salesDetail.canceledAt) {
      activities.push({
        title: SALE_STATUSES.REVOKED,
        user: salesDetail.canceler,
        date: salesDetail.canceledAt
      });
    }

    if (salesDetail.documentalRevision) {
      for (const movement of salesDetail.documentalRevision.movements) {
        activities.push({
          title: DOC_REVISION_VERBOSE_STATUS[movement.status],
          user: movement.user,
          date: movement.createdAt
        });
      }
    }

    return activities;
  };
  _getComments = () => {
    const {
      salesDetail: { comments, movements, saleReturnGroups, documentalRevision }
    } = this.props;
    const newComments = [];
    const revisionMovements = documentalRevision ? documentalRevision.movements : [];

    [
      ...movements,
      ...comments,
      ...revisionMovements,
      ...saleReturnGroups.map(srg => srg.movements).flat()
    ].map(item => {
      if (item.comment) newComments.push(item);
    });
    newComments.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    return newComments;
  };

  _permissionDocuments = () => {
    const {
      canGenPromissoryPDF,
      canGenInvoicePDF,
      canGenVoucherPDF,
      canGenAmortizationPDF,
      canGenSecurePDF
    } = this.props;

    return Boolean(
      canGenPromissoryPDF ||
      canGenInvoicePDF ||
      canGenVoucherPDF ||
      canGenAmortizationPDF ||
      canGenSecurePDF
    );
  };
  _permissionComments = () => {
    const { canCreateSaleComments, canListSaleComments } = this.props;
    if (canCreateSaleComments || canListSaleComments) return true;
    return false;
  };

  _canDeliver = sale => {
    if (sale && sale['hasPendingQuotaTransfer']) return false;
    if (sale && sale['hasPendingQuotaUnion']) return false;
    if (
      this.props.canDeliverSale &&
      sale &&
      sale.canDeliver &&
      !sale.saleType > 0
    )
      return true;
    return false;
  };

  _getPDFs = () => {
    const {
      salesDetail,
      canGenPromissoryPDF,
      canGenInvoicePDF,
      canGenVoucherPDF,
      canGenAmortizationPDF,
      canGenSecurePDF,
      canGenEnsuranceRequestPDF,
      cardifArticleLayerOne,
      voluntaryInsuranceLayerTwoArticleId
    } = this.props;

    if (salesDetail) {
      //remove layer 1 while sbb-1521 is merged
      const hasCardifArticleLayerOne = salesDetail.saleDetails.find(
        sd => sd.article.id === _get(cardifArticleLayerOne, 'id')
      );
      const hasCardifArticleLayerTwo = salesDetail.saleDetails.find(
        sd => sd.article.id === voluntaryInsuranceLayerTwoArticleId
      );

      canGenPromissoryPDF && this.props.pdfPromissory(salesDetail.id);
      canGenVoucherPDF && this.props.pdfVoucher(salesDetail.id);
      canGenAmortizationPDF && this.props.pdfAmortization(salesDetail.id);
      canGenSecurePDF && this.props.pdfSecure(salesDetail.id);
      canGenInvoicePDF &&
        salesDetail.supplier &&
        salesDetail.supplier.requiresBin &&
        this.props.pdfBin(salesDetail.id);
      //remove layer 1 while sbb-1521 is merged
      canGenEnsuranceRequestPDF &&
        hasCardifArticleLayerOne &&
        this.props.pdfRequestCardif(salesDetail.id);
      canGenEnsuranceRequestPDF &&
        hasCardifArticleLayerTwo &&
        this.props.pdfRequestCardifLayerTwo(salesDetail.id);
    }
  };
  _showCosigner = salesDetail => {
    return salesDetail.cosigner || salesDetail.cosignerDetails;
  };
  // eslint-disable-next-line complexity
  render() {
    const {
      salesDetail,
      sale,
      classes,
      requestSend,
      canGenPromissoryPDF,
      canGenInvoicePDF,
      canGenVoucherPDF,
      canGenAmortizationPDF,
      canGenSecurePDF,
      canGenEnsuranceRequestPDF,
      requestPromissoryUrl,
      requestVoucherUrl,
      requestBinUrl,
      requestSecureUrl,
      requestAmortizationUrl,
      requestCardifUrl,
      requestCardifUrlLayerTwoUrl,
      requestSatisfactoryDeliveryFormatUrl,
      canViewPromissories,
      canReattachVoucherOnRemoteSale,
      canAttachDocumentsOnTraditionalSale,
      canAttachDocumentsForDocRevisions,
      scoringExtraQuotaId,
      scoringExtraQuotaName,
      cardifArticleLayerOne,
      voluntaryInsuranceLayerTwoArticleId,
      voluntaryInsuranceLayerTwoName
    } = this.props;
    //remove layer 1 while sbb-1521 is merged
    let hasCardifArticleLayerOne;
    let hasCardifArticleLayerTwo;

    if (salesDetail) {
      //remove layer 1 while sbb-1521 is merged
      hasCardifArticleLayerOne = salesDetail.saleDetails.find(
        sd => sd.article.id === _get(cardifArticleLayerOne, 'id')
      );
      hasCardifArticleLayerTwo = salesDetail.saleDetails.find(
        sd => sd.article.id === voluntaryInsuranceLayerTwoArticleId
      );
    }
    const hasPendingTotalReturn =
      _get(salesDetail, 'returnProcessType', false) == 'total';
    const hasPendingPartialReturn =
      _get(salesDetail, 'returnProcessType', false) == 'partial';
    const canReturnSale = this._canReturnSale();
    const canReturnSaleManual = this._canReturnSaleManual();
    const canDeliver = this._canDeliver(salesDetail);

    return (
      <Fragment>
        {(requestSend || !salesDetail) && <ActivityIndicator />}
        {!requestSend && salesDetail && (
          <div>
            <div className={classes.titleRootWrapper}>
              <h1 className={classnames(classes.headline4, classes.title)}>
                {`Venta #${salesDetail.id}`}
              </h1>
            </div>
            <Grid container spacing={16}>
              {this.props.canApproveSale && salesDetail.canApprove && (
                <Grid item xs={12}>
                  {salesDetail.tagCodes == 'datacrédito-no-respuesta' && (
                    <Card className={classes.alertMessage}>
                      <p>
                        Revisar documentos ingresados, ocurrió un error al
                        obtener respuesta de datacrédito.
                      </p>
                    </Card>
                  )}
                  {salesDetail.tagCodes == 'datacrédito-no-cédula-deudor' && (
                    <Card className={classes.alertMessage}>
                      <p>
                        Revisar documentos ingresados, respuesta de datacrédito
                        indica tipo de documento de deudor diferente a cédula.
                      </p>
                    </Card>
                  )}
                  {salesDetail.tagCodes ==
                    'datacrédito-no-respuesta-deudor' && (
                      <Card className={classes.alertMessage}>
                        <p>
                          Revisar documentos ingresados, sistema de datacrédito no
                          encuentra información de deudor.
                        </p>
                      </Card>
                    )}
                  {salesDetail.tagCodes == 'datacrédito-no-cédula-codeudor' && (
                    <Card className={classes.alertMessage}>
                      <p>
                        Revisar documentos ingresados, respuesta de datacrédito
                        indica tipo de documento de codeudor diferente a cédula.
                      </p>
                    </Card>
                  )}
                  {salesDetail.tagCodes ==
                    'datacrédito-no-respuesta-codeudor' && (
                      <Card className={classes.alertMessage}>
                        <p>
                          Revisar documentos ingresados, sistema de datacrédito no
                          encuentra información de codeudor.
                        </p>
                      </Card>
                    )}
                  {salesDetail.tagCodes == 'datacrédito-fallecido' && (
                    <Card className={classes.alertMessage}>
                      <p>
                        Revisar documentos ingresados, respuesta de datacrédito
                        indica identificación de persona fallecida.
                      </p>
                    </Card>
                  )}
                </Grid>
              )}
              <Grid item xs={12} lg={12}>
                <SaleStatusHeader
                  saleDetail={salesDetail}
                  reFetch={this.fetchSaleDetails}
                  toggleAction={this.props.toggleSaleAction}
                  canApproveSale={
                    this.props.canApproveSale &&
                    salesDetail.canApprove &&
                    !salesDetail.saleType > 0
                  }
                  canRevokeSales={this.props.canRevokeSales}
                  canRevokeArticlesSales={this.props.canRevokeArticlesSales}
                  daysForSinister={this.props.daysForSinister}
                  canCancelSale={this.props.canCancelSale}
                  canRegisterSinisters={this.props.canRegisterSinisters}
                  canReturnSale={canReturnSale}
                  canReturnManual={canReturnSaleManual}
                  canDeliver={canDeliver}
                  canReviewTransfer={this.props.canReviewQuotaTransfer}
                  canApproveTransfer={this.props.canApproveQuotaTransfer}
                  canRevokeTransfer={this.props.canRevokeQuotaTransfer}
                  hasPendingTotalReturn={hasPendingTotalReturn}
                  onOpenRevision={this.handleModal}
                />
              </Grid>

              <Grid item xs={12} lg={9}>
                <Details
                  sale={{
                    ...salesDetail,
                    scoringExtraQuotaId,
                    scoringExtraQuotaName
                  }}
                />
                <SalePromissory
                  promissory={salesDetail.promissory}
                  canApprovePromissory={this._validatePromissoryStatus(
                    salesDetail.promissory
                  )}
                  handleModal={this.handleModal}
                  approveFormOpen={this.state.approveFormOpen}
                  rejectPromissoryCausals={this.props.rejectPromissoryCausals}
                  rejectFormOpen={this.state.rejectFormOpen}
                  onApprove={this.handleApprovePromissory}
                  onReject={this.handleRejectPromissory}
                  requestApprovePromissorySend={
                    this.props.requestApprovePromissorySend
                  }
                  requestRejectPromissorySend={
                    this.props.requestRejectPromissorySend
                  }
                  canViewPromissories={canViewPromissories}
                />
                <SaleCustomer
                  customer={salesDetail.debtor || {}}
                  contract={salesDetail.debtorContract}
                  customerDetails={salesDetail.debtorDetails || {}}
                />
                {this._showCosigner(salesDetail) && (
                  <SaleCustomer
                    type="Codeudor"
                    customer={salesDetail.cosigner || {}}
                    contract={salesDetail.cosignerContract}
                    customerDetails={salesDetail.cosignerDetails || {}}
                    status={
                      salesDetail.promissory.solidaryDebtor
                        ? 'Solidario'
                        : 'Obligatorio'
                    }
                    reasonSolidaryDebtor={
                      salesDetail.promissory.solidaryDebtor
                        ? salesDetail.promissory.reasonSolidaryDebtor
                        : null
                    }
                    solidaryDebtorCauses={this.props.solidaryDebtorCauses}
                  />
                )}
                {this._permissionComments() && (
                  <Comments
                    entityName="Sale"
                    disableCreation={
                      !this.props.canCreateSaleComments ||
                      !salesDetail.externalCode
                    }
                    object={salesDetail}
                    previousComments={this._getComments()}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={3}>
                <SaleRegistererDetail sale={salesDetail} />
                <Activity activities={this._getActivities()} />
                {this._permissionDocuments() && (
                  <Document
                    salesDetail={salesDetail}
                    secureSaleArticleId={this.props.secureSaleArticleId}
                    pdfPromissory={this.props.pdfPromissory}
                    pdfVoucher={this.props.pdfVoucher}
                    pdfAmortization={this.props.pdfAmortization}
                    pdfSecure={this.props.pdfSecure}
                    pdfBin={this.props.pdfBin}
                    promissoryUrl={this.props.promissoryUrl}
                    binUrl={this.props.binUrl}
                    voucherUrl={this.props.voucherUrl}
                    secureUrl={this.props.secureUrl}
                    amortizationUrl={this.props.amortizationUrl}
                    cardifUrl={this.props.cardifUrl}
                    cardifLayerTwoUrl={this.props.cardifLayerTwoUrl}
                    satisfactoryDeliveryFormatUrl={
                      this.props.satisfactoryDeliveryFormatUrl
                    }
                    canGenPromissoryPDF={canGenPromissoryPDF}
                    canGenInvoicePDF={canGenInvoicePDF}
                    canGenVoucherPDF={canGenVoucherPDF}
                    canGenAmortizationPDF={canGenAmortizationPDF}
                    canGenEnsuranceRequestPDF={canGenEnsuranceRequestPDF}
                    canGenSecurePDF={canGenSecurePDF}
                    canReattachVoucherOnRemoteSale={
                      canReattachVoucherOnRemoteSale
                    }
                    canAttachDocumentsOnTraditionalSale={
                      canAttachDocumentsOnTraditionalSale
                    }
                    canAttachDocumentsForDocRevisions={
                      canAttachDocumentsForDocRevisions
                    }
                    requestPromissoryUrl={requestPromissoryUrl}
                    requestVoucherUrl={requestVoucherUrl}
                    requestBinUrl={requestBinUrl}
                    requestSecureUrl={requestSecureUrl}
                    requestSatisfactoryDeliveryFormatUrl={
                      requestSatisfactoryDeliveryFormatUrl
                    }
                    requestAmortizationUrl={requestAmortizationUrl}
                    requestCardifUrl={requestCardifUrl}
                    requestCardifUrlLayerTwoUrl={requestCardifUrlLayerTwoUrl}
                    companyCode={this.props.companyCode}
                    hasCardifArticleLayerOne={hasCardifArticleLayerOne}
                    hasCardifArticleLayerTwo={hasCardifArticleLayerTwo}
                    voluntaryInsuranceLayerTwoName={
                      voluntaryInsuranceLayerTwoName
                    }
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}

        {this.props.canApproveSale && <ApproveSale sale={salesDetail} />}
        {this.props.canDeliverSale && <ProductDelivery sale={salesDetail} />}
        {(this.props.canRevokeSales || this.props.canRevokeArticlesSales) && (
          <CancelSale sale={salesDetail} />
        )}
        {this.props.canRegisterSinisters && (
          <RegisterSinister sale={salesDetail} />
        )}
        {this.props.canCancelSale && <CancelSale sale={salesDetail} />}
        {canReturnSale && (
          <ReturnSale
            sale={salesDetail}
            hasPendingPartialReturn={hasPendingPartialReturn}
          />
        )}
        {canReturnSaleManual && (
          <ReturnSale
            sale={salesDetail}
            hasPendingPartialReturn={hasPendingPartialReturn}
          />
        )}
      </Fragment>
    );
  }
}

SaleDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  salesDetail: PropTypes.object,
  match: PropTypes.object.isRequired,
  clientUpdate: PropTypes.func,
  approve: PropTypes.func,
  details: PropTypes.func,
  newAlert: PropTypes.func
};

export default withStyles(
  combineStyles(LayoutStyles, ButtonStyles, TypographyStyles, SpacingStyles)
)(SaleDetail);

/* eslint-disable complexity */
import PointUserAssociationRequests from '../PointUserAssociationRequests';
import SalesList from '../SalesList';
import SaleDetailPage from '../SaleDetailPage';
import SalesReturnList from '../SalesReturnList';
import PromissoryList from '../PromissoryList';
import PromissoryDetail from '../PromissoryDetail';
import ClientList from '../ClientList';
import ClientDetails from '../ClientDetail';
import CorporationList from '../CorporationList';
import CorporationDetail from '../CorporationDetail';
import SupplierList from '../SupplierList';
import SupplierDetail from '../SupplierDetail';
import PointOfSaleList from '../PointOfSaleList';
import PointOfSaleDetail from '../PointOfSaleDetail';
import ArticleList from '../ArticleList';
import CategoryList from '../CategoryList';
import UserList from '../UserList';
import UserDetail from '../UserDetail';
import BrandList from '../BrandList';
import CommissionList from '../CommissionList';
import ExtraQuotumList from '../ExtraQuotumList';
import GlobalSettingList from '../GlobalSettingList';
import Portal from '../Portal';
import DigitalSale from '../DigitalSale';
import DigitalSaleVoucher from '../DigitalSaleVoucher';
import RemoteDigitalSale from '../RemoteDigitalSale';
import Teleshopping from '../Teleshopping';
import RemoteTeleshopping from '../RemoteTeleshopping';
import RegisterPromissory from '../RegisterPromissory';
import PromissoryRanges from '../PromissoryRanges';
import ManualQuotaList from '../ManualQuotaList';
import QueryPromissory from '../QueryPromissory';
import Dashboard from '../Dashboard';
import SystemStatus from '../SystemStatus';
import FinancialPlanList from '../FinancialPlanList';
import FinancialDetailsList from '../FinancialDetailsList';
import SchedulePointOfSaleConfigurationList from '../SchedulePointOfSaleConfigurationList';
import QueryQuota from '../QueryQuota';
import QueryScoringQuota from '../QueryScoringQuota';
import QueryMegaQuota from '../QueryMegaQuota';
import CalculateInstalments from '../CalculateInstalments';
import HomeVisitList from '../HomeVisitList';
import DashboardSellers from '../DashboardSellers';
import RankingList from '../RankingList';
import Page404 from '../Page404';
import ScoringConfigurationList from '../ScoringConfigurationList';
import Insurance from '../Insurance';
import InsuranceList from '../InsuranceList';
import InsuranceDetails from '../InsuranceDetail';
import QuotaConfigurationList from '../QuotaConfigurationList';
import SubSegmentList from '../SubSegmentList';
import ScoringResponsesList from '../ScoringResponsesList';
import RegisterSchedule from '../RegisterSchedule';
import CampaignList from '../CampaignList';
import SupplierProposalsList from '../../pages/SupplierProposalsList';
import LineExecutiveProfileList from '../../pages/LineExecutiveProfileList';
import LocationExceptionList from '../LocationExceptionList';
import PandotyCoursesDetails from '../PandotyCoursesDetails';
import BulkLoadList from '../BulkLoadList';
import SinisterList from '../SinisterList';
import SecureCreditInstalmentsPercentageList from '../SecureCreditInstalmentsPercentageList';
import SecureProductMovements from '../SecureProductMovements';
import DocRevisionList from '../DocRevisionList';

import {
  BarChart,
  PieChart,
  LocalAtmTwoTone,
  AssignmentTurnedInTwoTone,
  PhoneInTalkTwoTone,
  AccountCircleTwoTone,
  Group,
  LocationCityTwoTone,
  StoreMallDirectoryTwoTone,
  AssignmentTwoTone,
  Settings as SettingsIcon,
} from '@material-ui/icons';

const routes = (currentUser, menuFunctions) => {
  const permissions = currentUser.permissions.map(p => p.action);
  const mobileConfirmed = currentUser.mobileConfirmedAt;
  const supplier = currentUser.supplier;
  const pointOfSales = currentUser.pointOfSales;
  let systemStatus = SystemStatus;
  let homePage = SalesList;
  let home = DashboardSellers;
  if (
    permissions.includes('dashboard_summaries') &&
    permissions.includes('dashboard_sales') &&
    permissions.includes('dashboard_top_rated')
  ) {
    homePage = Dashboard;
  } else if (
    permissions.includes('dashboard_sellers_summaries') &&
    permissions.includes('dashboard_sellers_sales') &&
    permissions.includes('dashboard_top_ranking') &&
    permissions.includes('dashboard_data_salesman') &&
    permissions.includes('dashboard_data_supplier')
  ) {
    home = DashboardSellers;
  }
  return [
    {
      path: '/404',
      component: Page404,
      isPermitted: true,
      displaySidebarMenu: false
    },
    {
      name: 'Estadísticas',
      groupId: 'statistics',
      icon: BarChart,
      isPermitted: true,
      displayInSidebar: true
    },
    {
      path: '/',
      name: '¿Cómo voy?',
      icon: BarChart,
      groupId: 'dashboardSellers',
      isPermitted: true,
      parentId: 'statistics',
      displayInSidebar:
        permissions.includes('dashboard_sellers_summaries') ||
        permissions.includes('dashboard_sellers_sales') ||
        permissions.includes('dashboard_top_ranking') ||
        permissions.includes('dashboard_data_salesman') ||
        permissions.includes('dashboard_data_supplier')
    },
    {
      path: '/',
      parentId: 'dashboardSellers',
      name: 'Dashboard',
      component: home,
      isPermitted:
        permissions.includes('dashboard_sellers_summaries') ||
        permissions.includes('dashboard_sellers_sales') ||
        permissions.includes('dashboard_top_ranking') ||
        permissions.includes('dashboard_data_salesman') ||
        permissions.includes('dashboard_data_supplier'),
      displayInSidebar:
        permissions.includes('dashboard_sellers_summaries') ||
        permissions.includes('dashboard_sellers_sales') ||
        permissions.includes('dashboard_top_ranking') ||
        permissions.includes('dashboard_data_salesman') ||
        permissions.includes('dashboard_data_supplier')
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: PieChart,
      component: homePage,
      isPermitted: true,
      parentId: 'statistics',
      displayInSidebar:
        permissions.includes('dashboard_summaries') ||
        permissions.includes('dashboard_sales') ||
        permissions.includes('dashboard_top_rated')
    },
    {
      path: '/system-status',
      name: 'Estado de servicios',
      component: systemStatus,
      isPermitted: permissions.includes('show_health_view'),
      parentId: 'statistics',
      displayInSidebar:
        permissions.includes('show_health_view')
    },
    {
      path: '/ranking',
      component: RankingList,
      isPermitted: true
    },
    {
      path: '/my-profile',
      component: UserDetail,
      isPermitted: true
    },
    {
      path: '/my-profile/course/:id',
      component: PandotyCoursesDetails,
      isPermitted: true
    },
    {
      name: 'Ventas',
      path: '/sales',
      component: SalesList,
      icon: LocalAtmTwoTone,
      isPermitted: permissions.includes('list_sales'),
      displayInSidebar: true
    },
    {
      name: 'Registro de ventas',
      groupId: 'register_sales',
      icon: StoreMallDirectoryTwoTone,
      isPermitted:
        (permissions.includes('register_sales') ||
          permissions.includes('create_remote_digital_sale') ||
          permissions.includes('create_voluntary_insurance_teleshopping') ||
          permissions.includes('create_unmaterialized_voluntary_insurance_teleshopping')) &&
        supplier,
      displayInSidebar: true
    },
    {
      name: 'Tradicional',
      path: '/portal/validate-debtor',
      component: Portal,
      icon: StoreMallDirectoryTwoTone,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0,
      parentId: 'register_sales',
      displayInSidebar: true
    },
    {
      name: 'Digital',
      path: '/digital-sale/validate-identity',
      component: DigitalSale,
      parentId: 'register_sales',
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier,
      displayInSidebar: true
    },
    {
      name: 'Venta Desmaterializada',
      path: '/remote-sale/validate-debtor',
      component: RemoteDigitalSale,
      parentId: 'register_sales',
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier,
      displayInSidebar: true
    },
    {
      name: 'Televenta de referencia',
      path: '/teleshopping/contract-validation',
      component: Teleshopping,
      parentId: 'register_sales',
      icon: PhoneInTalkTwoTone,
      isPermitted: permissions.includes(
        'create_voluntary_insurance_teleshopping'
      ),
      displayInSidebar: true
    },
    {
      path: '/teleshopping/register',
      component: Teleshopping,
      isPermitted: permissions.includes(
        'create_voluntary_insurance_teleshopping'
      )
    },
    {
      name: 'Televenta de referencia desmaterializada',
      path: '/remote-teleshopping/contract-validation',
      component: RemoteTeleshopping,
      parentId: 'register_sales',
      icon: PhoneInTalkTwoTone,
      isPermitted: permissions.includes(
        'create_unmaterialized_voluntary_insurance_teleshopping'
      ),
      displayInSidebar: true
    },
    {
      path: '/remote-teleshopping/register',
      component: RemoteTeleshopping,
      isPermitted: permissions.includes(
        'create_unmaterialized_voluntary_insurance_teleshopping'
      )
    },
    {
      path: '/remote-teleshopping/contact-data',
      component: RemoteTeleshopping,
      isPermitted:
        permissions.includes('create_remote_digital_sale') &&
        permissions.includes('create_unmaterialized_voluntary_insurance_teleshopping') &&
        supplier
    },
    {
      path: '/remote-teleshopping/otp-sale',
      component: RemoteTeleshopping,
      isPermitted:
        permissions.includes('create_remote_digital_sale') &&
        permissions.includes('create_unmaterialized_voluntary_insurance_teleshopping') &&
        supplier
    },
    {
      path: '/sales/:id(\\d+)',
      component: SaleDetailPage,
      isPermitted: permissions.includes('view_sales')
    },
    {
      name: 'Clientes',
      path: '/clients',
      component: ClientList,
      icon: Group,
      isPermitted: permissions.includes('list_clients'),
      displayInSidebar: true
    },
    {
      path: '/clients/:id',
      component: ClientDetails,
      isPermitted: permissions.includes('view_clients')
    },
    {
      name: 'Servicios',
      groupId: 'insurances',
      icon: LocalAtmTwoTone,
      isPermitted: permissions.includes('list_services'),
      displayInSidebar: true
    },
    {
      name: 'Ver servicios',
      path: '/insurances',
      parentId: 'insurances',
      component: InsuranceList,
      isPermitted: permissions.includes('list_services'),
      displayInSidebar: true
    },
    {
      path: '/insurances/:id',
      component: InsuranceDetails,
      isPermitted: permissions.includes('list_services')
    },
    {
      name: 'Registro de servicios',
      path: '/insurance/validate-contract',
      component: Insurance,
      parentId: 'insurances',
      isPermitted: permissions.includes('create_services'),
      displayInSidebar: true
    },
    {
      path: '/insurance/validate-client',
      component: Insurance,
      isPermitted: permissions.includes('create_services')
    },
    {
      path: '/insurance/summary',
      component: Insurance,
      isPermitted: permissions.includes('create_services')
    },
    {
      name: 'Aliados',
      groupId: 'aliados',
      icon: LocationCityTwoTone,
      isPermitted:
        permissions.includes('list_corporations') ||
        permissions.includes('list_suppliers') ||
        permissions.includes('list_point_of_sales') ||
        permissions.includes('list_my_point_of_sales') ||
        permissions.includes('list_scoring_responses') ||
        permissions.includes('preview_contracts') ||
        permissions.includes('consult_scoring') ||
        permissions.includes('list_supplier_proposals'),
      displayInSidebar: true
    },
    {
      name: 'Empresas',
      path: '/corporations',
      component: CorporationList,
      parentId: 'aliados',
      isPermitted: permissions.includes('list_corporations'),
      displayInSidebar: true
    },
    {
      path: '/corporations/:id',
      component: CorporationDetail,
      isPermitted: permissions.includes('view_corporations')
    },
    {
      name: 'Proveedores',
      path: '/suppliers',
      component: SupplierList,
      parentId: 'aliados',
      isPermitted: permissions.includes('list_suppliers'),
      displayInSidebar: true
    },
    {
      path: '/suppliers/:id',
      component: SupplierDetail,
      isPermitted: permissions.includes('view_suppliers')
    },
    {
      name: 'Puntos de Venta',
      path: '/points-of-sale',
      component: PointOfSaleList,
      parentId: 'aliados',
      isPermitted:
        permissions.includes('list_point_of_sales') ||
        permissions.includes('list_my_point_of_sales'),
      displayInSidebar: true
    },
    {
      path: '/points-of-sale/:id',
      component: PointOfSaleDetail,
      isPermitted: permissions.includes('view_point_of_sales')
    },
    {
      name: 'Resultados de Scoring',
      path: '/scoring_responses',
      component: ScoringResponsesList,
      isPermitted: permissions.includes('list_scoring_responses'),
      parentId: 'aliados',
      displayInSidebar: true
    },
    {
      name: 'Consulta de Cupo',
      component: QueryQuota,
      parentId: 'aliados',
      isPermitted: permissions.includes('preview_contracts'),
      displayInSidebar: true,
      function: menuFunctions.openQueryQuotaModal
    },
    {
      name: 'Consulta de Scoring',
      component: QueryScoringQuota,
      parentId: 'aliados',
      isPermitted: permissions.includes('consult_scoring'),
      displayInSidebar: true,
      function: menuFunctions.openQueryScoringQuotaModal
    },
    {
      name: 'Consulta de Megacupo',
      component: QueryMegaQuota,
      parentId: 'aliados',
      isPermitted: permissions.includes('check_mega_quota_info') && supplier && supplier.canSellWithMegaquota,
      displayInSidebar: true,
      function: menuFunctions.openQueryMegaQuotaModal
    },
    {
      name: 'Cálculo del Valor de las Cuotas',
      component: CalculateInstalments,
      parentId: 'aliados',
      isPermitted: permissions.includes('preview_contracts'),
      displayInSidebar: true,
      function: menuFunctions.openCalculateInstalmentsModal
    },
    {
      name: 'Propuestas',
      parentId: 'aliados',
      isPermitted: permissions.includes('list_supplier_proposals'),
      displayInSidebar: true,
      path: '/supplier-proposals',
      component: SupplierProposalsList
    },
    {
      name: 'Pagarés',
      groupId: 'promissories',
      icon: AssignmentTurnedInTwoTone,
      isPermitted:
        permissions.includes('list_promissories') ||
        permissions.includes('list_promissory_ranges') ||
        permissions.includes('query_promissories') ||
        permissions.includes('register_sales'), // FIXME: ADD PERMISSION
      displayInSidebar: true
    },
    {
      name: 'Ver Pagarés',
      path: '/promissories',
      component: PromissoryList,
      parentId: 'promissories',
      isPermitted: permissions.includes('list_promissories'),
      displayInSidebar: true
    },
    {
      path: '/promissories/:id',
      component: PromissoryDetail,
      isPermitted: permissions.includes('view_promissories')
    },
    {
      name: 'Consultar Pagaré Único',
      component: QueryPromissory,
      parentId: 'promissories',
      isPermitted: permissions.includes('query_promissories'),
      displayInSidebar: true,
      function: menuFunctions.openQueryPromissoryModal
    },
    {
      path: '/query-promissory/:id',
      component: PromissoryDetail,
      isPermitted: permissions.includes('query_promissories')
    },
    {
      name: 'Registro Pagarés',
      path: '/promissory/validate-debtor',
      component: RegisterPromissory,
      parentId: 'promissories',
      isPermitted: permissions.includes('create_promissories'),
      displayInSidebar: true
    },
    {
      name: 'Rango Pagarés Manuales',
      path: '/promissory-ranges',
      parentId: 'promissories',
      component: PromissoryRanges,
      isPermitted: permissions.includes('list_promissory_ranges'),
      displayInSidebar: true
    },
    {
      path: '/portal/contract-information',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/validate-cosigner',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/identification-check',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/scoring',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/validate-invoice',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/supplier-data',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/select-articles',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/promissory-details',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/portal/end-sale',
      component: Portal,
      isPermitted:
        permissions.includes('register_sales') &&
        supplier &&
        pointOfSales.length > 0
    },
    {
      path: '/digital-sale/register-identity',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/contract-information',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/validate-invoice',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/unmaterialized-promissory',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/otp-promissory',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/scoring',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/supplier-data',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/identification-check',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/select-articles',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/capture-voucher/:id(\\d+)',
      component: DigitalSaleVoucher,
      isPermitted:
        (permissions.includes('attach_voucher_to_remote_sale') ||
          permissions.includes('attach_documents_on_traditional_sale')) && supplier
    },
    {
      path: '/digital-sale/otp-sale',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/contact-data',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/digital-sale/end-sale',
      component: DigitalSale,
      isPermitted:
        permissions.includes('register_sales') &&
        permissions.includes('sell_by_unmaterialized_promissory') &&
        supplier
    },
    {
      path: '/remote-sale/otp-enrollment',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/contract-information',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/validate-cosigner',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') &&
        supplier
    },
    {
      path: '/remote-sale/supplier-data',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/identification-check',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/scoring',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/validate-invoice',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/unmaterialized-promissory',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/otp-promissory',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/select-articles',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/contact-data',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/otp-sale',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/remote-sale/end-sale',
      component: RemoteDigitalSale,
      isPermitted:
        permissions.includes('create_remote_digital_sale') && supplier
    },
    {
      path: '/promissory/contract-information',
      component: RegisterPromissory,
      isPermitted: permissions.includes('register_sales')
    },
    {
      path: '/promissory/validate-cosigner',
      component: RegisterPromissory,
      isPermitted: permissions.includes('register_sales')
    },
    {
      path: '/promissory/identification-check',
      component: RegisterPromissory,
      isPermitted: permissions.includes('register_sales')
    },
    {
      path: '/promissory/validate-invoice',
      component: RegisterPromissory,
      isPermitted: permissions.includes('register_sales')
    },
    {
      path: '/promissory/promissory-details',
      component: RegisterPromissory,
      isPermitted: permissions.includes('register_sales')
    },
    {
      name: 'Solicitudes',
      groupId: 'request',
      icon: AssignmentTwoTone,
      isPermitted:
        permissions.includes('list_point_user_association_requests') ||
        permissions.includes('list_my_point_user_association_requests') ||
        permissions.includes('list_sale_return_details') ||
        permissions.includes('list_quota_transfers') ||
        permissions.includes('list_manual_quota') ||
        permissions.includes('list_schedules') ||
        permissions.includes('list_secure_product_movements'),
      displayInSidebar: true
    },
    {
      name: 'Asesores',
      path: '/sellers_request',
      parentId: 'request',
      component: PointUserAssociationRequests,
      isPermitted:
        permissions.includes('list_my_point_user_association_requests') ||
        permissions.includes('list_my_point_user_association_requests')
    },
    {
      name: 'Devolución',
      path: '/sales/revoke',
      parentId: 'request',
      component: SalesReturnList,
      isPermitted: permissions.includes('list_sale_return_details')
    },
    {
      name: 'Siniestros',
      path: '/sinister',
      parentId: 'request',
      component: SinisterList,
      isPermitted: permissions.includes('list_sinisters')
    },
    {
      name: 'Revisión documental',
      path: '/doc-revision',
      parentId: 'request',
      component: DocRevisionList,
      isPermitted: permissions.includes('list_doc_revisions')
    },
    {
      name: 'Movimientos Productos Afianzados',
      path: '/secure-product-movement',
      parentId: 'request',
      component: SecureProductMovements,
      isPermitted: permissions.includes('list_secure_product_movements')
    },
    {
      name: 'Cupo Manual',
      path: '/manual-quota',
      parentId: 'request',
      component: ManualQuotaList,
      isPermitted: permissions.includes('list_manual_quota'),
      displayInSidebar: true
    },
    {
      name: 'Registro de Agendamiento',
      parentId: 'request',
      component: RegisterSchedule,
      isPermitted: permissions.includes('create_schedules'),
      displayInSidebar: true,
      function: menuFunctions.openRegisterScheduleModal
    },
    {
      name: 'Agendamiento',
      path: '/home-visits',
      parentId: 'request',
      component: HomeVisitList,
      isPermitted: permissions.includes('list_schedules'),
      displayInSidebar: true
    },
    {
      name: 'Configuración',
      groupId: 'config',
      icon: SettingsIcon,
      isPermitted:
        permissions.includes('list_global_settings') ||
        permissions.includes('list_users') ||
        permissions.includes('list_articles') ||
        permissions.includes('list_categories') ||
        permissions.includes('list_brands') ||
        permissions.includes('view_commissions') ||
        permissions.includes('list_extra_quota') ||
        permissions.includes('list_financial_plans') ||
        permissions.includes('list_financing_plans_to_categories') ||
        permissions.includes('list_line_executive_profiles') ||
        permissions.includes('list_secure_credit_instalments_percentages') ||
        permissions.includes("list_bulk_processes"),
      displayInSidebar: true
    },
    {
      name: 'Usuarios',
      path: '/users',
      parentId: 'config',
      component: UserList,
      icon: AccountCircleTwoTone,
      isPermitted: permissions.includes('list_users'),
      displayInSidebar: true
    },
    {
      path: '/users/:id',
      component: UserDetail,
      isPermitted: permissions.includes('view_users')
    },
    {
      name: 'Cupos',
      path: '/quotas',
      parentId: 'config',
      component: QuotaConfigurationList,
      isPermitted: permissions.includes('list_assigned_quotas'),
      displayInSidebar: true
    },
    {
      name: 'Sub Segmentos',
      path: '/subsegments',
      parentId: 'config',
      component: SubSegmentList,
      isPermitted: permissions.includes('list_subsegments'),
      displayInSidebar: true
    },
    {
      name: 'Artículos',
      path: '/articles',
      parentId: 'config',
      component: ArticleList,
      isPermitted: permissions.includes('list_articles'),
      displayInSidebar: true
    },
    {
      name: 'Categorías',
      path: '/categories',
      parentId: 'config',
      component: CategoryList,
      isPermitted: permissions.includes('list_categories'),
      displayInSidebar: true
    },
    {
      name: 'Marcas',
      path: '/brands',
      parentId: 'config',
      component: BrandList,
      isPermitted: permissions.includes('list_brands'),
      displayInSidebar: true
    },
    {
      name: 'Comisiones',
      path: '/commissions',
      parentId: 'config',
      component: CommissionList,
      isPermitted: permissions.includes('view_commissions'),
      displayInSidebar: true
    },
    {
      name: 'Extra Cupos',
      path: '/extra-quota',
      parentId: 'config',
      component: ExtraQuotumList,
      isPermitted: permissions.includes('list_extra_quota'),
      displayInSidebar: true
    },
    {
      name: 'Cargues Masivos',
      path: '/bulk-loads',
      parentId: 'config',
      component: BulkLoadList,
      isPermitted: permissions.includes("list_bulk_processes"),
      displayInSidebar: true
    },
    {
      name: 'Exclusiones de Scoring',
      path: '/config-scoring',
      parentId: 'config',
      component: ScoringConfigurationList,
      isPermitted: permissions.includes('list_scoring_exclusions'),
      displayInSidebar: true
    },
    {
      name: 'Excepciones por Localidad',
      path: '/config-location-exception',
      parentId: 'config',
      component: LocationExceptionList,
      isPermitted: permissions.includes('list_location_exceptions'),
      displayInSidebar: true
    },
    {
      name: 'Planes de Financiación',
      path: '/financial-plans',
      parentId: 'config',
      component: FinancialPlanList,
      isPermitted: permissions.includes('list_financial_plans'),
      displayInSidebar: true
    },
    {
      name: 'Planes - Categorías',
      path: '/financial-details',
      parentId: 'config',
      component: FinancialDetailsList,
      isPermitted: permissions.includes('list_financing_plans_to_categories'),
      displayInSidebar: true
    },
    {
      name: 'Agendamientos - Puntos de venta',
      path: '/schedule-point-of-sales-configurations',
      parentId: 'config',
      component: SchedulePointOfSaleConfigurationList,
      isPermitted: permissions.includes('list_financing_plans_to_categories'),
      displayInSidebar: true
    },
    {
      name: 'Parámetros Globales',
      path: '/global-settings',
      parentId: 'config',
      component: GlobalSettingList,
      isPermitted: permissions.includes('list_global_settings'),
      displayInSidebar: true
    },
    {
      name: 'Lista negra de asesores',
      path: '/black-list',
      parentId: 'config',
      component: UserList,
      icon: AccountCircleTwoTone,
      isPermitted: permissions.includes('visualize_blacklist')
    },
    {
      name: 'Perfiles de ejecutivos',
      path: '/line-executive-profiles',
      parentId: 'config',
      component: LineExecutiveProfileList,
      isPermitted: permissions.includes('list_line_executive_profiles'),
      displayInSidebar: true
    },
    {
      name: 'Campañas',
      path: '/campaigns',
      parentId: 'config',
      component: CampaignList,
      isPermitted: permissions.includes('list_campaign'),
      displayInSidebar: true
    },
    {
      name: 'Porcentaje de fianza',
      path: '/secure-credit-instalments-percentages',
      parentId: 'config',
      component: SecureCreditInstalmentsPercentageList,
      isPermitted: permissions.includes('list_secure_credit_instalments_percentages'),
      displayInSidebar: true
    },
  ];
};

export default routes;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _find from 'lodash/find';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ProfileStyles from './__styles__/Profile.styles';

export const Profile = ({ classes, client, isLoading }) => {
  const contracts = client.contracts;
  return (
    <FormItemContainer
      title={'Información del Cliente'}
      classes={{container: classes.container}}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.root}>
          <div className={classes.details}>
            <Grid container spacing={16} className={classes.contactData}>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Nombre:'}</p>
                <p className={classes.body2}>{client.name || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Apellido:'}</p>
                <p className={classes.body2}>{client.lastName || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{`${'Tipo de Documento'}:`}</p>
                <p className={classes.body2}>{client.typeOfIdentification || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{`${'No. Documento'}:`}</p>
                <p className={classes.body2}>{client.identification || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Fecha de nacimiento:'}</p>
                <p className={classes.body2}>
                  {client.dateOfBirth
                    ? moment(client.dateOfBirth, 'YYYY-MM-DD').format('YYYY/MM/DD')
                    : '--'}
                </p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Género:'}</p>
                <p className={classes.body2}>{client.gender || '--'}</p>
              </Grid>
              {contracts && contracts.length > 0 && (
                <Grid item lg={12}>
                  <p className={classes.subtitle3}>{
                    contracts.length > 1
                      ? 'Contratos'
                      : 'Contrato'
                  }</p>
                  {contracts.map(contract => (
                    <div key={contract.externalCode} style={{ padding: '5px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p className={classes.body2}>{contract.externalCode}</p>
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      )}
    </FormItemContainer>
  );
};

export default withStyles(
  combineStyles(ProfileStyles, ButtonStyles, TypographyStyles)
)(Profile);

import {
  SET_SCORING_CONFIGURATION_LIST_DATA,
  OPEN_SCORING_CONFIGURATION_FORM_MODAL,
  CLOSE_SCORING_CONFIGURATION_FORM_MODAL,
  CREATE_SCORING_CONFIGURATION,
  UPDATE_SCORING_CONFIGURATION,
  DELETE_SCORING_CONFIGURATION
} from '../constants/scoring_configurations_constants';
import { sendRequest, completeRequest } from './request_state';
import { newAlert } from './alert_actions';
import {
  listSanitizer,
  createSanitizer,
  updateSanitizer
} from '../helpers/sanitizers/scoringConfigurationSanitizer';
import RequestService from '../services/request_service';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { history } from '../helpers/history';

const requestService = new RequestService('node');

const _openScoringConfigurationFormModal = () => {
  return {
    type: OPEN_SCORING_CONFIGURATION_FORM_MODAL
  };
};

const _closeScoringConfigurationFormModal = () => {
  return {
    type: CLOSE_SCORING_CONFIGURATION_FORM_MODAL
  };
};

const setScoringConfigurationListData = payload => {
  const { data, meta } = payload;
  return {
    type: SET_SCORING_CONFIGURATION_LIST_DATA,
    data,
    meta
  };
};

const createScoringConfigurationAction = payload => {
  return {
    type: CREATE_SCORING_CONFIGURATION,
    data: payload
  };
};

const updateScoringConfigurationAction = payload => {
  return {
    type: UPDATE_SCORING_CONFIGURATION,
    data: payload
  };
};

const deleteScoringConfigurationAction = payload => {
  return {
    type: DELETE_SCORING_CONFIGURATION,
    data: payload
  };
};

export const openScoringConfigurationFormModal = () => dispatch => {
  dispatch(_openScoringConfigurationFormModal());
};

export const closeScoringConfigurationFormModal = () => dispatch => {
  dispatch(_closeScoringConfigurationFormModal());
};

export const fetchScoringConfigurationList = params => async dispatch => {
  dispatch(sendRequest('ScoringConfigurationList'));
  history.replace(
    `/config-scoring?${stringifyQuery(listSanitizer(params), false)}`
  );
  try {
    const payload = await requestService.get(
      'scoring-exclusions',
      listSanitizer(params)
    );
    dispatch(setScoringConfigurationListData(payload));
  } catch (errors) {
    const err = await errors.json();
    if (err.errors) {
      dispatch(newAlert('error', 'ERROR:', err.errors.base));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuníquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('ScoringConfigurationList'));
  }
};

export const createScoringConfiguration = params => async dispatch => {
  dispatch(sendRequest('ScoringConfigurationCreate'));
  try {
    const payload = await requestService.post(
      'scoring-exclusions',
      createSanitizer(params)
    );
    dispatch(_closeScoringConfigurationFormModal());
    dispatch(createScoringConfigurationAction(payload.data));
    dispatch(
      newAlert('success', 's', 'Exclusión de scoring creada exitosamente.')
    );
  } catch (errors) {
    const err = await errors.json();
    if (err.errors) {
      dispatch(newAlert('error', 'ERROR:', err.errors.base));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuníquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('ScoringConfigurationCreate'));
  }
};

export const updateScoringConfiguration = params => async dispatch => {
  dispatch(sendRequest('ScoringConfigurationUpdate'));
  try {
    const payload = await requestService.put(
      `scoring-exclusions/${params.id}`,
      updateSanitizer(params)
    );
    dispatch(_closeScoringConfigurationFormModal());
    dispatch(updateScoringConfigurationAction(payload.data));
    dispatch(
      newAlert('success', 's', 'Exclusión de scoring actualizada exitosamente.')
    );
  } catch (errors) {
    const err = await errors.json();
    if (err.errors) {
      dispatch(newAlert('error', 'ERROR:', err.errors.base));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuníquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('ScoringConfigurationUpdate'));
  }
};

export const deleteScoringConfiguration = id => async dispatch => {
  dispatch(sendRequest('ScoringConfigurationDelete'));
  try {
    const payload = await requestService.delete(`scoring-exclusions/${id}`);
    dispatch(_closeScoringConfigurationFormModal());
    dispatch(deleteScoringConfigurationAction(payload.data));
    dispatch(
      newAlert('success', 's', 'Exclusión de scoring eliminada exitosamente.')
    );
  } catch (errors) {
    const err = await errors.json();
    if (err.errors) {
      dispatch(newAlert('error', 'ERROR:', err.errors.base));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuníquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('ScoringConfigurationDelete'));
  }
};

export const columns = [
  {
    title: '# Artículo',
    name: 'id'
  },
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Categoria',
    name: 'categoryName'
  },
  {
    title: 'Marca',
    name: 'brandName'
  },
  {
    title: 'I.V.A.',
    name: 'tax'
  },
  {
    title: 'Periodo de Gracia',
    name: 'gracePeriodText'
  },
  {
    title: 'Estado',
    name: 'status'
  }
];

export const mapData = articles => {
  return articles.map(article => ({
    id: article.id,
    name: article.name,
    description: article.description,
    categoryId: article.category.id,
    categoryName: article.category.name,
    conceptId: article.concept.id,
    conceptName: article.concept.description,
    brandId: article.brand.id,
    brandName: article.brand.name,
    tax: article.iva ? `${article.iva}%` : null,
    iva: article.iva,
    maxItems: article.maxItems,
    gracePeriodText: article.gracePeriod ? 'Si' : 'No',
    gracePeriod: article.gracePeriod,
    status: article.active ? 'Activo' : 'Inactivo',
    suppliers: article.suppliers,
    articleTypes: article.articleTypes,
    active: article.active
  }));
};

export const columns = [
  {
    title: 'Nombre',
    name: 'name'
  },
  {
    title: 'Descripción',
    name: 'description'
  },
  {
    title: 'Valor',
    name: 'value'
  },
  {
    title: 'Etiquetas',
    name: 'tags'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      id: datum.id,
      name: datum.name || '--',
      description: datum.description || '--',
      value: datum.value,
      tags: datum.tags,
      code: datum.code,
      settingType: datum.settingType
    };
  });

export const promissoryTypes = [
  { label: 'Digital y manual', key: 0, value: 0 },
  { label: 'Manual', key: 1, value: 1 },
  { label: 'Digital', key: 2, value: 2 }
];

export const processesOrder = [
  { label: 'Cualquier orden', key: 0, value: 0 },
  {
    label:
      'Revisión de documentos antes de entrega de producto',
    key: 1,
    value: 1
  },
  {
    label:
      'Entrega de producto antes de revisión de documentos',
    key: 2,
    value: 2
  }
];
import React, { PureComponent, Fragment } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import parseQuerystring from '../../../helpers/parseQuerystring';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import TextFieldInput from '../../shared/Inputs/TextField';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import combineStyles from '../../../helpers/combineStyles';
import SpacingStyles from '../../../styles/helpers/spacing';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants';
import { statusOptions, commissionTypes } from './dropDownOptions';
import _get from 'lodash/get';
import CollapseWrapper from '../../shared/CollapseWrapper.js';

class CommissionFilters extends PureComponent {
  state = {
    categoryId: null,
    cacheCategoryName: '',
    articleName: '',
    supplierId: null,
    cacheSupplierId: '',
    saleChannelId: '',
    cacheSaleChannelId: '',
    active: 'all',
    type: 'all'
  };

  componentDidMount() {
    this._handleChangeFilter();
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleChangeFilter = () => {
    const filterParams = this._getFilterParams();
    this.props.onChangeFilter(filterParams);
  };

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter !== 'all'),
      'supplierId',
      'categoryId',
      'articleName',
      'supplier_name',
      'category_name',
      'sale_channel_name',
      'saleChannelId',
      'active',
      'type'
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  render() {
    const { classes } = this.props;
    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="category"
                  name="categoryId"
                  label="Categoría"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cacheCategoryName || ''}
                  onChange={change => {
                    if (this.state.categoryId) {
                      this.setState(
                        { category_name: '', categoryId: null },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cacheCategoryName: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('categories', value)
                  }
                  onSuggestionSelected={category => {
                    this.setState(
                      { category_name: category.label, categoryId: category.value },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <AutocompleteInput
                  id="supplier"
                  name="supplierId"
                  label="Proveedor"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cacheSupplierId || ''}
                  onChange={change => {
                    if (this.state.supplierId) {
                      this.setState(
                        { supplier_name: '', supplierId: null },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cacheSupplierId: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('suppliers', value)
                  }
                  onSuggestionSelected={supplier => {
                    this.setState(
                      { supplier_name: supplier.label, supplierId: supplier.value },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextFieldInput
                  id="article"
                  name="articleName"
                  value={this.state.articleName || ''}
                  onChange={this._handleChange}
                  label="Artículo"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <AutocompleteInput
                  id="sale_channel"
                  name="saleChannelId"
                  label="Medio de recepción"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.cacheSaleChannelId || ''}
                  onChange={change => {
                    if (this.state.saleChannelId) {
                      this.setState(
                        { saleChannelId: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ cacheSaleChannelId: change });
                  }}
                  getSuggestions={value =>
                    this._getSuggestions('saleChannels', value)
                  }
                  onSuggestionSelected={saleChannel => {
                    this.setState(
                      { saleChannelId: saleChannel.value },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Tipo'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'type'}
                  options={commissionTypes}
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value }, this._handleChangeFilter);
                  }}
                  inputLabel={'Type'}
                  value={this.state.type}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'active'}
                  options={statusOptions}
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value }, this._handleChangeFilter);
                  }}
                  inputLabel={'Active'}
                  value={this.state.active}
                  withChip={false}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({});

CommissionFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(CommissionFilters);

import {
  CLIENTS_SET,
  CLIENT_INFO_SET,
  CLEAR_CLIENT,
  CLIENTS_CLEAR
} from '../constants/action_constants';

const initialState = {
  clients: [],
  meta: {},
  clientInfo: null
};

export function client(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_SET:
      return {
        ...state,
        clients: action.data,
        meta: action.meta
      };
    case CLIENTS_CLEAR:
      return {
        ...state,
        clients: []
      };
    case CLIENT_INFO_SET:
      return {
        ...state,
        clientInfo: action.data
      };
    case CLEAR_CLIENT:
      return {
        ...state,
        clientInfo: null
      };
    default:
      return state;
  }
}

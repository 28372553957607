import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { Formik, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import GridItem from '../../../shared/GridItem';
import ToggleButton from '../../../shared/Buttons/Toggle';

import combineStyles from '../../../../helpers/combineStyles';
import LayoutStyles from '../../../../styles/layout';
import ButtonStyles from '../../../../styles/button';
import TypographyStyles from '../../../../styles/typography';
import SpacingStyles from '../../../../styles/helpers/spacing';

import TextFieldInput from '../../../shared/Inputs/TextField';
import CheckboxInput from '../../../shared/Inputs/Checkbox';
import AutocompleteInput from '../../../shared/Inputs/Autocomplete';
import ChippedAutocompleteInput from '../../../shared/Inputs/ChippedAutocomplete';
import DialogForm from '../../../shared/Modals/DialogForm';
import ProgressButton from '../../../shared/Buttons/ProgressButton';

import _forEach from 'lodash/forEach';

import { filterListFuzzyly } from '../../../../helpers/utilityFunctions';
import _get from 'lodash/get';

import FormSchemaValidation from './Form.validation';
import { TextField } from '@material-ui/core';

const styles = theme => ({
  title: {
    fontWeight: 'bold !important'
  },
  fieldsMarginBottom: {
    marginBottom: `${theme.spacing.unit * 2.5}px !important`
  },
  toggleSwitchLeftLabel: {
    marginRight: `${theme.spacing.unit * 1.5}px !important`
  },
  toggleSwitchLabel: {
    color: `${theme.typography.color.second} !important`
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  }
});

export class Form extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  state = { categoryMaxItems: null, maxItems: null };

  componentDidMount() {
    if (this.props.item) {
      this.setState({
        categoryMaxItems: this.props.item.categoryMaxItems,
        maxItems: this.props.item.maxItems
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.formik.setErrors({});

    if (this.state.maxItems && this.state.categoryMaxItems) {
      if (parseInt(this.state.maxItems) > this.state.categoryMaxItems) {
        this.formik.setErrors({
          maxItems: 'Excede la cantidad máxima permitida'
        });
      }
    }

    if (this.props.errors) {
      const formik = this.formik;
      formik.setErrors(this._handleErrors(this.props.errors));
    }
  }

  _handleOnClose = () => {
    this.props.onClose();
  };

  _handleOnSave = async values => {
    const { onSave } = this.props;
    try {
      await onSave(values);
      return true;
    } catch {
      return false;
    }
  };

  _handleOnSave = values => {
    const { onSave } = this.props;
    onSave(values);
  };

  _getSuggestions = async (type, value) => {
    let items = this.props[type].map(item => {
      return {
        label: item.name || item.description,
        value: item.id,
        maxItems: item.maxItems
      };
    });

    if (type == 'suppliers') {
      const currentItemsSelected = this.formik.state.values[type].map(
        r => r.value
      );
      items = items.filter(item => !currentItemsSelected.includes(item.value));
    }

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  _handleErrors = apiErrors => {
    const errors = {};
    _forEach(apiErrors, (v, k) => (errors[k] = v));
    return errors;
  };

  render() {
    const {
      classes,
      isSubmitting,
      item = {},
      canSetMaxItemsPerArticle
    } = this.props;
    const initialValues = {
      ...item,
      suppliers: item.suppliers.map(supplier => ({
        label: supplier.name,
        value: supplier.id
      })),
      articleTypes: item.isNew
        ? []
        : item.articleTypes.map(value => ({
            label: value.name,
            value: value.id
          })),
      categoryId: item.categoryId,
      brandId: item.brandId,
      conceptId: item.conceptId
    };

    return (
      <Formik
        ref={ref => (this.formik = ref)}
        validationSchema={FormSchemaValidation}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={values => {
          this._handleOnSave(values);
        }}
        onReset={(values, actions) => {
          actions.resetForm();
          this._handleOnClose();
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          dirty
        }) => (
          <DialogForm
            open={true}
            onClose={handleReset}
            title={
              <div className={classes.titleContainer}>
                <p className={classnames(classes.headline6, classes.title)}>
                  {`${values.isNew ? 'Crear Articulo' : 'Editar Articulo'}`}
                </p>
                <ToggleButton
                  value={values.active}
                  onChange={() => {
                    setFieldValue('active', !values.active);
                  }}
                />
              </div>
            }
            footer={
              <Fragment>
                <Button
                  className={classes.button}
                  onClick={handleReset}
                  color={'secondary'}
                  classes={{ label: classes.buttonLabel }}
                >
                  {'Cancelar'}
                </Button>
                <ProgressButton
                  onClick={handleSubmit}
                  className={classes.button}
                  classes={{
                    disabled: classes.buttonDisabled,
                    label: classes.buttonLabel
                  }}
                  color={'secondary'}
                  disabled={
                    !dirty || isSubmitting || Object.keys(errors).length > 0
                  }
                  isSubmitting={isSubmitting}
                >
                  Guardar
                </ProgressButton>
              </Fragment>
            }
          >
            <Grid container spacing={16}>
              <GridItem xs={12} sm={12}>
                <TextField
                  label="Nombre"
                  required
                  name="name"
                  variant="outlined"
                  onChange={e => {
                    this.props.errorOnRequest('SaveArticle', {});
                    handleChange(e);
                  }}
                  value={values.name}
                  fullWidth
                  multiline
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Field
                  name="description"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <TextFieldInput
                        value={values.description}
                        label={'Descripción'}
                        fullWidth
                        field={field}
                        form={form}
                        classes={{
                          root: classes.noMarginBottom
                        }}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="brandId"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <AutocompleteInput
                        id="brandId"
                        name="brandId"
                        label="Marca"
                        margin="normal"
                        variant="outlined"
                        error={''}
                        helperText={''}
                        value={this.state.brand || values.brandName || ''}
                        onChange={change => {
                          setFieldValue('brandId', '');
                          setFieldValue('brandName', '');
                          this.setState({ brand: change });
                        }}
                        suggestions={this.props.brands}
                        getSuggestions={value =>
                          this._getSuggestions('brands', value)
                        }
                        onSuggestionSelected={item => {
                          setFieldValue('brandName', item.label);
                          setFieldValue('brandId', item.value);
                        }}
                        field={field}
                        form={form}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="categoryId"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <AutocompleteInput
                        id="categoryId"
                        name="categoryId"
                        label="Categoria"
                        margin="normal"
                        variant="outlined"
                        error={''}
                        helperText={''}
                        value={this.state.category || values.categoryName || ''}
                        onChange={change => {
                          setFieldValue('categoryId', '');
                          setFieldValue('categoryName', '');
                          this.setState({ category: change });
                        }}
                        suggestions={this.props.categories}
                        getSuggestions={value =>
                          this._getSuggestions('categories', value)
                        }
                        onSuggestionSelected={item => {
                          setFieldValue('categoryName', item.label);
                          setFieldValue('categoryId', item.value);
                          this.setState({
                            categoryMaxItems: item.maxItems
                          });
                        }}
                        field={field}
                        form={form}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="conceptId"
                  onChange={handleChange}
                  render={({ field, form }) => {
                    return (
                      <AutocompleteInput
                        id="conceptId"
                        name="conceptId"
                        label="Concepto"
                        margin="normal"
                        variant="outlined"
                        error={''}
                        helperText={''}
                        value={this.state.concept || values.conceptName || ''}
                        onChange={change => {
                          setFieldValue('conceptId', '');
                          setFieldValue('conceptName', '');
                          this.setState({ concept: change });
                        }}
                        suggestions={this.props.concepts}
                        getSuggestions={value =>
                          this._getSuggestions('concepts', value)
                        }
                        onSuggestionSelected={item => {
                          setFieldValue('conceptName', item.label);
                          setFieldValue('conceptId', item.value);
                        }}
                        field={field}
                        form={form}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="iva"
                  render={({ field, form }) => (
                    <TextFieldInput
                      value={values.iva}
                      label={'I.V.A.'}
                      fullWidth
                      field={field}
                      form={form}
                      type="number"
                      onChange={e => {
                        handleChange(e);
                        this.setState({
                          iva: parseInt(e.target.value) || 0
                        });
                      }}
                    />
                  )}
                />
              </GridItem>
              {canSetMaxItemsPerArticle && (
                <GridItem xs={6}>
                  <Field
                    name={'maxItems'}
                    render={({ field, form }) => (
                      <TextFieldInput
                        value={values.maxItems}
                        label={'Máximo No. de unidades por cliente'}
                        fullWidth
                        field={field}
                        form={form}
                        type="number"
                        onChange={e => {
                          handleChange(e);
                          this.setState({
                            maxItems: parseInt(e.target.value) || 0
                          });
                        }}
                      />
                    )}
                  />
                </GridItem>
              )}
              <GridItem
                xs={6}
                className={classnames({
                  [classes.hasMatCheckboxContainer]: values.isHazMat
                })}
              >
                <Field
                  name={'gracePeriod'}
                  render={({ field, form }) => (
                    <CheckboxInput
                      label={'Periodo de Gracia'}
                      field={field}
                      form={form}
                      value={values.gracePeriod}
                      onChange={e => {
                        handleChange(e);
                      }}
                      classes={{
                        root: classnames(
                          classes.noMarginTop,
                          classes.checkboxContainer
                        )
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Field
                  name="suppliers"
                  render={({ field, form }) => (
                    <ChippedAutocompleteInput
                      getSuggestions={value =>
                        this._getSuggestions('suppliers', value)
                      }
                      value={this.state.supplier}
                      values={values.suppliers}
                      onChange={evt => {
                        const {
                          target: { value }
                        } = evt;
                        this.setState({ supplier: value });
                        handleChange(evt);
                      }}
                      field={field}
                      form={form}
                      label={'Proveedores'}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <Field
                  name="articleTypes"
                  render={({ field, form }) => (
                    <ChippedAutocompleteInput
                      getSuggestions={value =>
                        this._getSuggestions('articleTypes', value)
                      }
                      value={this.state.articleType}
                      values={values.articleTypes}
                      onChange={evt => {
                        const {
                          target: { value }
                        } = evt;
                        this.setState({ articleType: value });
                        handleChange(evt);
                      }}
                      field={field}
                      form={form}
                      label={'Tipos de Artículo'}
                    />
                  )}
                />
              </GridItem>
            </Grid>
          </DialogForm>
        )}
      />
    );
  }
}
export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    TypographyStyles,
    ButtonStyles,
    SpacingStyles
  )
)(Form);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'; //Registrado
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart'; //Cancelada
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'; //Entregada
import AssignmentTurnedIncon from '@material-ui/icons/AssignmentTurnedIn'; //Aprobada
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'; //Anulada parcialmente
import SpacingStyles from '../../../../styles/helpers/spacing';
import TypographyStyles from '../../../../styles/typography';
import combineStyles from '../../../../helpers/combineStyles';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { STATUSES as SALE_STATUSES } from '../Status/constanst';

import _get from 'lodash/get';
import { DOC_REVISION_VERBOSE_STATUS } from '../../../../constants/enums';

const styles = theme => ({
  iconContainer: {
    position: 'absolute',
    left: 0,
    top: -8,
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(242,161,56,0.9)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconRoot: {
    color: ['#FFF', '!important'],
    fontSize: '18px'
  },
  activityDetail: {
    position: 'relative',
    display: 'inline-block',
    alignSelf: 'stretch'
  },
  activityName: {
    marginTop: [theme.spacing.unit * 1.5, '!important'],
    marginBottom: [theme.spacing.unit * 0.625, '!important']
  },

  itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 6,
    position: 'relative'
  },
  verticalLine: {
    width: 1,
    height: '100%',
    borderRight: '1px dashed rgba(12,31,44,0.12);',
    position: 'absolute',
    left: 16
  }
});
export class Item extends PureComponent {
  static propTypes = {
    activity: PropTypes.object.isRequired
  };

  render() {
    const { classes, activity } = this.props;

    const ICONS = {
      [SALE_STATUSES.REGISTERED]: AddShoppingCartIcon,
      [SALE_STATUSES.APPROVED]: AssignmentTurnedIncon,
      [SALE_STATUSES.DELIVERED]: ShoppingCartIcon,
      [SALE_STATUSES.REVOKED]: RemoveShoppingCartIcon,
      [SALE_STATUSES.PARTIALLY_REVOKED]: RemoveCircleOutline,
      [SALE_STATUSES.PARTIALLY_RETURNED]: RemoveCircleOutline,
      [SALE_STATUSES.RETURNED]: RemoveShoppingCartIcon,
      [SALE_STATUSES.PARTIALLY_RETURNED_MANUAL]: RemoveCircleOutline,
      [SALE_STATUSES.RETURNED_MANUAL]: RemoveShoppingCartIcon,
      [DOC_REVISION_VERBOSE_STATUS.registered]: AddShoppingCartIcon,
      [DOC_REVISION_VERBOSE_STATUS.revoked]: RemoveShoppingCartIcon,
      [DOC_REVISION_VERBOSE_STATUS.approved]: AssignmentTurnedIncon,
    };

    const Icon = ICONS[activity.title];

    return (
      <div className={classes.itemContainer}>
        <div className={classes.verticalLine} />
        <div className={classes.iconContainer}>
          <Icon color="primary" classes={{ root: classes.iconRoot }} />
        </div>
        <div className={classes.activityDetail}>
          <h4
            className={classnames(
              classes.noMargin,
              classes.caption,
              classes.boldWeight
            )}
          >
            {activity.title}
          </h4>

          <h5 className={classnames(classes.subtitle3, classes.activityName)}>
            {moment(activity.date)
              .locale('es')
              .format('DD MMM YYYY [a las] h:mm A')}
          </h5>
          {activity.user && (
            <p className={classes.body2}>
              {`${_get(activity, 'user.firstName', '') || ''} ${_get(
                activity,
                'user.lastName',
                ''
              ) || ''}`}
            </p>
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(
  combineStyles(styles, TypographyStyles, SpacingStyles)
)(Item);

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import DigitalSaleVoucher from './DigitalSaleVoucher';
import { newAlert } from '../../../actions/alert_actions';
import { setCurrentStep } from '../../../actions/stepper_actions';
import {
  setNextStep,
  digitalSaleVoucherStep
} from '../../../actions/portal_financing_actions';
import { salesDetail } from '../../../actions/sales_actions';
import { fetchSessionData } from '../../../actions/session_actions';
import { cancelSale } from '../../../actions/sales_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const { salesDetail } = state.sales;
  return {
    salesDetail,
    routes: state.stepper.routes,
    promissory: pf.promissory,
    canAttachDocumentsOnTraditionalSale: state.authentication.currentUser.canAttachDocumentsOnTraditionalSale,
    canReattachVoucherOnRemoteSale: state.authentication.currentUser.canReattachVoucherOnRemoteSale,
    canAttachDocumentsForDocRevisions: state.authentication.currentUser.canAttachDocumentsForDocRevisions,
    stepIsLoading: state.portalFinancing.stepIsLoading,
    requestSend: state.requestState.requestSend,
    causals: state.globalSetting.orgData.sale_revoke_causes,
    companyCode: state.sessionData.companyName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    details: bindActionCreators(salesDetail, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    setNextStep: bindActionCreators(setNextStep, dispatch),
    digitalSaleVoucherStep: bindActionCreators(
      digitalSaleVoucherStep,
      dispatch
    ),
    fetchSessionData: bindActionCreators(fetchSessionData, dispatch),
    cancelSale: bindActionCreators(cancelSale, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DigitalSaleVoucher);

import _pickBy from 'lodash/pickBy';

const dataToCreateCommissionsFilters = filters => {
  const { supplierId, categoryId } = filters;

  const sanitizedFilters = {
    categoryId: Number(categoryId),
    supplierId: Number(supplierId),
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default dataToCreateCommissionsFilters;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import classnames from 'classnames';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import Button from '@material-ui/core/Button';
import FormModal from './modals/FormModal';
import parseQuerystring from '../../../helpers/parseQuerystring';

const _getNewConfig = () => {
  return {
    categoryName: '',
    geographicLocationName: '',
    supplierName: '',
    categoryId: undefined,
    geographicLocationId: undefined,
    supplierId: undefined
  };
};

class ScoringConfigurationList extends React.PureComponent {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    currentConfig: _getNewConfig()
  };

  componentDidMount() {
    this._fetchData();
  }

  _fetchData(params = {}) {
    this._fetchConfigs(params);
  }

  _fetchConfigs = (params = {}) => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchScoringConfigurationList({
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchConfigs({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchConfigs);
  };

  _formButtonIsVisible = () => {
    return this.props.canCreateScoringExclusions;
  };

  _getTableName = () => {
    const { configs = [] } = this.props;
    return configs.length != 1 ? 'Exclusiones' : 'Exclusión';
  };

  _openModal = (params = {}) => {
    this.props.openFormModal();
    this.setState({
      currentConfig: this._getCurrentConfig(params.config)
    });
  };

  _closeModal = () => {
    this.props.closeFormModal();
  };

  _isLoadingData = () => {
    const { loading = false } = this.props;
    return loading;
  };

  _getNewConfig = () => {
    return {
      categoryName: '',
      geographicLocationName: '',
      supplierName: '',
      categoryId: undefined,
      geographicLocationId: undefined,
      supplierId: undefined
    };
  };

  _getCurrentConfig = config => {
    return config || _getNewConfig();
  };

  _handleRowClick = config => {
    if (this.props.canUpdateScoringExclusions) {
      this._openModal({ config });
    }
  };

  render() {
    const {
      configs = [],
      meta = {},
      classes,
      categories,
      geographicLocations,
      suppliers,
      modalSubmitLoading = false,
      modalDestroyLoading = false,
      createScoringConfiguration,
      updateScoringConfiguration,
      formModalIsOpen,
      deleteScoringConfiguration,
      canDestroyScoringExclusions
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};
    const { rowsPerPage } = this.state;

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Exclusiones de Scoring'}
          </h1>
          {this._formButtonIsVisible() && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => this._openModal()}
            >
              {'Crear Exclusión'}
            </Button>
          )}
        </div>
        <div>
          <Table
            name={this._getTableName()}
            columns={columns}
            data={mapData(configs)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={configs.length}
            loading={this._isLoadingData()}
            onClickOnRow={this._handleRowClick}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
        <div>
          {formModalIsOpen && (
            <FormModal
              onClose={this._closeModal}
              onDelete={({ id }) => {
                deleteScoringConfiguration(id);
              }}
              config={this.state.currentConfig}
              categories={categories.filter(category => category.active)}
              suppliers={suppliers.filter(supplier => supplier.active)}
              geographicLocations={geographicLocations}
              createConfig={createScoringConfiguration}
              updateConfig={updateScoringConfiguration}
              submitLoading={modalSubmitLoading}
              modalDestroyLoading={modalDestroyLoading}
              rowsPerPage={rowsPerPage}
              currentPage={page}
              canDestroyScoringExclusions={canDestroyScoringExclusions}
            />
          )}
        </div>
      </div>
    );
  }
}

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ScoringConfigurationList);

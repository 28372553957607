import {
  LIST_POINTS_OF_SALE,
  LIST_POINTS_OF_SALE_NAMES,
  FETCH_POINT_OF_SALE,
  LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS,
  SELECTED_SELLERS_SET,
  LIST_POINTS_OF_SALE_HOME_VISITS,
  SET_POINT_OF_SALE_SALE_CHANNELS
} from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import RequestService from '../services/request_service';
import AxiosRequest from '../services/axios_request';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import {
  pointOfSaleFilterSanitizer,
  posDashboardFilterSanitizer,
  schedulePointOfSaleFilterSanitizer,
  setSellersSanitizer
} from '../helpers/sanitizers/pointOfSaleSanitizer';
import { fetchUserNames } from './user_actions';
import _get from 'lodash/get';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import snakecaseKeys from 'snakecase-keys';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();
import { objectToString } from '../helpers/mappings/map';
import { SET_CATEGORIES_IN_SCHEDULE_POINT_OF_SALE_CONFIGURATION_FORM_MODAL } from '../constants/schedule_point_of_sale_configuration_constants';

//* PURE ACTION FUNCTIONS
function setPointsOfSale(items, pagi) {
  return {
    type: LIST_POINTS_OF_SALE,
    data: items,
    meta: pagi
  };
}

function setPointsOfSaleNames(items) {
  return {
    type: LIST_POINTS_OF_SALE_NAMES,
    data: items
  };
}

function setPointsOfSaleHomeVisits(items) {
  return {
    type: LIST_POINTS_OF_SALE_HOME_VISITS,
    data: items
  };
}

function setPointOfSaleSaleChannels(items){
  return {
    type: SET_POINT_OF_SALE_SALE_CHANNELS,
    data: items
  };
}

function setPointsOfSaleNamesAssociation(items) {
  return {
    type: LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS,
    data: items
  };
}

function setPointOfSaleInfo(info) {
  return {
    type: FETCH_POINT_OF_SALE,
    data: info
  };
}

function setSelectedSellers(items) {
  return {
    type: SELECTED_SELLERS_SET,
    data: items
  };
}

export function clearUpdatePOSErrors() {
  return dispatch => {
    dispatch(errorOnRequest('UpdatePointOfSale', {}));
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchPointsOfSale = (params = {}) => async dispatch => {
  dispatch(sendRequest('PointsOfSale'));
  history.replace(`/points-of-sale?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'point-of-sale',
      pointOfSaleFilterSanitizer(params)
    );
    dispatch(setPointsOfSale(payload.data, payload.meta));
  } catch (errors) {
    errors.json().then(e => {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    });
  } finally {
    dispatch(completeRequest('PointsOfSale'));
  }
};

export const fetchPosDashboard = (params = {}) => async dispatch => {
  dispatch(sendRequest('PointsOfSale'));
  try {
    const payload = await requestServiceNode.get(
      'point-of-sale/dashboard',
      posDashboardFilterSanitizer(params)
    );
    dispatch(setPointsOfSale(payload.data, payload.meta));
  } catch (errors) {
    errors.json().then(e => {
      dispatch(newAlert('error', 'ERROR:', e.message));
    });
  } finally {
    dispatch(completeRequest('PointsOfSale'));
  }
};

export const fetchSchedulePointsOfSale = params => async dispatch => {
  dispatch(sendRequest('SchedulePointsOfSale'));
  try {
    const payload = await requestServiceNode.get(
      'schedules/posSchedules', params
    );
    return payload.data;
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', 'Error cargando los puntos de venta'));
  } finally {
    dispatch(completeRequest('SchedulePointsOfSale'));
  }
};

export const fetchSchedulePointOfSale = params => async dispatch => {
  dispatch(sendRequest('SchedulePointsOfSale'));
  try {
    const payload = await requestServiceNode.get(
      'schedules/automaticPosSchedules', params
    );
    return payload.data;
  } catch (error) {
    const errorMessage = _get(error, 'errors.base[0]', '');
    dispatch(newAlert('error', 'ERROR:', `Error cargando los puntos de venta: ${errorMessage}`));
  } finally {
    dispatch(completeRequest('SchedulePointsOfSale'));
  }
};

export function fetchPointOfSaleSaleChannels(params) {
  return dispatch => {
    return requestServiceNode
      .get('point-of-sale/sale-channels', params)
      .then(payload => {
        dispatch(setPointOfSaleSaleChannels(payload.data));
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(toggleBaseErrorModal(true, error.errors.base[0]));
      });
  };
}

export function fetchPointsOfSaleNames(params = {}) {
  return dispatch => {
    dispatch(sendRequest('PointOfSales'));
    return requestServiceNode.post(`point-of-sale/preview`).then(
      payload => {
        dispatch(setPointsOfSaleNames(payload.data));
        dispatch(completeRequest('PointOfSales'));
      },
      error => {
        error.json().then(e => {
          e.data &&
            e.data.message &&
            dispatch(newAlert('error', 'ERROR:', e.data.message));
          e.error &&
            e.error.message &&
            dispatch(newAlert('error', 'ERROR:', e.error.message));
          e.errors &&
            e.errors.title &&
            dispatch(newAlert('error', 'ERROR:', e.errors.title));
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
        });
        dispatch(completeRequest('PointOfSales'));
      }
    );
  };
}

export function fetchPointsOfSaleHomeVisits() {
  return dispatch => {
    dispatch(sendRequest('PointOfSales'));
    return requestServiceNode.get(
      'schedules/pointOfSaleNames'
    ).then(
      payload => {
        dispatch(setPointsOfSaleHomeVisits(payload.data));
        dispatch(completeRequest('PointOfSales'));
      },
      error => {
        error.json().then(e => {
          e.data &&
            e.data.message &&
            dispatch(newAlert('error', 'ERROR:', e.data.message));
          e.error &&
            e.error.message &&
            dispatch(newAlert('error', 'ERROR:', e.error.message));
          e.errors &&
            e.errors.title &&
            dispatch(newAlert('error', 'ERROR:', e.errors.title));
          e.message && dispatch(newAlert('error', 'ERROR:', e.message));
        });
        dispatch(completeRequest('PointOfSales'));
      }
    );
  };
}

export function fetchPointsOfSaleNamesAssociation(params = {}) {
  return dispatch => {
    dispatch(sendRequest('PointOfSales'));
    return requestServiceNode.post(`point-of-sale/pointOfSaleAssociationList`,
      {userId: Number(params.id)}
    ).then(
      payload => {
        dispatch(setPointsOfSaleNamesAssociation(payload.data));
        dispatch(completeRequest('PointOfSales'));
      },
      error => {
        error.json().then(e => {
          dispatch(newAlert('error', 'ERROR:', e.errors.base));
        });
        dispatch(completeRequest('PointOfSales'));
      }
    );
  };
}

export const fetchPointOfSaleInfo = id => async dispatch => {
  dispatch(sendRequest('PointOfSaleInfo'));
  try {
    const payload = await requestServiceNode.get(`point-of-sale/${id}`);
    dispatch(setPointOfSaleInfo(payload.data));
    dispatch(
      setSelectedSellers(setSellersSanitizer(_get(payload, 'data.users')))
    );
    dispatch(fetchUserNames(id));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('PointOfSaleInfo'));
  }
};

export const setPointOfSaleSellers = (id, params = {}) => async dispatch => {
  dispatch(sendRequest('UpdatePointOfSaleSellers'));
  try {
    const payload = await axiosRequest.put(
      `point_of_sales/${id}/users`,
      params
    );
    dispatch(fetchPointOfSaleInfo(id));
    dispatch(
      newAlert('success', 'sucess', 'Vendedores actualizados exitosamente')
    );
  } catch (errors) {
    dispatch(serverStatusError(errors));
    dispatch(newAlert('error', 'ERROR:', errors.errors.base));
  } finally {
    dispatch(completeRequest('UpdatePointOfSaleSellers'));
  }
};

export const updatePointOfSale = (params = {}) => async dispatch => {
  dispatch(sendRequest('UpdatePointOfSale'));
  try {
    await requestServiceNode.post(`point-of-sale/update`, params);
    dispatch(fetchPointOfSaleInfo(params.id));
    dispatch(
      newAlert('success', 'sucess', 'Punto de venta actualizada exitosamente')
    );
  } catch (errors) {
    dispatch(serverStatusError(errors));
    dispatch(errorOnRequest('UpdatePointOfSale', errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('UpdatePointOfSale'));
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Checkbox from '../../shared/Inputs/Checkbox';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import { Formik } from 'formik';
import { validationSchema } from './QueryMegaQuotaValidation';
import CustomDialog from '../../shared/Modals/CustomDialog';
import OurDatePicker from '../../shared/CustomDatePicker';
import formatMoney from '../../../helpers/formatMoney';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

class QueryMegaQuota extends Component {
  state = {
    attemp: 0,
    megaquotaLoading: false,
  };

  onSubmit = (values, attemp = 0) => {
    const hasCosigner = values.hasCosigner;
    this.setState({ megaquotaLoading: true });

    this.props.queryMegaQuotas({
      queryType: 'contract',
      isSidebar: true,
      debtor: {
        personIdType: doctypes.find(o => o.name === values.personIdType).id,
        personId: values.personId.toString(),
        contractId: values.contract,
        birthdate: values.debtorBirthday,
        expeditionDate: values.debtorDocumentExpeditionDate,
      },
      cosigner: hasCosigner ? {
        contractId: values.cosignerContract,
        personIdType: doctypes.find(o => o.name === values.cosignerIdentificationType).id,
        personId: values.cosignerIdentification.toString(),
        birthdate: values.cosignerBirthday,
        expeditionDate: values.cosignerDocumentExpeditionDate,
      } : null
    }).then(data => {
      if (!data) this.setState({ attemp: 0, megaquotaLoading: false });

      const debtorMegaQuota = data.megaQuotas[0];
      const cosignerMegaQuota = data.megaQuotas[1];

      const { milisecondsToNextConsult, numberOfConsults } = this.calculateNumberOfConsults();

      if (debtorMegaQuota.quota !== null && (!hasCosigner && cosignerMegaQuota === null || hasCosigner && cosignerMegaQuota.quota !== null) || attemp === numberOfConsults) {
        this.setState({ attemp: 0, megaquotaLoading: false });
      } else {
        if (attemp + 1 !== numberOfConsults) this.setState({ attemp: attemp + 1 });
        setTimeout(() => this.onSubmit(values, attemp + 1), milisecondsToNextConsult);
      };
    });
  };

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  handleCheckBox(values, setFieldValue) {
    setFieldValue('hasCosigner', !values.hasCosigner);
    if (!values.hasCosigner) {
      setFieldValue('cosignerContract', '');
      setFieldValue('cosignerIdentification', '');
    }
  }

  calculateMegaquota = () => {
    const { quotas, usedQuota } = this.props;
    const quotaValues = quotas.filter(client => client !== null);
    const minQuotaClient = quotaValues.reduce((min, client) => (client.quota < min.quota ? client : min), quotaValues[0]);
    const megaQuotaValue = minQuotaClient && minQuotaClient.quota !== null ? (minQuotaClient.quota - usedQuota) : null;

    return { megaQuotaValue };
  };

  calculateNumberOfConsults = () => {
    const { timeForWaitingResponseInMegacupoConsult } = this.props;

    const milisecondsToNextConsult = 10 * 1000;
    const maximumMilisecondsToConsult = timeForWaitingResponseInMegacupoConsult * 1000;
    const numberOfConsults = Math.floor(maximumMilisecondsToConsult / milisecondsToNextConsult);

    return { milisecondsToNextConsult, numberOfConsults };
  }

  renderResponse() {
    const {
      megaquotaAllowedCategoryIds,
      serverErrors = [],
      availableQuota,
      classes,
      scoringQuota,
      isScoringUsed
    } = this.props;

    const { attemp } = this.state;

    if (serverErrors.length > 0) {
      return (
        <div>
          <p>{serverErrors}</p>
        </div>
      );
    }

    const { numberOfConsults } = this.calculateNumberOfConsults();
    const { megaQuotaValue } = this.calculateMegaquota();
    const isMegaQuotaAbleToApply = (megaQuotaValue || 0) > availableQuota || scoringQuota > availableQuota;

    return (
      <div>
        <br></br>
        <div className={classes.extraQuotaItem}>
          {megaQuotaValue !== null && attemp === 0 ? (
            isMegaQuotaAbleToApply ? (
              isScoringUsed ? (
                <>
                  <p>Su cupo con Scoring es <b>{formatMoney(scoringQuota)}</b>. Se omite el uso de Megacupo.</p>
                </>
              ) : (
                <>
                  <p>El cupo total utilizable con Megacupo es de <b>{formatMoney(megaQuotaValue)}</b>.</p>
                  {megaquotaAllowedCategoryIds.length > 0 && (
                    <>
                      <p>Este cupo se encuentra disponible para utilizar únicamente en las siguientes categorías:{' '}</p>
                      <ul>{megaquotaAllowedCategoryIds.map((cat) => <li key={cat.id}>{cat.name}</li>)}</ul>
                    </>
                  )}
                </>
              )
            ) : (
              <p>Su cupo disponible es de: <b>{formatMoney(availableQuota)}</b></p>
            )
          ) : (
            <p>
              {
                attemp === 0 || attemp === numberOfConsults ?
                  'Se agotó el tiempo de respuesta para consulta de Megacupo. Intente más tarde.' :
                  'Se está realizando la consulta del Megacupo. Espere un momento.'
              }
            </p>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { classes, loading, isOpen, hasSearched, cosignerIsRequired } = this.props;
    const { megaquotaLoading } = this.state;

    return (
      isOpen && (
        <Formik
          initialValues={{
            hasCosigner: false,
            contract: '',
            cosignerContract: '',
            personIdType: 'Cédula',
            cosignerIdentificationType: 'Cédula',
            personId: '',
            cosignerIdentification: '',
            debtorBirthday: null,
            cosignerBirthday: null,
            debtorDocumentExpeditionDate: null,
            cosignerDocumentExpeditionDate: null,
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            this.props.resetQueryMegaQuotaData();
            this.onSubmit(values);
          }}
          onReset={(_, actions) => {
            actions.resetForm();
            this.props.closeQueryMegaQuotaModal();
          }}
          render={({
            values,
            errors,
            touched,
            dirty,
            handleSubmit,
            handleBlur,
            handleChange,
            handleReset,
            setFieldValue
          }) => (
            <CustomDialog
              onClose={handleReset}
              onConfirm={handleSubmit}
              loading={loading || megaquotaLoading}
              disableBackdropClick={true}
              open={isOpen}
              title="Consulta de Megacupo"
              buttonText="Consultar"
              disabled={Object.keys(errors).length > 0 || !dirty}
              classes={{
                root: classes.queryQuotaDialogRoot,
                content: classes.queryQuotaDialogContent
              }}
            >
              <form autoComplete="off" className={classes.form}>
                <Grid container spacing={24} alignItems="flex-start">
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OutlinedTextField
                      onKeyPress={this._onKeyPress}
                      label="Numero de contrato deudor:"
                      name="contract"
                      type="number"
                      error={touched.contract && !!errors.contract}
                      helperText={touched.contract && errors.contract}
                      value={values.contract}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <SimpleSelect
                      value={values.personIdType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="personIdType"
                      label="Tipo de identificación del deudor:"
                      options={mapListToOptions(doctypes, {}, true)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OutlinedTextField
                      onKeyPress={this._onKeyPress}
                      type="number"
                      label="Identificación del deudor:"
                      name="personId"
                      error={touched.personId && !!errors.personId}
                      helperText={touched.personId && errors.personId}
                      value={values.personId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OurDatePicker
                      name={'debtorBirthday'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha nacimiento deudor:"
                      value={values.debtorBirthday}
                      onChange={e => setFieldValue('debtorBirthday', e)}
                      onError={() => setFieldValue('debtorBirthday', null)}
                      error={touched.debtorBirthday && !!errors.debtorBirthday}
                      helperText={touched.debtorBirthday && errors.debtorBirthday}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} className={classes.inputGrid}>
                    <OurDatePicker
                      name={'debtorDocumentExpeditionDate'}
                      invalidLabel="Fecha inválida"
                      maxDateMessage="Fecha inválida"
                      minDateMessage="Fecha inválida"
                      invalidDateMessage={'Fecha inválida'}
                      label="Fecha expedición documento deudor:"
                      value={values.debtorDocumentExpeditionDate}
                      onChange={e => setFieldValue('debtorDocumentExpeditionDate', e)}
                      onError={() => setFieldValue('debtorDocumentExpeditionDate', null)}
                      error={touched.debtorDocumentExpeditionDate && !!errors.debtorDocumentExpeditionDate}
                      helperText={touched.debtorDocumentExpeditionDate && errors.debtorDocumentExpeditionDate}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  {cosignerIsRequired && <div className={classes.cosignerError}>
                    <span className={classes.errors}>
                      {"Por politica se requiere codeudor obligatorio"}
                    </span>
                  </div>}

                  <Grid xs={12} className={classes.Checkbox}>
                    <Checkbox
                      name="hasCosigner"
                      value={values.hasCosigner}
                      label="Codeudor"
                      disabled={loading}
                      onChange={() => this.handleCheckBox(values, setFieldValue)}
                      customLabelRoot={true}
                    />
                  </Grid>

                  {values.hasCosigner && (
                    <React.Fragment>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          label="Numero de contrato codeudor:"
                          name="cosignerContract"
                          type="number"
                          error={(touched.cosignerContract && !!errors.cosignerContract)}
                          helperText={(touched.cosignerContract && errors.cosignerContract)}
                          value={values.cosignerContract}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <SimpleSelect
                          value={values.cosignerIdentificationType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="cosignerIdentificationType"
                          label="Tipo de identificación del codeudor:"
                          options={mapListToOptions(doctypes, {}, true)}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          type="number"
                          label="Identificación del codeudor:"
                          name="cosignerIdentification"
                          error={touched.cosignerIdentification && !!errors.cosignerIdentification}
                          helperText={touched.cosignerIdentification && errors.cosignerIdentification}
                          value={values.cosignerIdentification}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OurDatePicker
                          name={'cosignerBirthday'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha nacimiento deudor:"
                          value={values.cosignerBirthday}
                          onChange={e => setFieldValue('cosignerBirthday', e)}
                          onError={() => setFieldValue('cosignerBirthday', null)}
                          error={touched.cosignerBirthday && !!errors.cosignerBirthday}
                          helperText={touched.cosignerBirthday && errors.cosignerBirthday}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item sm={6} xs={12} className={classes.inputGrid}>
                        <OurDatePicker
                          name={'cosignerDocumentExpeditionDate'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha expedición documento deudor:"
                          value={values.cosignerDocumentExpeditionDate}
                          onChange={e => setFieldValue('cosignerDocumentExpeditionDate', e)}
                          onError={() => setFieldValue('cosignerDocumentExpeditionDate', null)}
                          error={touched.cosignerDocumentExpeditionDate && !!errors.cosignerDocumentExpeditionDate}
                          helperText={touched.cosignerDocumentExpeditionDate && errors.cosignerDocumentExpeditionDate}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                {hasSearched && (
                  <Grid xs={12}>
                    {this.renderResponse()}
                  </Grid>
                )}
              </form>
            </CustomDialog>
          )}
        />
      )
    );
  }
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0
  },
  Checkbox: {
    marginLeft: '12px',
    marginBottom: '12px',
    marginTop: '12px'
  },
  notFound: {
    marginTop: 10,
    color: 'red'
  },
  dataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px 0px'
  },
  dataRegister: {
    margin: '2.5px 0px'
  },
  extraQuotaItem: {
    marginTop: 10,
    borderTop: 'solid 1px #BCBCBC',
    padding: 10,
    width: '100%',
  },
  errors: {
    marginTop: '10px',
    margin: 0,
    fontSize: '13px',
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#fe0b0b',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    marginRight: 0,
    padding: '6px 8px',
    width: 100
  },
  queryQuotaDialogRoot: {
    ['@media (max-width:600px)']: {
      width: '95%',
      margin: '0 24px'
    }
  },
  queryQuotaDialogContent: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  inputGrid: {
    padding: '6px 12px 6px 12px !important',
    ['@media (max-width:600px)']: {
      padding: '0 12px !important'
    }
  },
  grayContainer: {
    backgroundColor: '#EFF1F4',
    marginBottom: '5%',
    padding: 20
  },
  title: {
    color: '#111'
  },
  tag: {
    margin: 0,
    fontSize: '13px',
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#fe0b0b',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  },
  cosignerError: {
    width: '100%'
  }
});

QueryMegaQuota.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(QueryMegaQuota);

import _pickBy from 'lodash/pickBy';

const saleRevokeFilters = filters => {
  const { saleId, contractId, cause, status, zoneId, page, rowsPerPage } = filters;
  const sanitizedFilters = {
    saleId,
    contractId,
    cause,
    status,
    returnType: 'returned',
    zoneId,
    page,
    rowsPerPage
  };

  const STATUSES = {
    registered: 0,
    approved: 1,
    revoked: 2,
    reviewed: 3
  };

  const params = _pickBy(sanitizedFilters, filter => !!filter);
  return {
    ...params,
    sale_return_details_status_eq: STATUSES[status]
  };
};

export default saleRevokeFilters;

import { LIST_SCORING_RESPONSES } from '../constants/action_constants';
import { newAlert } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest } from './request_state';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { scoringConsultsListSanitizer } from '../helpers/sanitizers/scoringSanitizer';
import { history } from '../helpers/history';

const requestService = new RequestService('node');

function setScoringList(data, meta) {
  return {
    type: LIST_SCORING_RESPONSES,
    data,
    meta
  };
}

export const fetchScoringList = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/scoring_responses?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'scoring-responses',
      scoringConsultsListSanitizer(params)
    );
    dispatch(setScoringList(payload.data, payload.meta));
  } catch (errors) {
    const err = await errors.json();
    if (err.errors) {
      dispatch(newAlert('error', 'ERROR:', err.errors.base));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest());
  }
};

import * as Yup from 'yup';

export const validationSchema = companyName => Yup.object().shape({
  initialDate: Yup.string()
    .when('commissionTypeCode', {
      is: 'sale',
      then: Yup.string()
        .nullable()
        .required('Fecha inicial es requerida')
    })
    .when('commissionTypeCode', {
      is: 'advertisement',
      then: Yup.string().nullable()
    }),
  recoveryPercentage: Yup.number()
    .min(0.0, 'El valor mínimo de la comisión de cobro es 0.0')
    .max(100.0, 'El valor máximo de la comisión de cobro es 100.0')
    .nullable()
    .required('Comisión de cobro es requerida'),
  commissionTypeName: Yup.string(),
  supplierName: Yup.string().when(
    ['commissionTypeCode','articleName', 'categoryName'],
    (commissionTypeCode, articleName, categoryName, schema) => {
      if (commissionTypeCode == 'sale') {
        if ((categoryName != '' && articleName) || companyName === 'GDG') {
          return schema.required('Proveedor es requerido');
        }
      } else {
        return schema.required('Proveedor es requerido');
      }
    }
  ),
  articleName: companyName === 'GDG' && Yup.string().required('Artículo es requerido'),
  saleChannelName: Yup.string().nullable(),
  categoryName: Yup.string()
  .when('commissionTypeCode', {
    is: 'sale',
    then: Yup.string()
      .nullable()
      .required('Categoría es requerida')
  })
  .when('commissionTypeCode', {
    is: 'advertisement',
    then: Yup.string().nullable()
  })
});

export default validationSchema;

import GlobalSettingList from './GlobalSettingList';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  fetchGlobalSettings,
  updateGlobalSetting
} from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    canUpdateGlobalSettings:
      state.authentication.currentUser.canUpdateGlobalSettings,
    globalSettings: state.globalSetting.list,
    companyList: state.globalSetting.orgData.company_name_list,
    metadata: state.globalSetting.metadata,
    requestGlobalSettingsSend: state.requestState.requestGlobalSettingsSend,
    requestSaveGlobalSettingSend:
      state.requestState.requestSaveGlobalSettingSend,
    requestErrorsOnSaveGlobalSetting:
      state.requestState.requestErrorsOnSaveGlobalSetting,
    globalSettingSaved: state.globalSetting.saved
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGlobalSettings: bindActionCreators(fetchGlobalSettings, dispatch),
    updateGlobalSetting: bindActionCreators(updateGlobalSetting, dispatch)
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GlobalSettingList);

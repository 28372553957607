const styles = theme => ({
  stepCircle: {
    color: 'white',
    width: '16px',
    height: '16px',
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  notDoneStep: {
    border: '4px solid rgba(235,238,241, 1)'
  },
  notDoneColor: {
    backgroundColor: 'rgba(235,238,241, 1)'
  },
  endCircles: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  lastCircle: {
    position: 'relative',
    left: '3px'
  },
  registered: {
    backgroundColor: '#FEB00B'
  },
  pendingRevisionStatus: {
    border: '4px solid #FEB00B'
  },
  approved: {
    backgroundColor: '#00C396'
  },
  revoked: {
    backgroundColor: '#DB4945'
  },
  revokedColor: {
    backgroundColor: '#DB4945'
  },
});

export default styles;

import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { ImagePicker } from 'react-file-picker';

import StepperButtons from '../../shared/StepperButtons';
import SegmentHeader from '../../shared/SegmentHeader';
import Preview from '../../shared/Preview';
import CustomDialog from '../../shared/Modals/CustomDialog';
import ActivityIndicator from '../../shared/ActivityIndicator';
import Camera from '../../shared/Camera';
import combineStyles from '../../../helpers/combineStyles';
import TypographyStyles from '../../../styles/typography';
import LayoutStyles from '../../../styles/layout';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import ProgressButton from '../../shared/Buttons/ProgressButton';

import CancelSale from '../CancelSale';
import { history } from '../../../helpers/history';

import { Formik, Form } from 'formik';
import {
  validationSchema,
  validationSchemaCause,
  validationSchemaAdditionalData
} from '../PortalIDCheck/PortalIDCheckValidation';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';

class DigitalSaleVoucher extends PureComponent {
  state = {
    submit: false,
    base64imgArray: [],
    voucherShouldOpen: false,
    voucherImageRef: null,
    causalModal: false,
    observation: ''
  };

  webcamRef = React.createRef();

  componentDidMount() {
    !this.props.companyCode && this.props.fetchSessionData();
    this.fetchSaleDetails();
  }

  fetchSaleDetails = () => {
    this.props.details(this.props.match.params.id);
  };

  onSubmit = () => {
    Promise.all(this.state.base64imgArray.map(async base64 => {
      const res = await fetch(base64)
      const blob = await res.blob();
      return new File([blob], "File", { type: "image/jpeg" })
    })
    ).then(files => {
      this.props.digitalSaleVoucherStep({
        saleId: this.props.match.params.id,
        files,
        isExternal: false
      });
    })
  };

  _handleConfirm = values => {
    const params = {
      sale: {
        id: this.props.salesDetail.id,
        cause: values.cause,
        observation: values.observation
      }
    };
    this.props.cancelSale(params);
    history.push(`/sales/${this.props.salesDetail.id}`);
  };

  _handleClose = () => {
    this.setState({ voucherShouldOpen: false });
  };

  _handleCloseCausal = () => {
    this.setState({ causalModal: false });
  };

  _handleOpenVoucherImage = id => {
    this.setState({ voucherShouldOpen: true, voucherImageRef: id });
  };

  _handleDeleteVoucherImage = id => {
    const newBase64imgArray = this.state.base64imgArray.filter(
      (_, i) => i !== id
    );
    this.setState({
      base64imgArray: newBase64imgArray
    });
  };

  _errorImagePicker = errMsg => {
    if (errMsg.includes('jpg')) {
      errMsg = 'Debe seleccionar un archivo de tipo: jpg';
    } else {
      if (errMsg.includes('The uploaded image is too large.')) {
        errMsg =
          'La imagen seleccionada supera las dimensiones máximas permitidas.';
      } else if (errMsg.includes('The uploaded image is too small.')) {
        errMsg =
          'La imagen seleccionada no supera las dimensiones Mínimas permitidas.';
      } else {
        errMsg = 'Ha ocurrido un error.';
      }
    }
    this.props.newAlert('error', 'ERROR:', errMsg);
  };

  renderCausals() {
    const { classes } = this.props;
    const initialValuesCause = {
      cause: '',
      name: ''
    };
    return (
      <Formik
        initialValues={initialValuesCause}
        validationSchema={validationSchemaCause}
        onSubmit={this._handleConfirm}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue
        }) => (
          <CustomDialog
            open={this.state.causalModal}
            disableBackdropClick={true}
            onClose={this._handleCloseCausal}
            onConfirm={handleSubmit}
            title={`Entrega no exitosa`}
            contentText={`Por favor escoja una causal, si lo desea, podrá añadir una observación.`}
            buttonText={'CONTINUAR'}
          >
            <Form className={classes.formCause}>
              <Grid container>
                <Fade in={true}>
                  <Grid item xs={12}>
                    <OutlinedSelectChippedInput
                      label="Causal:"
                      required
                      name="cause"
                      options={this.props.causals.map(causal => ({
                        value: causal.id,
                        title: causal.name
                      }))}
                      value={this.state.cause || values.cause}
                      onChange={e => {
                        handleChange(e);
                      }}
                      disabled={false}
                      withChip={false}
                      error={touched.cause && !!errors.cause}
                      helperText={touched.cause && errors.cause}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Fade>
                <Grid item xs={12}>
                  <TextField
                    label="Observación:"
                    name="observation"
                    margin="normal"
                    variant="outlined"
                    onChange={e => {
                      handleChange(e);
                    }}
                    inputProps={{ maxLength: 200 }}
                    value={this.state.observation || values.description}
                    // disabled={requestSend}
                    fullWidth
                    multiline
                  />
                  <p className={classes.observation}>{`${this.state.observation.length
                    } / 200`}</p>
                </Grid>
              </Grid>
            </Form>
          </CustomDialog>
        )}
      />
    );
  }

  _resizedataURL(datas, wantedMaxWidthOrHeight) {
    return new Promise(async function (resolve, reject) {

      // We create an image to receive the Data URI
      var img = document.createElement('img');

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const currentWidth = img.width;
        const currentHeight = img.height;

        let wantedWidth;
        let wantedHeight;
        let factor;
        // We set the dimensions at the wanted size.
        if (currentWidth >= currentHeight && currentWidth > wantedMaxWidthOrHeight) {
          factor = wantedMaxWidthOrHeight / currentWidth
          wantedWidth = wantedMaxWidthOrHeight
          wantedHeight = currentHeight * factor
        } else if (currentWidth < currentHeight && currentHeight > wantedMaxWidthOrHeight) {
          factor = wantedMaxWidthOrHeight / currentWidth
          wantedWidth = currentWidth * factor
          wantedHeight = wantedMaxWidthOrHeight
        } else {
          wantedWidth = currentWidth;
          wantedHeight = currentHeight;
        }
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

        const dataURI = canvas.toDataURL('image/jpeg');

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;

    })
  }

  render() {
    const {
      classes,
      stepIsLoading,
      requestSend,
      salesDetail,
      causals,
      canAttachDocumentsOnTraditionalSale,
      canReattachVoucherOnRemoteSale,
      canAttachDocumentsForDocRevisions,
    } = this.props;

    const {
      saleType,
      saleStatus,
      missSaleVoucher,
      saleVouchers,
      externalCode,
      documentalRevision
    } = salesDetail;

    const isTraditionalSale = saleType === 0;
    const isRemoteSale = saleType === 1;
    const isActiveSale = !['Devuelta', 'Anulada'].includes(saleStatus);

    const saleHasVouchers = saleVouchers.length > 0;
    const canAttachVoucherToRemoteSale = missSaleVoucher;

    const canAttachInTraditionalSale = isTraditionalSale &&
      canAttachDocumentsOnTraditionalSale && (
        !saleHasVouchers ||
        (saleHasVouchers && canReattachVoucherOnRemoteSale)
      );

    const canAttachInRemoteSale = isRemoteSale &&
      canAttachVoucherToRemoteSale && (
        !saleHasVouchers ||
        (saleHasVouchers && canReattachVoucherOnRemoteSale)
      );

    const canAttachDocumentsForRevision = canAttachDocumentsForDocRevisions && (!documentalRevision || documentalRevision.status === 2);

    const canAttachVoucher = isActiveSale && externalCode &&
      ((canAttachInTraditionalSale || canAttachInRemoteSale) || canAttachDocumentsForRevision);

    const innerWidthViewPort = window.innerWidth;
    const capture = async selectedPhoto => {
      const imageSrc = selectedPhoto || this.webcamRef.current.getScreenshot();
      const newImageSrc = await this._resizedataURL(imageSrc, 1000);
      const newBase64imgArray = this.state.base64imgArray.concat(newImageSrc);
      this.setState({
        base64imgArray: newBase64imgArray
      });
    };

    if (!canAttachVoucher) {
      history.replace(`/sales/${salesDetail.id}`);
    }

    return (
      <div className={classes.container}>
        {requestSend && <ActivityIndicator />}
        {!requestSend && salesDetail && causals && (
          <Fragment>
            <div className={classes.titleRootWrapper}>
              <h1 className={classnames(classes.headline4, classes.title)}>
                {`Venta #${this.props.match.params.id}`}
              </h1>
            </div>
            <Paper className={classes.paper}>
              <CustomDialog
                open={
                  this.state.voucherShouldOpen &&
                  this.state.voucherImageRef !== null
                }
                loading={false}
                disabled={false}
                onClose={this._handleClose}
                onConfirm={this._handleClose}
                customWidth={true}
                maxWidth={innerWidthViewPort < 1000 ? '100%' : 1000}
                title={`Voucher`}
              >
                {this.state.voucherImageRef != null ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        '<img src="' +
                        this.state.base64imgArray[this.state.voucherImageRef] +
                        '" style="max-width: 100%;"/>'
                    }}
                  />
                ) : null}
              </CustomDialog>
              {this.renderCausals()}
              <Fragment>
                <div className={classes.segmentHeaderOptions}>
                  <SegmentHeader className={classes.segmentHeader}>
                    {
                      'Favor tomar captura del voucher de la compra. Puede tomar varias imágenes'
                    }
                  </SegmentHeader>
                  <PrimaryButton
                    onClick={() => {
                      this.setState({ causalModal: true });
                    }}
                    className={classes.button}
                  >
                    Entrega no exitosa
                  </PrimaryButton>
                </div>
                <Camera
                  captureValid={true}
                  detections={null}
                  disabledNext={null}
                  setCameraDetections={null}
                  webcamRef={this.webcamRef}
                  capture={capture}
                  capturingDocument={false}
                  capturingVoucher={true}
                  extension={'jpeg'}
                />
                {stepIsLoading && (
                  <ActivityIndicator className={classes.loadingIndicator} />
                )}
                <div className={classes.previewWrapper}>
                  {this.state.base64imgArray.length > 0 &&
                    this.state.base64imgArray.map((item, i) => {
                      return (
                        <Preview
                          key={i}
                          base64img={item}
                          width={innerWidthViewPort * 0.15}
                          onClick={() => this._handleOpenVoucherImage(i)}
                          onDelete={() => this._handleDeleteVoucherImage(i)}
                        />
                      );
                    })}
                </div>
                <ImagePicker
                  extensions={['jpg', 'jpeg']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 10000,
                    minHeight: 100,
                    maxHeight: 10000
                  }}
                  onChange={capture}
                  onError={this._errorImagePicker}
                >
                  <ProgressButton
                    className={classnames(classes.button, classes.action)}
                    color="secondary"
                    variant="contained"
                  >
                    CARGAR FOTO
                  </ProgressButton>
                </ImagePicker>
                <StepperButtons
                  onSubmit={this.onSubmit}
                  goBack={this.props.goBack}
                  alternativeButtonText={'Capturar'}
                  alternativeButtonCallback={capture}
                  nextText={'Enviar'}
                  disabledNext={this.state.base64imgArray.length === 0}
                />
              </Fragment>
            </Paper>
          </Fragment>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'Column'
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5'
  },
  button: {
    margin: 2,
    padding: '8px'
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 2
    }
  },
  segmentHeaderMargin: {
    marginBottom: 2 * theme.spacing.unit
  },
  paper: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  liDivider: {
    listStyleType: 'none'
  },
  previewWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  loadingIndicator: {
    position: 'fixed',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 1
  },
  formCause: {
    padding: '8px'
  }
});

DigitalSaleVoucher.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(LayoutStyles, TypographyStyles, styles)
)(DigitalSaleVoucher);

import * as Yup from 'yup';
import { formatBack } from '../../shared/Inputs/Masks';
import { _moneyFormat } from '../../../helpers/utilityFunctions';

export const validationSchema = hasCardifSeller => Yup.object().shape({
  articles: Yup.array().of(
    Yup.object().shape({
    })
  ),
  gracePeriod: Yup.boolean().default(0),
  cardifSellerId: hasCardifSeller ? Yup.string().required('Debe ingresar un vendedor.') : Yup.string(),
  initialPayment: Yup.number()
    .integer('Debe tener un valor entero.')
    .moreThan(-1, 'Debe tener un valor positivo o cero.')
    .default(0),
  observations: Yup.string().default('')
});

export function articleValidation(
  article,
  articleExist,
  isVoluntaryInsurance,
  giftCardArticles,
  giftCardValueRange,
  api_error,
  returnBool
) {
  if (!article.id) return returnBool ? false : '*Requerido';
  if (article.id < 1 || (!articleExist && !isVoluntaryInsurance))
    return returnBool ? true : 'Selecciona un artículo válido.';
  if (giftCardValueRange && giftCardArticles.includes(article.id)) {
    const { maxValue, minValue } = giftCardValueRange;
    const articleValue = formatBack(article.amount, 'currency');
    if (
      !maxValue ||
      minValue > maxValue ||
      articleValue * article.quantity > maxValue ||
      articleValue < minValue
    ) {
      return returnBool ? true : '*Tarjeta Regalo inválida';
    } else {
      return returnBool
        ? true
        : `Mínimo: ${_moneyFormat(minValue)} - Máximo: ${_moneyFormat(
          maxValue
        )}`;
    }
  }
  if (api_error && api_error.article_id)
    return returnBool ? true : api_error.article_id[0];
  return returnBool ? false : '*Artículo válido';
}

import {
  SUPPLIERS_SET,
  SUPPLIER_INFO_SET,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_COMPLETE
} from '../constants/action_constants';

import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
import _get from 'lodash/get';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import {
  supplierFilterSanitizer,
  supplierDashboardFilterSanitizer
} from '../helpers/sanitizers/supplierSanitizer';
import RequestService from '../services/request_service';
const requestNodeService = new RequestService('node');

//* PURE ACTION FUNCTIONS
function setSuppliers(items, pagi) {
  return {
    type: SUPPLIERS_SET,
    data: items,
    meta: pagi
  };
}

function setSupplierInfo(info) {
  return {
    type: SUPPLIER_INFO_SET,
    data: info
  };
}

function updateRequest() {
  return { type: SUPPLIER_UPDATE_REQUEST };
}

function updateComplete() {
  return { type: SUPPLIER_UPDATE_COMPLETE };
}

export const fetchSuppliers = (params = {}) => async dispatch => {
  dispatch(sendRequest('Suppliers'));
  history.replace(`/suppliers?${stringifyQuery(params)}`);
  try {
    const payload = await requestNodeService.get(
      'suppliers',
      supplierFilterSanitizer(params)
    );
    dispatch(setSuppliers(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('Suppliers'));
  }
};

export const fetchSuppliersDashboard = (params = {}) => async dispatch => {
  dispatch(sendRequest('Suppliers'));
  try {
    const payload = await requestNodeService.get(
      'suppliers/dashboard',
      supplierDashboardFilterSanitizer(params)
    );
    dispatch(setSuppliers(payload.data, payload.meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.message));
  } finally {
    dispatch(completeRequest('Suppliers'));
  }
};

export const fetchSupplierInfo = id => async dispatch => {
  dispatch(sendRequest('SupplierInfo'));
  try {
    const payload = await requestNodeService.get(`suppliers/${id}`);
    dispatch(setSupplierInfo(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    _get(errors, 'errors.base[0]') &&
      dispatch(newAlert('error', 'ERROR:', errors.errors.base[0]));
  } finally {
    dispatch(completeRequest('SupplierInfo'));
  }
};

// UPDATE SUPPLIER CONFIG
export const updateSupplierConfig = (id, body = {}) => async dispatch => {
  dispatch(updateRequest());
  try {
    await requestNodeService.put(`suppliers/${id}`, body, {}, true);
    dispatch(
      newAlert('success', '', 'Configuración modificada satisfactoriamente.')
    );
    dispatch(fetchSupplierInfo(id));
  } catch (errors) {
    return errors.json().then(e => {
      if(_get(e, 'errors.title')) {
        const message = typeof e.errors.title === 'string' ? e.errors.title : e.errors.title.message;
        dispatch(newAlert('error', 'ERROR:', message));
      }
    });
  } finally {
    dispatch(updateComplete());
  }
};

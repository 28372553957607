import {
  SET_SCORING_CONFIGURATION_LIST_DATA,
  OPEN_SCORING_CONFIGURATION_FORM_MODAL,
  CLOSE_SCORING_CONFIGURATION_FORM_MODAL,
  CREATE_SCORING_CONFIGURATION,
  UPDATE_SCORING_CONFIGURATION,
  DELETE_SCORING_CONFIGURATION
} from '../constants/scoring_configurations_constants';

const initialState = {
  configs: [],
  meta: {},
  formModalIsOpen: false
};

export function scoringConfigurations(state = initialState, action) {
  switch (action.type) {
    case SET_SCORING_CONFIGURATION_LIST_DATA:
      return {
        ...state,
        configs: action.data,
        meta: action.meta
      };
    case CREATE_SCORING_CONFIGURATION:
      return {
        ...state,
        configs: [action.data, ...state.configs]
      };
    case UPDATE_SCORING_CONFIGURATION:
      const { configs } = state;
      const updatedConfig = action.data;
      const configIndex = configs.findIndex(
        ({ id }) => Number(id) === Number(action.data.id)
      );

      if (configIndex !== -1) {
        const updatedConfigs = [...configs];
        updatedConfigs[configIndex] = updatedConfig;
        return {
          ...state,
          configs: updatedConfigs
        };
      }

      return state;
    case DELETE_SCORING_CONFIGURATION:
      return {
        ...state,
        configs: state.configs.filter(
          ({ id }) => Number(id) !== Number(action.data.id)
        )
      };
    case OPEN_SCORING_CONFIGURATION_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: true
      };
    case CLOSE_SCORING_CONFIGURATION_FORM_MODAL:
      return {
        ...state,
        formModalIsOpen: false
      };
    default: {
      return state;
    }
  }
}

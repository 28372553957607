import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import SegmentHeader from '../../shared/SegmentHeader';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StepperButtons from '../../shared/StepperButtons';
import {
  initialValuesSanitizer,
  previewVoucherPdfSanitizer,
  submitOtpSanitizerVoucher,
  submitSanitizer
} from '../../../helpers/sanitizers/portalPromissorySanitizer';
import PromissoryPreview from '../PromissoryDetail/PromissoryPreview';
import MIMETYPES from '../../../constants/mimetypes';
import { PdfTypeV2 } from '../../../constants/enums';
import OTPV2Modal from './OTPModal';
import Icon from '@material-ui/core/Icon';
import ActivityIndicator from '../../shared/ActivityIndicator';

const signed_document = require('../../../assets/icons/contract.png');

class DigitalSaleOTPSale extends PureComponent {
  constructor(props) {
    super(props);

    this.setCode = this.setCode.bind(this);
  }

  state = {
    debtorExternalTabOpened: false,
    cosignerExternalTabOpened: false,
    submit: false,
    showOtpValidationModal: false,
  };

  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  setCode(code) {
    this.setState({
      code: code
    });
  }

  componentDidMount() {
    this.props.setCurrentStep(this.currentStep);

    if (this.props.isPromissoriesV2 && !this.props.voucherUrl) {
      const parsedValues = previewVoucherPdfSanitizer(this.props);
      this.props.previewDocumentV2(parsedValues, "Voucher", this.props.isRemoteTeleshopping);
    }
  }

  initialValues = () => initialValuesSanitizer(this.props);
  _getLocation = (object, key, param, list) => {
    const result = object[key]
      ? this.props[list].find(
          location =>
            object[key] === location.description || object[key] === location.id
        )
      : '';
    if (param) {
      return result[param];
    }
    return result;
  };

  onSubmit = () => {
    const {
      unmaterializedVoucherProcessId,
      process,
      isPromissoriesV2,
      externalProcessId,
      otpV2TransaccionId,
      cosigner,
      voucherSerial
    } = this.props;

    const { otpValidatorCosigner, otpValidatorDebtor } = this.props;
      const otpV2Validator =  cosigner ? otpValidatorDebtor && otpValidatorCosigner : otpValidatorDebtor;
      if (isPromissoriesV2 && !otpV2Validator) {
        this.props.newAlert(
          'error',
          'ERROR:',
          'El voucher no ha sido firmado'
        );
        return;
      }

    if (process === 'remoteTeleshopping') {
      const { contract, verification_sale, observations, price, instalments, salesmanId } = this.props;
      const params = {
        contract,
        verification_sale,
        observations,
        process,
        price,
        instalments,
        unmaterializedVoucherProcessId,
        salesmanId,
        isPromissoriesV2,
        voucherSerial,
        externalProcessId,
      };

      if (isPromissoriesV2 && otpV2Validator) {
        this.props.validateSignedDocumentV2({
          externalProcessId: externalProcessId,
          idTransaccion: otpV2TransaccionId
        }).then((response) => {
          if (response && response.valid) {
            this.props.createRemoteTeleshopping({...params, voucherSerial: response.voucherSerial});
          }
        })
      } else if (!isPromissoriesV2) {
        this.props.createRemoteTeleshopping(params);
      }
    } else {
      if (isPromissoriesV2 && otpV2Validator) {
        this.props.validateSignedDocumentV2({
          externalProcessId: externalProcessId,
          idTransaccion: otpV2TransaccionId
        }).then((response) => {
          if (response && response.valid) {
            this.voucherLastStep()
          }
        })
      } else if (!isPromissoriesV2) {
        this.voucherLastStep()
      }
    }
  };

  voucherLastStep = () => {
    const {
      unmaterializedVoucherProcessId,
      voucherSerial,
      process
    } = this.props;
    const saleDetails = submitSanitizer(
      this.initialValues(),
      this.props,
      this._getLocation,
    );
    this.props.checkDigitalSaleSignedUnmaterializedVoucher(
      {
      unmaterialized_process_id: unmaterializedVoucherProcessId,
      voucher_serial: voucherSerial,
      ...saleDetails
    },
    this.props.contract,
    this.props.routes[this.nextStep],
    process
    );
  };

  handleCloseOtpModal = () => {
    this.setState({ showOtpValidationModal: false });
  };

  handleOpenOtpModal = () => {
    this.setState({ showOtpValidationModal: true });
  };

  handleSend = () => {
    const { otpV2TransaccionId, isRemoteTeleshopping } = this.props;
    if (otpV2TransaccionId) {
      this.setState({ showOtpValidationModal: true });
      return;
    }
    const sendOtpFunction = isRemoteTeleshopping ? this.props.sendOtpV2Teleshopping : this.props.sendOtpV2;
    const parsedValues = submitOtpSanitizerVoucher(PdfTypeV2.voucher, this.props);
    sendOtpFunction(
      parsedValues
    ).then((valid) => {
      if (valid) {
        this.setState({ showOtpValidationModal: true });
      }
    })
  };

  render() {
    const {
      classes,
      contract,
      debtor,
      cosigner,
      isRemoteTeleshopping,
      clientMobile,
      debtorUnmaterializedVoucherUrl,
      cosignerUnmaterializedVoucherUrl,
      unmaterializedVoucherProcessId,
      isPromissoriesV2,
      otpV2TransaccionId,
      otpValidatorDebtor,
      otpValidatorCosigner,
      loading,
      mobile,
      requestSendOtpSent,
      voucherUrl,
      previewPromissoryLoading
    } = this.props;

    if(previewPromissoryLoading) {
      return <ActivityIndicator />
    }

    if (debtorUnmaterializedVoucherUrl && !this.state.debtorExternalTabOpened && !isPromissoriesV2) {
      window.open(debtorUnmaterializedVoucherUrl, '_blank');
      this.setState({ debtorExternalTabOpened: true });
    }
    if (cosignerUnmaterializedVoucherUrl && !this.state.cosignerExternalTabOpened && !isPromissoriesV2) {
      window.open(cosignerUnmaterializedVoucherUrl, '_blank');
      this.setState({ cosignerExternalTabOpened: true });
    }
    const currentUser = { phone: isRemoteTeleshopping ? clientMobile : debtor.mobile };
    const cosignerUser = { phone: cosigner ? cosigner.mobile : null };

    const validCosigner = cosigner && !otpValidatorCosigner;
    const buttonSigner = otpValidatorDebtor && validCosigner ? "codeudor" : "deudor";
    const isOtpValid = cosigner ? otpValidatorDebtor && otpValidatorCosigner : otpValidatorDebtor;

    return (
      <div className={classes.bodySection}>
        {isPromissoriesV2 && voucherUrl !== '' ? (
          <Paper className={classes.paper}>
            <Grid container spacing={40} className={classes.pdf}>
              <Grid item xs={12} md={8}>
                <SegmentHeader className={classes.segmentHeaderOtpV2}>
                  Para proceder con la firma del documento, por favor desplácese hacia abajo y siga las instrucciones.
                </SegmentHeader>
                <PromissoryPreview
                  url={voucherUrl}
                />
                <Grid item xs={12} md={12} className={classes.bottomOptions}>
                  {!isOtpValid ? (
                    <PrimaryButton
                      loading={requestSendOtpSent}
                      onClick={this.handleSend}
                      className={classes.signButton}
                    >
                      {`Firma ${buttonSigner}`}
                    </PrimaryButton>
                  ) : (
                    <Icon classes={{ root: classes.iconRoot }}>
                      <img className={classes.imageIcon} src={signed_document} />
                    </Icon>
                  )}
                  {!isOtpValid ? (
                  <p className={classes.advisoryText} >{`Al momento de iniciar la firma del documento se le enviará un mensaje de texto ${cosigner ? 'a los números': 'al número'} ${
                    cosigner ? currentUser.phone + ' y ' + cosignerUser.phone : currentUser.phone + '.'
                  } `}</p>
                ) : (
                  <p className={classes.advisoryText} >{`El documento ha sido firmado exitosamente. Puede finalizar la venta.`}</p>
                )}
                </Grid>
              </Grid>
            </Grid>
            <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <div className={classes.segmentHeaderOptions}>
              <SegmentHeader className={classes.segmentHeader}>
                {`Se le enviará un mensaje de texto ${cosignerUnmaterializedVoucherUrl ? 'a los números': 'al número'} ${
                  cosignerUnmaterializedVoucherUrl ? currentUser.phone + ' y ' + cosignerUser.phone : currentUser.phone
                } `}
              </SegmentHeader>
              <PrimaryButton
                onClick={() => {
                  this.props.resendOlimpiaOTP(contract, {
                    process_id: unmaterializedVoucherProcessId
                  });
                }}
                className={classes.button}
              >
                Reenviar OTP
              </PrimaryButton>
            </div>
            <div className={classes.item} variant="caption">
              {cosignerUnmaterializedVoucherUrl ? `El voucher está siendo firmado por los usuarios en este momento. Al
                  completar el proceso para ambos usuarios (deudor y codeudor), favor continuar presionando ` :`El pagaré está siendo firmado por el usuario en este momento. Al
                  completar el proceso, favor continuar presionando `}
              <span className={classes.headline}>Siguiente</span>
              {` en esta pantalla`}
            </div>
            <StepperButtons onSubmit={this.onSubmit} goBack={this.props.goBack} />
          </Paper>
        )}
        <OTPV2Modal
          open={this.state.showOtpValidationModal}
          transaccionId={otpV2TransaccionId}
          debtor={debtor}
          handleCloseOtpModal={this.handleCloseOtpModal}
          handleOpenOtpModal={this.handleOpenOtpModal}
          validateOtpV2={this.props.validateOtpV2}
          reSendOtpV2={this.props.reSendOtpV2}
          otpValidatorDebtor={otpValidatorDebtor}
          otpValidatorCosigner={otpValidatorCosigner}
          cosigner={cosigner}
          isRemoteTeleshopping={isRemoteTeleshopping}
          mobile={mobile}
          validateOtpV2Teleshopping={this.props.validateOtpV2Teleshopping}
          reSendOtpV2Teleshopping={this.props.reSendOtpV2Teleshopping}
          loading={requestSendOtpSent || loading}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1,
    marginTop: '7px'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 2
    }
  },
  liDivider: {
    listStyleType: 'none'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5'
  },
  signButton: {
    margin: 2,
    padding: '8px',
    marginRight: '20px'
  },
  pdf: {
    display: 'flex',
    justifyContent: 'center',
  },
  bottomOptions: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    marginTop: '7px'
  },
  segmentHeaderOtp: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 5
    },
    width: '100%',
  },
  advisoryText: {
    textAlign: 'center'
  },
  iconRoot: {
    width: '70px',
    height: '70px',
  },
  imageIcon: {
    height: '70%'
  },
  segmentHeaderOtpV2: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 5
    },
    width: '100%',
  },
  fontSize: '20px'
});

DigitalSaleOTPSale.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(DigitalSaleOTPSale);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import classnames from 'classnames';
import {
  Grid,
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { _numberFormat, _dateFormat } from '../../../helpers/utilityFunctions';
import { history } from '../../../helpers/history';
import Divider from '@material-ui/core/Divider';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

class ExpandCardClient extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    client: PropTypes.object,
    detail: PropTypes.object,
    title: PropTypes.string,
    solidary: PropTypes.bool,
    chip: PropTypes.bool,
    subtitle: PropTypes.string,
    link: PropTypes.bool
  };

  renderItem = (title, content) => {
    const { classes } = this.props;

    return (
      <Grid item lg={6}>
        <p className={classes.subtitle3}>{title}</p>
        <p className={classes.body2}>{content}</p>
      </Grid>
    );
  };

  _renderClientId = (client, clientDetails, classes) => {
    let client_id_type = clientDetails.typeOfIdentification;
    if (!client_id_type)
      client_id_type = client.typeOfIdentification
        ? client.typeOfIdentification
        : 'Identificación';

    return (
      <div className={classes.details}>
        <p className={classnames(classes.headline7, classes.detailsTitle)}>
          Documento Identidad
        </p>
        <Grid container spacing={16} className={classes.contactData}>
          {this.renderItem(
            'Tipo de Documento:',
            client_id_type
          )}
          {this.renderItem(
            'No. Documento:',
            _numberFormat(clientDetails.identification || client.identification)
          )}
          {this.renderItem(
            'Fecha expedición:',
            _dateFormat(
              clientDetails.idExpeditionDate || client.idExpeditionDate
            )
          )}
          {this.renderItem(
            'Lugar expedición:',
            clientDetails.idExpeditionPlace || client.idExpeditionPlace || '--'
          )}
          {this.renderItem(
            'Fecha Nacimiento:',
            _dateFormat(clientDetails.dateOfBirth || client.dateOfBirth)
          )}
        </Grid>
      </div>
    );
  };
  _renderClientPersonal = (client, clientDetails, classes) => {
    return (
      <div className={classes.details}>
        <p className={classnames(classes.headline7, classes.detailsTitle)}>
          Personal
        </p>
        <Grid container spacing={16} className={classes.contactData}>
          {this.renderItem(
            'Género:',
            clientDetails.gender || client.gender || '--'
          )}
          {this.renderItem(
            'Estado civil:',
            clientDetails.maritalStatus || client.maritalStatus || '--'
          )}
          {this.renderItem(
            'Nivel de estudio:',
            clientDetails.levelOfStudies || '--'
          )}
          {this.renderItem(
            'Profesión:',
            clientDetails.profession ? clientDetails.profession.name : '--'
          )}
        </Grid>
      </div>
    );
  };
  _renderClientVivienda = (client, clientDetails, classes) => {
    let bill_owner = clientDetails.billOwner;
    if (bill_owner === null) bill_owner = client.billOwner;
    return (
      <div className={classes.details}>
        <p className={classnames(classes.headline7, classes.detailsTitle)}>
          Vivienda
        </p>
        <Grid container spacing={16} className={classes.contactData}>
          {this.renderItem(
            'Barrio:',
            _get(clientDetails, 'addressData.neighborhood') ||
              _get(client, 'address.neighborhood') ||
              '--'
          )}
          {this.renderItem(
            'Dirección:',
            _get(clientDetails, 'addressData.body') ||
              _get(client, 'address.body') ||
              '--'
          )}
          {this.renderItem(
            'Tipo:',
            clientDetails.typeOfHouse || client.typeOfHouse || '--'
          )}
          {this.renderItem(
            'Estrato:',
            clientDetails.socialStratus || client.socialStratus || '--'
          )}
        </Grid>
      </div>
    );
  };
  _renderClientContact = (client, clientDetails, classes) => {
    return (
      <div className={classes.details}>
        <p className={classnames(classes.headline7, classes.detailsTitle)}>
          Contacto
        </p>
        <Grid container spacing={16} className={classes.contactData}>
          {this.renderItem(
            'Teléfono:',
            clientDetails.phone || client.phone || '--'
          )}
          {this.renderItem(
            'Celular:',
            clientDetails.mobile || client.mobile || '--'
          )}
          {this.renderItem(
            'Correo:',
            clientDetails.email || client.email || '--'
          )}
        </Grid>
      </div>
    );
  };
  _goToClient = id => {
    history.push(`/clients/${id}`);
  };
  render() {
    const { classes, title, client, detail } = this.props;
    if (!client && !detail) {
      return null;
    }
    const clientInfo = client || {};
    const detailInfo = detail || {};
    return (
      <ExpansionPanel classes={{ expanded: classes.expanded }}>
        <ExpansionPanelSummary
          classes={{ root: classes.summary }}
          expandIcon={<ExpandMore />}
        >
          <h2 className={classes.headline8}>
            {title || 'Detalle del Cliente'}
          </h2>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionBody}>
          {this._renderClientId(clientInfo, detailInfo, classes)}
          <Divider light classes={{ root: classes.detailDivider }} />
          {this._renderClientPersonal(clientInfo, detailInfo, classes)}
          <Divider light classes={{ root: classes.detailDivider }} />
          {this._renderClientVivienda(clientInfo, detailInfo, classes)}
          <Divider light classes={{ root: classes.detailDivider }} />
          {this._renderClientContact(clientInfo, detailInfo, classes)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

const styles = theme => ({
  expanded: {
    margin: '24px 0'
  },
  container: {
    marginBottom: theme.spacing.unit * 2
  },
  actionButton: {
    marginRight: theme.spacing.unit
  },
  cardRegistro: {
    // paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2
  },
  cardRow: {
    paddingBottom: theme.spacing.unit * 3
  },
  expansionBody: {
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    flexDirection: 'column',
    padding: '20px 0 !important'
  },
  heading: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary
  },
  headingSubtitle: {
    marginLeft: theme.spacing.unit * 2,
    fontSize: theme.typography.pxToRem(16),
    alignSelf: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: '#CACACA'
  },
  detailsTitle: {
    marginBottom: '20px !important',
    fontWeight: '500 !important'
  },
  summary: {
    borderBottom: '1px solid rgba(12,31,44,0.1)'
  },
  detailDivider: {
    width: '100%',
    margin: '20px 0 !important'
  }
});

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  ),
  { withTheme: true }
)(ExpandCardClient);

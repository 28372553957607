import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import _pick from 'lodash/pick';
import _find from 'lodash/find';

import parseQuerystring from '../../../helpers/parseQuerystring.js';
import combineStyles from '../../../helpers/combineStyles.js';
import SpacingStyles from '../../../styles/helpers/spacing.js';
import { DEFAULT_DEBOUNCE_DELAY } from '../../../constants/request_constants.js';
import TextFieldInput from '../../shared/Inputs/TextField.js';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped.js';
import AutocompleteInput from '../../shared/Inputs/Autocomplete';
import { statusOptions, dateOptions, originalDateOptions } from './dropDownOptions.js';
import { filterListFuzzyly } from '../../../helpers/utilityFunctions.js';
import CollapseWrapper from '../../shared/CollapseWrapper.js';
import { CUSTOM_DATE } from './constants.js';
import PickCustomDateModal from '../../shared/Inputs/PickCustomDateModal.js';

class DocRevisionFilters extends PureComponent {
  state = {
    saleId: '',
    contractId: '',
    supplierId: '',
    supplierName: '',
    status: 'all',
    date: '',
    openPickCustomDate: null,
    customDate: CUSTOM_DATE,
  };

  componentDidMount() {
    const query = parseQuerystring(this.props);
    this.setState({
      ...this.state,
      ...query
    });
  }

  _searchCallback = _debounce(
    (attribute, value) => {
      this.setState({ [attribute]: value, page: 0 }, this._handleChangeFilter);
    },
    DEFAULT_DEBOUNCE_DELAY,
    {
      leading: false,
      trailing: true
    }
  );

  _handleChange = ({ target: { name, value } }) => {
    this._searchCallback(name, value);
  };

  _handleSelectChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value }, this._handleChangeFilter);

  _handleChangeFilter = () =>
    this.props.onChangeFilter(this._getFilterParams());

  _getFilterParams = () => {
    const filterParams = _pick(
      _pickBy(this.state, filter => !!filter && filter != 'all' && filter != 'todas'),
      ['saleId', 'contractId', 'status', 'supplierId', 'date']
    );
    return filterParams;
  };

  _getSuggestions = async (type, value) => {
    const zones = this.props[type].filter(item => item.area_type === 2);

    const items = zones.map(item => ({
      label: item.name || item.description,
      value: item.id
    }));

    const suggestions = filterListFuzzyly(value, items, 'label');

    return Promise.resolve(suggestions);
  };

  getSuggestions = async (type, value) => {
    const items = this.props[type].map(item => ({
      label: item.name || item.description,
      value: item.id
    }));
    const suggestions = filterListFuzzyly(value, items, 'label');
    return Promise.resolve(suggestions);
  };

  capitalizeFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

  _handleDateFilter = ({ target: { name, value } }) => {
    if (value === this.state.customDate) {
      this.setState({ [`openPickCustom${this.capitalizeFirst(name)}`]: name });
    } else {
      this.setState(
        { [name]: value, [`custom${this.capitalizeFirst(name)}`]: CUSTOM_DATE },
        this._handleChangeFilter
      );
    }
  };

  _handleClosePickCustomDate = () => this.setState({ openPickCustomDate: null });

  _handleDateRangeFilter = ({ target: { name, value } }) => {
    const customDate = !_find(
      originalDateOptions,
      availableOption => availableOption.value === value
    )
      ? value
      : CUSTOM_DATE;
    this.setState(
      {
        [name]: value,
        [`openPickCustom${this.capitalizeFirst(name)}`]: null,
        [`custom${this.capitalizeFirst(name)}`]: customDate
      },
      this._handleChangeFilter
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <CollapseWrapper
        filter={true}
        body={
          <Fragment>
            <Grid container spacing={16}>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="saleId"
                  name="saleId"
                  value={this.state.saleId}
                  onChange={this._handleChange}
                  label="# Venta"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextFieldInput
                  id="contractId"
                  name="contractId"
                  value={this.state.contractId}
                  onChange={this._handleChange}
                  label="Contrato"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <OutlinedSelectChippedInput
                  label={'Estado'}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  name={'status'}
                  options={statusOptions}
                  onChange={this._handleSelectChange}
                  inputLabel={'Status'}
                  value={this.state.status}
                  withChip={false}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <AutocompleteInput
                  id="supplier"
                  name="supplierId"
                  label="Proveedor"
                  margin="normal"
                  variant="outlined"
                  error={''}
                  helperText={''}
                  value={this.state.supplierName || ''}
                  onChange={change => {
                    if (this.state.supplierId) {
                      this.setState(
                        { supplierId: '' },
                        this._handleChangeFilter
                      );
                    }
                    this.setState({ supplierName: change });
                  }}
                  getSuggestions={value => this.getSuggestions('suppliers', value)}
                  onSuggestionSelected={supplier => {
                    this.setState(
                      { supplierId: supplier.value },
                      this._handleChangeFilter
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={3}>
                <OutlinedSelectChippedInput
                  label={'Fecha de consulta'}
                  name={'date'}
                  options={dateOptions(this.state.customDate, this.state.date)}
                  value={this.state.date}
                  classes={{
                    root: classes.selectRoot,
                    filterColor: classes.filterColor
                  }}
                  inputLabel={'Consult Date'}
                  onChange={this._handleDateFilter}
                  withChip={false}
                />
              </Grid>
              {!!this.state.openPickCustomDate && (
                <PickCustomDateModal
                  name={this.state.openPickCustomDate}
                  onClose={this._handleClosePickCustomDate}
                  onSave={this._handleDateRangeFilter}
                  value={this.state.date}
                />
              )}
            </Grid>
          </Fragment>
        }
      />
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3
  },
});
DocRevisionFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(combineStyles(styles, SpacingStyles), { withTheme: true })
)(DocRevisionFilters);

import { articlesConstants } from '../constants/articles_constants';
import {
  ARTICLES_SET,
  SAVED_ARTICLE
} from '../constants/action_constants';
const initialState = {
  searches: {},
  entities: {},
  articles: [],
  meta: {},
  articleInfo: null,
  conceptDescriptions: [],
  saved: false
};

export function articles(state = initialState, action) {
  switch (action.type) {
    case ARTICLES_SET:
      return {
        ...state,
        articles: action.data,
        meta: action.meta,
        saved: false,
      };
    case articlesConstants.FETCHED_ARTICLE:
    case articlesConstants.UPDATED_ARTICLE: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.data.id]: action.data
        }
      };
    }

    case articlesConstants.CREATED_ARTICLE: {
      return {
        ...state,
        searches: {},
        entities: {
          ...state.entities,
          [action.data.id]: action.data
        }
      };
    }

    case articlesConstants.LISTED_ARTICLES: {
      let entities = state.entities;

      if (action.data.length > 0) {
        entities = { ...state.entities };

        for (const item of action.data) {
          entities[item.id] = item;
        }
      }

      return {
        ...state,
        searches: {
          ...state.searches,
          [action.search]: {
            expiresAt: action.expiresAt,
            list: action.data.map(item => item.id),
            meta: action.meta
          }
        },
        entities
      };
    }

    case SAVED_ARTICLE:
      return {
        ...state,
        saved: true,
      };

    default: {
      return state;
    }
  }
}

import { SALE_RETURN_STATUS } from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';

export const columns = [
  {
    title: '# Venta',
    name: 'saleId',
    componentType: 'Link'
  },
  {
    title: 'Fecha',
    name: 'date'
  },
  {
    title: '# Contrato',
    name: 'contract'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Creador',
    name: 'creator'
  },
  {
    title: 'Revisor',
    name: 'reviewer'
  },
  {
    title: 'Departamento',
    name: 'department'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      saleId: datum.saleId || '--',
      saleIdLinkTo: `/sales/${datum.saleId}`,
      type:
        datum.saleReturnDetails[0].returnType == 'canceled'
          ? 'Anulación'
          : 'Devolución',
      date:
        moment(datum.saleReturnDetails[0].createdAt)
          .locale('es')
          .format('DD MMM YYYY') || '--',
      status: SALE_RETURN_STATUS[datum.saleReturnDetails[0].status],
      contract: datum.sale.contract.id,
      orderId: datum.orderNumber || 'En proceso',
      creator: _get(
        datum.movements.find(movement => movement.status === 'registered'),
        'user.username',
        '--'
      ),
      reviewer: _get(
        datum.movements.find(movement => movement.status === 'reviewed'),
        'user.username',
        '--'
      ),
      department: _get(
        datum.sale.contract.location.parent,
        'description',
        '--'
      ),
    };
  });

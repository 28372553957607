import { salesRevokeConstants } from '../constants/sales_revoke_constants';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import saleRevokeFilters from '../helpers/sanitizers/saleRevokeFilters';
import { newAlert } from './alert_actions';

const requestServiceNode = new RequestService('node');

//* PURE ACTION FUNCTIONS (This ones call the reducer)
function success(items, pagina) {
  return {
    type: salesRevokeConstants.LIST_SALES_REVOKE_REQUEST,
    data: items,
    meta: pagina
  };
}

// * INDEX *
export const fetchSalesRevoke = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/sales/revoke?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'sale-return-details',
      saleRevokeFilters(params),
      true
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const changeReturnStatus = (
  id,
  body,
  params = {}
) => async dispatch => {
  dispatch(sendRequest('ChangeReturnStatus'));
  try {
    const payload = await requestServiceNode.post(
      `sales/${id}/return-sale-application`,
      body,
    );
    dispatch(newAlert('success', 'success', payload.data.message));
    dispatch(fetchSalesRevoke(params));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
  } finally {
    dispatch(completeRequest('ChangeReturnStatus'));
  }
};

export const returnArticlesSalesMassive = (body = {}) => async dispatch => {
  dispatch(sendRequest('returnArticlesSalesMassive'));
  try {
    const data = new FormData();
    data.append('file', body.file, body.file.name);
    const payload = await requestServiceNode.post(
      'sale-return-articles/massive',
      data,
      {},
      true,
      {},
      false
    );
    dispatch(newAlert('success', 's', payload.data.message));
    return { success: true, payload };
  } catch (errors) {

    if (typeof errors !== 'undefined') {
      const e = await errors.json();
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
      return errors;
    }

    dispatch(
      newAlert(
        'error',
        'ERROR:',
        'Surgió un problema al subir el archivo. Intenté de nuevo.'
      )
    );
  } finally {
    dispatch(completeRequest('returnArticlesSalesMassive'));
  }
};

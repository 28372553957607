import moment from 'moment-timezone';

import { DOC_REVISION_STATUS } from '../../../constants/enums';

export const columns = [
  {
    title: '# Venta',
    name: 'saleId',
    componentType: 'Link'
  },
  {
    title: 'Contrato',
    name: 'contractId'
  },
  {
    title: 'Fecha',
    name: 'date'
  },
  {
    title: 'Estado',
    name: 'status'
  },
  {
    title: 'Proveedor',
    name: 'supplierName'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      saleId: datum.saleId || '--',
      saleIdLinkTo: `/sales/${datum.saleId}`,
      date: moment(datum.registeredAt).subtract(5, 'hours').locale('es').format('DD MMM YYYY') || '--',
      status: DOC_REVISION_STATUS[datum.status],
      contractId: datum.contractId || '--',
      supplierName: datum.supplier.name || '--',
    };
  });

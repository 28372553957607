import moment from 'moment';
import _pickBy from 'lodash/pickBy';

export const scoringSanitizer = (values, solidaryCosigner) => {
  return {
    supplier_id: values.supplierId,
    debtor_expedition_date: moment(values.debtorExpeditionDate, 'YYYY/MM/DD').format(
      'YYYY-MM-DD'
    ),
    cosigner_expedition_date: values.cosignerExpeditionDate
      ? moment(values.cosignerExpeditionDate, 'YYYY/MM/DD').format('YYYY-MM-DD')
      : null,
    cache_params: {
      debtor_identification_type: values.debtorIdentificationType,
      debtor_identification: values.debtorIdentification,
      debtor_contract_number: values.debtorContractNumber,
      debtor_birthdate: values.debtorBirthdate,
      cosigner_identification_type: values.cosignerIdentificationType || null,
      cosigner_identification: values.cosignerIdentification || null,
      cosigner_contract_number: values.cosignerContractNumber || null,
      cosigner_birthdate: values.cosignerBirthdate || null
    },
    cosigner_was_solidary: solidaryCosigner
  };
};

export const scoringConsultsListSanitizer = params => {
  const { date, identification, contractId, page, perPage } = params;

  const dateRange = date ? date.split(' - ') : [];

  const sanitizedParams = {
    page,
    perPage,
    solicitudeDateStart: dateRange[0] || date,
    solicitudeDateEnd: dateRange[1] || date,
    identification: identification,
    contractId: contractId
  };

  return _pickBy(sanitizedParams, filter => !!filter);
};

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import QueryScoringQuota from './QueryMegaQuota';
import { newAlert } from '../../../actions/alert_actions';
import {
  queryMegaQuotas,
  closeQueryMegaQuotaModal,
  resetQueryMegaQuotaData
} from '../../../actions/query_mega_quota_actions';

function mapStateToProps(state) {
  return {
    loading: state.requestState.requestQueryMegaQuotasSend,
    serverErrors: state.requestState.requestErrorsOnQueryMegaQuotas,
    cosignerIsRequired: state.queryMegaQuotas.cosignerIsRequired,
    quotas: state.queryMegaQuotas.megaQuotas,
    scoringQuota: state.queryMegaQuotas.scoringQuota,
    isScoringUsed: state.queryMegaQuotas.isScoringUsed,
    usedQuota: state.queryMegaQuotas.usedQuota,
    availableQuota: state.queryMegaQuotas.availableQuota,
    isOpen: state.queryMegaQuotas.isQueryMegaQuotaModalOpen,
    hasSearched: state.queryMegaQuotas.hasSearched,
    categoriesUsedToScoringMaximumValue: state.globalSetting.orgData.categories_to_use_scoring_extra_quota_maximun_value,
    megaquotaAllowedCategoryIds: state.globalSetting.orgData.mega_quota_allowed_category_ids,
    timeForWaitingResponseInMegacupoConsult: state.globalSetting.orgData.time_for_waiting_response_in_megacupo_consult,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newAlert: bindActionCreators(newAlert, dispatch),
    queryMegaQuotas: bindActionCreators(queryMegaQuotas, dispatch),
    closeQueryMegaQuotaModal: bindActionCreators(closeQueryMegaQuotaModal, dispatch),
    resetQueryMegaQuotaData: bindActionCreators(resetQueryMegaQuotaData, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QueryScoringQuota);

import _pickBy from 'lodash/pickBy';

const getValue = setting => {
  const { settingType, value } = setting;

  if (settingType === 'boolean') {
    if (value === '1') {
      return 'Verdadero';
    }
    return 'Falso';
  }

  if (!value) {
    return '--';
  }

  if (value.includes('[{')) {
    return '-- Ver en Consola Administrativa';
  }

  return value;
};

export const getGlobalSettings = ({ data }) => {
  return data.map(setting => ({
    ...setting,
    value: getValue(setting),
    tags: setting.tags.map(({ code }) => code)
  }));
};

export const saveGlobalSetting = params => {
  const { name, description, value, settingType } = params;
  const sanitizedFilters = {
    name,
    description,
    value
  };

  if (settingType == 'boolean') {
    sanitizedFilters.value = sanitizedFilters.value == 'Verdadero' ? '1' : '0';
  }

  return _pickBy(sanitizedFilters, filter => !!filter || filter === false);
};

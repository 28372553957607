import React, { PureComponent, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import StepperButtons from '../../shared/StepperButtons';
import SimpleSelect from '../../shared/Selects/SimpleSelect';
import OurDatePicker from '../../shared/CustomDatePicker';
import ActivityIndicator from '../../shared/ActivityIndicator';
import Camera from '../../shared/Camera';
import mapListToOptions from '../../../helpers/mapListToOptions';
import { doctypes } from '../../../constants/enums';
import SegmentHeader from '../../shared/SegmentHeader';

import { Formik } from 'formik';
import {
  validationSchema,
  validationSchemaOTP
} from './DigitalSaleIdentityValidationSchema';

class DigitalSaleIdentityValidation extends PureComponent {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;
  constructor(props) {
    super(props);

    this.setCameraDetections = this.setCameraDetections.bind(this);
  }

  setCameraDetections(detections, faceSmiling = null) {
    let newState = { detections: detections };
    if (faceSmiling) {
      newState = { ...newState, faceSmiling: faceSmiling };
    }
    this.setState(newState);
  }
  state = {
    checked: true,
    debtorCode: '',
    debtorDoctype: 'Cédula',
    debtorDocid: '',
    contract: '',
    birthday: null,
    digitalSaleWithPUI: false,
    detections: null,
    disabledNext: true,
    captureValid: false,
    faceSmiling: false
  };

  webcamRef = React.createRef();

  componentDidMount = async () => {
    this.props.setCurrentStep(this.currentStep);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.baseErrorOpen !== this.props.baseErrorOpen &&
      !this.props.baseErrorOpen
    ) {
      if (!this.props.debtorIsOnFacialDb) {
        this.formik.resetForm(this.state);
        this.setState({ captureValid: false, faceSmiling: false });
      }
    }
    if (!this.state.captureValid && this.state.faceSmiling) {
      this.setState({ captureValid: true });
    }
    if (this.state.detections) {
      if (this.state.detections.length === 1) {
        this.toggleDisabledNext(false);
      } else {
        this.toggleDisabledNext(true);
      }
    } else {
      this.toggleDisabledNext(true);
    }
  }

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisabledNext(endState) {
    if (this.state.disabledNext !== endState) {
      this.setState({ disabledNext: endState });
    }
  }

  onFormSubmit = values => {
    const { debtorDoctype, debtorDocid, birthday, contract } = values;
    const params = {
      debtor_contract_number: contract,
      debtor_identification_type: debtorDoctype,
      debtor_identification: debtorDocid,
      debtor_birthdate: birthday,
      make_gnp_sale: false
    };
    this.props.locationValidityCheck(params, this.props.process).then(canSell => canSell && this.props.digitalSaleSetDebtorIdentification(params, this.props.process));
  };


  onFormSubmitOTP = () => {};

  _onKeyPress = event => {
    event.key === 'Enter' && event.preventDefault();
  };

  render() {
    const {
      classes,
      debtorIdentification,
      debtorIdentificationType,
      contract,
      debtorIsOnFacialDb,
      serverErrors = {},
      stepIsLoading,
      isBlocked = false,
      isBlackListActive
    } = this.props;

    const capture = () => {
      if (this.state.disabledNext || !this.state.captureValid) {
        return;
      }
    };

    const silentCapture = imageSrc => {};
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          {debtorIsOnFacialDb ? (
            <Fragment>
              <div className={classes.toggle}>
                <p className={classes.toggleCode}>Código</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.checked}
                      onChange={this.toggleChecked}
                    />
                  }
                  label=""
                />
                <p className={classes.toggleCamara}>Cámara</p>
              </div>
              {this.state.checked ? (
                <Fragment>
                  <Camera
                    captureValid={this.state.captureValid}
                    detections={this.state.detections}
                    disabledNext={this.state.disabledNext}
                    setCameraDetections={this.setCameraDetections}
                    webcamRef={this.webcamRef}
                    capture={capture}
                    silentCapture={silentCapture}
                    capturingDocument={false}
                    extension={'jpeg'}
                  />
                  {stepIsLoading && (
                    <ActivityIndicator className={classes.loadingIndicator} />
                  )}
                  <StepperButtons
                    onSubmit={capture}
                    goBack={this.handleBack}
                    nextText="Capturar"
                    disabledNext={
                      this.state.disabledNext || !this.state.captureValid
                    }
                  />
                </Fragment>
              ) : (
                <Formik
                  ref={ref => (this.formik = ref)}
                  enableReinitialize={true}
                  initialValues={this.state}
                  validationSchema={validationSchemaOTP}
                  onSubmit={this.onFormSubmitOTP}
                  render={({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange
                  }) => (
                    <form autoComplete="off" className={classes.form}>
                      <SegmentHeader className={classes.segmentHeader}>
                        INGRESE EL CÓDIGO DE ACCESO GENERADO POR LA APLICACIÓN
                      </SegmentHeader>
                      <Grid container spacing={24} alignItems="flex-start">
                        <Grid item sm={6} xs={12}>
                          <OutlinedTextField
                            onKeyPress={this._onKeyPress}
                            type={'number'}
                            label="Código de acceso:"
                            name="debtorCode"
                            error={
                              (touched.debtorCode && !!errors.debtorCode) ||
                              (serverErrors && !!serverErrors.debtor)
                            }
                            helperText={
                              (touched.debtorCode && errors.debtorCode) ||
                              (serverErrors && serverErrors.debtor)
                            }
                            value={values.debtorCode}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div className={classes.spacer} />
                      {stepIsLoading && (
                        <ActivityIndicator
                          className={classes.loadingIndicator}
                        />
                      )}
                      <StepperButtons
                        onSubmit={handleSubmit}
                        goBack={this.props.goBack}
                        nextText="Siguiente"
                        disabledNext={isBlocked && isBlackListActive}
                      />
                    </form>
                  )}
                />
              )}
            </Fragment>
          ) : (
            <Formik
              ref={ref => (this.formik = ref)}
              enableReinitialize={true}
              initialValues={this.state}
              validationSchema={validationSchema}
              onSubmit={this.onFormSubmit}
              render={({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleChange
              }) => (
                <form autoComplete="off" className={classes.form}>
                  <Fragment>
                    <SegmentHeader className={classes.segmentHeader}>
                      INFORMACIÓN DEUDOR
                    </SegmentHeader>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item sm={6} xs={12}>
                        <OutlinedTextField
                          label="Numero de contrato:"
                          name="contract"
                          type={'number'}
                          error={
                            (touched.contract && !!errors.contract) ||
                            (serverErrors && !!serverErrors.contract)
                          }
                          helperText={
                            (touched.contract && errors.contract) ||
                            (serverErrors && serverErrors.contract)
                          }
                          value={values.contract}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <SimpleSelect
                          value={values.debtorDoctype}
                          onChange={handleChange}
                          name="debtorDoctype"
                          label="Tipo de identificacion del deudor:"
                          options={mapListToOptions(doctypes, {}, true)}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <OutlinedTextField
                          onKeyPress={this._onKeyPress}
                          label="Identificacion del deudor:"
                          name="debtorDocid"
                          error={
                            (touched.debtorDocid && !!errors.debtorDocid) ||
                            (serverErrors && !!serverErrors.debtor)
                          }
                          helperText={
                            (touched.debtorDocid && errors.debtorDocid) ||
                            (serverErrors && serverErrors.debtor)
                          }
                          value={values.debtorDocid}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <OurDatePicker
                          name={'birthday'}
                          invalidLabel="Fecha inválida"
                          maxDateMessage="Fecha inválida"
                          minDateMessage="Fecha inválida"
                          invalidDateMessage={'Fecha inválida'}
                          label="Fecha nacimiento deudor:"
                          value={values.birthday}
                          onChange={e => {
                            setFieldValue('birthday', e);
                          }}
                          onError={() => setFieldValue('birthday', null)}
                          error={
                            (touched.birthday && !!errors.birthday) ||
                            (serverErrors && serverErrors.debtor_birthdate)
                          }
                          helperText={
                            (touched.birthday && errors.birthday) ||
                            (serverErrors && serverErrors.debtor_birthdate)
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.spacer} />
                    <StepperButtons
                      onSubmit={handleSubmit}
                      disabledNext={isBlocked && isBlackListActive}
                    />
                  </Fragment>
                </form>
              )}
            />
          )}
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    flexGrow: 1,
    position: 'relative'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  spacer: {
    flex: 1,
    minHeight: '1px',
    minWidth: '1px'
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 2 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  segmentHeader: {
    width: '100%',
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  loadingIndicator: {
    position: 'fixed',
    background: 'rgba(255,255,255,0.8)',
    left: 0,
    width: '100%',
    top: 0,
    height: '100%',
    zIndex: 2
  },
  toggle: {
    // position: 'absolute',
    // right: '25px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '14px',
    height: '36px',
    zIndex: 1,
    ['@media (max-width:500px)']: {
      position: 'relative',
      right: 0
    }
  },
  toggleCode: {
    marginRight: '14px',
    fontSize: '0.875rem'
  },
  toggleCamara: {
    marginLeft: '-16px',
    fontSize: '0.875rem'
  }
});

DigitalSaleIdentityValidation.propTypes = {
  classes: PropTypes.object.isRequired,
  newAlert: PropTypes.func.isRequired
};

export default withStyles(styles)(DigitalSaleIdentityValidation);

import _pickBy from 'lodash/pickBy';

const globalSettingFilters = filters => {
  const { settingName, description, page, perPage, tag } = filters;

  const sanitizedFilters = {
    name: settingName,
    description,
    tagName: tag,
    page,
    perPage
  };

  return _pickBy(sanitizedFilters, filter => !!filter);
};

export default globalSettingFilters;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManualQuotaCreateEdit from './ManualQuotaCreateEdit';
import {
  manualQuotaCreate,
  createMassiveManualQuota
} from '../../../actions/manual_quota_actions';
import {
  fetchContract,
  clearContract
} from '../../../actions/contract_actions';
function mapStateToProps(state) {
  return {
    isEditing: state.manualQuota.isEditing,
    requestSend: state.requestState['requestCreate-Manual-QuotaSend'],
    requestSendMassive: state.requestState['requestCreate-Manual-QuotasSend'],
    requestSendContract: state.requestState['requestContract-DetailSend'],
    contractDetail: state.contract.contractDetail,
    massiveManualQuotaActive:
      state.globalSetting.orgData.massive_manual_quota_active,
    canCreateMassiveManualQuota:
      state.authentication.currentUser.canCreateMassiveManualQuota,
    manualQuotaCauses: state.globalSetting.orgData.manual_quota_causes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    manualQuotaCreate: bindActionCreators(manualQuotaCreate, dispatch),
    createMassiveManualQuota: bindActionCreators(
      createMassiveManualQuota,
      dispatch
    ),
    fetchContract: bindActionCreators(fetchContract, dispatch),
    clearContract: bindActionCreators(clearContract, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualQuotaCreateEdit);

import { LIST_DOC_REVISIONS_REQUEST } from '../constants/doc_revision_constants';

const initialState = {
  docRevisions: [],
  meta: { nextPage: false }
};

export function docRevisions(state = initialState, action) {
  switch (action.type) {
    case LIST_DOC_REVISIONS_REQUEST:
      return {
        ...state,
        docRevisions: action.data,
        meta: action.meta
      };
    default:
      return state;
  }
}

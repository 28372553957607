import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, TextField } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';

import CustomDialog from '../../../shared/Modals/CustomDialog';
import Movements from '../../../shared/Movements/Movements';

import { _moneyFormat } from '../../../../helpers/utilityFunctions';
import { statusOptions } from '../dropDownOptions';
import { DOC_REVISION_STATUS } from '../../../../constants/enums';

class DocRevisionForm extends Component {
  state = { comment: '' };

  handleApprove = () => {
    this.props.onApprove({ id: this.props.documentalRevision.id, comment: this.state.comment });
  };

  handleRevoke = () => {
    this.props.onRevoke({ id: this.props.documentalRevision.id, comment: this.state.comment });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getStatus = () => {
    return statusOptions.find(status => status.title === this.props.documentalRevision.status).value;
  };

  getActionText = () => {
    const { canApproveDocRevisions } = this.props;
    const type = this.getStatus();

    switch (type) {
      case 'registered':
        if (canApproveDocRevisions) return 'Aprobar';
        return null;
      default:
        return null;
    }
  };

  getRevokeActionText = () => {
    const { canRevokeDocRevisions } = this.props;
    const type = this.getStatus();

    switch (type) {
      case 'registered':
        if (canRevokeDocRevisions) return 'Rechazar';
        return null;
      default:
        return null;
    }
  };

  getDescription = status => {
    switch (status) {
      case 'registered':
        return 'Solicitud';
      case 'revoked':
        return 'Rechazo';
      case 'approved':
        return 'Aprobación';
      default:
        return '';
    }
  };

  getLastRegisteredOnwards(movements) {
    const lastZeroIndex = movements.findLastIndex(m => m.status === 'registered');
    return lastZeroIndex !== -1 ? movements.slice(lastZeroIndex) : [];
  }

  render() {
    const {
      documentalRevision,
      classes,
      open = true,
      isSaving,
      isRevoking,
      onClose,
      canRevokeDocRevisions,
      canApproveDocRevisions
    } = this.props;

    const { comment } = this.state;
    const movements = documentalRevision.movements;
    const canUpdateRevision = canApproveDocRevisions || canRevokeDocRevisions;

    return (
      <div>
        <CustomDialog
          open={open}
          loading={isSaving}
          loadingSecondary={isRevoking}
          disabled={!canUpdateRevision}
          disableBackdropClick={false}
          onClose={onClose}
          onConfirm={this.handleApprove}
          title="Solicitud de revisión documental"
          contentText={''}
          buttonText={this.getActionText()}
          buttonSecondaryText={this.getRevokeActionText()}
          onConfirmSecondary={this.handleRevoke}
          disabledSecondary={_isEmpty(comment)}
          classes={{ root: classes.dialogRoot }}
        >
          <Grid container spacing={16}>
            {movements && <Movements movements={this.getLastRegisteredOnwards(movements)} disables={['reviewed']} />}

            <div className={classes.textContainer}>
              <div className={classes.commentsContainer}>
                {movements.map(mov => {
                  const movComment = mov.comment || mov.comments[0] ? mov.comments[0].comment : '';

                  return (
                    <p className={classes.titleContainer} key={mov.id}>
                      {`${this.getDescription(mov.status)}: `}
                      <a className={classes.subText}>{movComment}</a>
                    </p>
                  );
                })}
              </div>
            </div>
            {canUpdateRevision && documentalRevision.status === DOC_REVISION_STATUS[0] && (
              <Grid item xs={12}>
                <TextField
                  label="Comentarios*"
                  name="comment"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  value={comment}
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  flashMessage: {
    marginTop: theme.spacing.unit / 1.5,
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  subtitleContainer: {
    color: '#DB4945 !important',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    marginLeft: theme.spacing.unit * 20
  },
  commentsContainer: {
    marginTop: theme.spacing.unit * 1
  },
  textContainer: {
    display: 'block'
  },
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingRight: '81px',
    wordBreak: 'break-all'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    overflowWrap: 'anywhere'
  },
  comments: {
    marginTop: theme.spacing.unit * 2
  }
});

DocRevisionForm.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(DocRevisionForm);

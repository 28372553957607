export const SET_SCORING_CONFIGURATION_LIST_DATA =
  'SET_SCORING_CONFIGURATION_LIST_DATA';

export const CREATE_SCORING_CONFIGURATION = 'CREATE_SCORING_CONFIGURATION';
export const UPDATE_SCORING_CONFIGURATION = 'UPDATE_SCORING_CONFIGURATION';
export const DELETE_SCORING_CONFIGURATION = 'DELETE_SCORING_CONFIGURATION';

export const OPEN_SCORING_CONFIGURATION_FORM_MODAL =
  'OPEN_SCORING_CONFIGURATION_FORM_MODAL';
export const CLOSE_SCORING_CONFIGURATION_FORM_MODAL =
  'CLOSE_SCORING_CONFIGURATION_FORM_MODAL';

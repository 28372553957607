import {
  PORTAL_FINANCING_CLEAR,
  PORTAL_FINANCING_PERSIST,
  PORTAL_FINANCING_VALIDATED_INFORMATION,
  STEP_LOADING,
  STEP_SUCCESS,
  STEP_ERROR,
  TOGGLE_ID_MODAL,
  TOGGLE_BASE_MODAL,
  RESET_BASE_MODAL,
  DC_DEBTOR_ERROR,
  DC_COSIGNER_ERROR,
  RESET_COSIGNER_DATA,
  HANDLE_ARTICLES,
  SET_NEXT_STEP,
  CHANGE_AV_QUOTA,
  EXTRA_QUOTAS_SET,
  RESET_ADD_QUOTAS,
  RESET_MOVE_QUOTA,
  RESET_PROMISSORY_DATA,
  TOGGLE_SCORING_MODAL,
  SET_SCORING_QUOTA,
  RESET_SCORING,
  RESTORE_AV_QUOTA,
  SET_APPLY_SCORING,
  SET_QUESTIONS_INVOICE,
  INVOICES_VALID,
  SET_PROCESS,
  UPDATE_STEPPER_ROUTES,
  SET_DEBTOR_IDENTIFICATION,
  RESET_DEBTOR_IS_ON_FACIAL_DB,
  SET_DEBTOR_BIRTHDATE,
  SET_DEBTOR_MOBILE,
  SET_INVOICE_VAL_STEP_SKIPPED,
  SET_ORIGINAL_PROCESS,
  RESET_IDENTITY_OTP_CODE,
  SET_MEGA_QUOTA,
  RESET_MEGA_QUOTA,
  OTP_SEND_TRANSACTION_ID,
  OTP_RE_SEND_TRANSACTION_ID,
} from '../constants/action_constants';
import { handleRequestError } from './session_actions';
import RequestService from '../services/request_service';
import axios from 'axios';
import AxiosRequest from '../services/axios_request';
import AxiosNodeRequest from '../services/axios_node_request';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import { newAlert, serverStatusError } from './alert_actions';
import { setOptionalSteps } from './stepper_actions';
import { history } from '../helpers/history';
import { objectToString } from '../helpers/mappings/map';
import snakecaseKeys from 'snakecase-keys';
import {
  identificationSanitizer,
  debtorIdentificationSanitizer,
  cosignerIdentificationSanitizer
} from '../helpers/sanitizers/identificationSanitizer';
import _get from 'lodash/get';
import {
  addQuotasSanitizer,
  moveQuotaSanitizer,
  addQuotasSetSanitizer,
  moveQuotaSetSanitizer,
  addAvailableQuotas,
  transferErrorsSanitizer
} from '../helpers/sanitizers/quotaTransferSanitizer';
import { fetchSecureCreditInstalmentsPercentages } from './secure_credit_instalments_percentage_actions';
import { validateOtpSanitizer } from '../helpers/sanitizers/otpSanitizer';
import scoringInRegisterSaleFilterSanitizer from '../helpers/sanitizers/scoringInRegisterSaleSanitizer';

const CACHE_KEY = 'portalFinancing';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();
const axiosNodeRequest = new AxiosNodeRequest();


import camelcaseKeys from 'camelcase-keys';
import {
  checkInvoiceSanitizer,
  questionsInvoiceSanitizer,
  verifyInvoiceSanitizer
} from '../helpers/sanitizers/invoiceSanitizer';
import { OTPCodes } from '../constants/identity-otp-constants';
import { doctypes } from '../constants/enums';
import { clearUrl, setUrl, setUrlFromBlob } from './file_actions';
import { RESET_OTP_V2_INFO_RESPONSE } from '../constants/otp_validation_constants';
import MIMETYPES from '../constants/mimetypes';
import { previewPromissorySanitizer, previewVoucherSanitizer } from '../helpers/sanitizers/previewDocumentSanitizer';
import { Cases, convertCase } from '../helpers/utilityFunctions';

const getDocTypeValue = debtorIdentificationType => {
  return doctypes.find(type => type.id === debtorIdentificationType || type.name === debtorIdentificationType).value;
};

export const persistFinancing = () => dispatch => {
  const cache = sessionStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    dispatch({ type: PORTAL_FINANCING_PERSIST, data });
  }
};

export const cacheFinancing = state => {
  const { portalFinancing, stepper } = state;
  sessionStorage.setItem(CACHE_KEY, JSON.stringify(portalFinancing));
  sessionStorage.setItem('stepper', JSON.stringify(stepper));
};

export const validateInformation = data => (dispatch, getState) => {
  dispatch({ type: PORTAL_FINANCING_VALIDATED_INFORMATION, data });
  cacheFinancing(getState());
};

export const clearFinancing = () => dispatch => {
  sessionStorage.removeItem(CACHE_KEY);
  sessionStorage.removeItem('stepper');
  dispatch({ type: PORTAL_FINANCING_CLEAR });
};

//PORTAL REGISTER FINANCING PAGE

function stepLoading() {
  return {
    type: STEP_LOADING
  };
}

export function resetAddQuotasData() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_ADD_QUOTAS
    });
    dispatch(errorOnRequest('FetchTransferQuotas', {}));
    cacheFinancing(getState());
  };
}

export function resetMoveQuotaData() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_MOVE_QUOTA
    });
    dispatch(errorOnRequest('FetchTransferQuotas', {}));
    cacheFinancing(getState());
  };
}

export const restoreAvailableQuota = () => {
  return (dispatch, getState) => {
    dispatch(sendRequest('ChangeAvailableQuota'));
    dispatch({
      type: RESTORE_AV_QUOTA
    });
    cacheFinancing(getState());
    setTimeout(() => dispatch(completeRequest('ChangeAvailableQuota')), 2000);
  };
};

export function toggleIDModal(active) {
  return {
    type: TOGGLE_ID_MODAL,
    active
  };
}

export function toggleScoringModal(active) {
  return {
    type: TOGGLE_SCORING_MODAL,
    active
  };
}

function setDebtorIdentification(
  identification,
  identificationType,
  debtorIsOnFacialDb,
  digitalSaleWithPUI
) {
  return dispatch => {
    dispatch({
      type: SET_DEBTOR_IDENTIFICATION,
      identification,
      identificationType,
      debtorIsOnFacialDb,
      digitalSaleWithPUI
    });
  };
}

function resetDebtorIsOnFacialDb() {
  return dispatch => {
    dispatch({
      type: RESET_DEBTOR_IS_ON_FACIAL_DB
    });
  };
}

function setDebtorBirthdate(debtorBirthdate) {
  return dispatch => {
    dispatch({
      type: SET_DEBTOR_BIRTHDATE,
      debtorBirthdate
    });
  };
}

function setDebtorMobile(debtorMobile) {
  return dispatch => {
    dispatch({
      type: SET_DEBTOR_MOBILE,
      debtorMobile
    });
  };
}

export function stepSuccess(data) {
  return (dispatch, getState) => {
    dispatch({
      type: STEP_SUCCESS,
      data
    });
    cacheFinancing(getState());
  };
}

export function resetOtpCode(data) {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_IDENTITY_OTP_CODE,
      data
    });
  };
}

export function setQuestionsInvoice(data) {
  return {
    type: SET_QUESTIONS_INVOICE,
    data
  };
}

export function setInvoicesValid(data) {
  return {
    type: INVOICES_VALID,
    data
  };
}

export function setInvoiceValidationSkipped(invoiceValidationSkipped) {
  return {
    type: SET_INVOICE_VAL_STEP_SKIPPED,
    invoiceValidationSkipped
  };
}

export function setNextStep(process, nextStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_NEXT_STEP,
      process,
      nextStep,
      nextStepTitle: process
    });
    cacheFinancing(getState());
  };
}

function stepError(errors) {
  return {
    type: STEP_ERROR,
    errors
  };
}

export function toggleBaseErrorModal(open, text, title, route) {
  return {
    type: TOGGLE_BASE_MODAL,
    open,
    text,
    title,
    route
  };
}

export function resetBaseErrorModal() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_BASE_MODAL
    });
    cacheFinancing(getState());
  };
}

function dcDebtorError(errors) {
  return {
    type: DC_DEBTOR_ERROR,
    errors
  };
}

function dcCosignerError(errors) {
  return {
    type: DC_COSIGNER_ERROR,
    errors
  };
}

function setOriginalProcess(process) {
  return {
    type: SET_ORIGINAL_PROCESS,
    process
  };
}

export function resetCosignerData(params, isSecureSale) {
  return dispatch => {
    dispatch({
      type: RESET_COSIGNER_DATA
    });
    return requestServiceNode.post('register-sale/skipCosigner', params)
      .then(() => {
        if (isSecureSale) {
          dispatch(toggleBaseErrorModal(true, 'Se ha omitido el codeudor. A partir de este momento se realizará la venta con fianza.'));
          dispatch(stepSuccess({ isSecureSale }));
          dispatch(fetchSecureCreditInstalmentsPercentages({ page: 1, perPage: 50 }, false));
        }
      })
      .catch(async error => {
        const e = await error.json();
        dispatch(stepError(e.errors));
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base
            )
          );
      });
  };
}

export function checkSecureSaleExperian(params) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode.post('register-sale/secureSaleScoreCheck', params)
      .then(response => {
        dispatch(stepSuccess({}));
        if (response.data.valid) {
          return response.data;
        }
      })
      .catch(async error => {
        const e = await error.json();
        dispatch(stepError(e.errors));
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base
            )
          );
      });
  };
}

export function handleArticles(data, route) {
  return (dispatch, getState) => {
    dispatch({
      type: HANDLE_ARTICLES,
      data
    });
    cacheFinancing(getState());
    history.push(route);
  };
}

export function checkFirstStepRegisterSale(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('register-sale/identification', { ...params, process }, null)
      .then(
        payload => {
          const data = snakecaseKeys(payload.data);
          if (process === 'remoteDigitalSaleWithUUP' && data.is_invalid_age) {
            dispatch(stepError());
            dispatch(
              toggleBaseErrorModal(
                true,
                'Esta venta está disponible solo para titulares o no titulares menores a ' + data.max_age + ' años',
                ''
              )
            );
          } else {
            !data.require_cosigner && setOptionalSteps([2]);
            if (data.valid) {
              dispatch(setNextStep(process));
              const dataParams = snakecaseKeys(params);
              dispatch(stepSuccess({ ...data, ...dataParams }));
              history.push(route);
            }
          }
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

export function digitalSaleSetDebtorIdentification(params = {}, process) {
  const dataParams = camelcaseKeys(params);
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('register-sale/identification', { ...dataParams, process }, null)
      .then(
        payload => {
          const customPayload = snakecaseKeys(payload);
          if (
            process === 'digitalSaleWithUUP' &&
            customPayload.data.require_cosigner
          ) {
            dispatch(stepError());
            dispatch(
              toggleBaseErrorModal(
                true,
                'Esta venta está disponible solo para titulares que por políticas no requieran codeudor',
                ''
              )
            );
          } else {
            !customPayload.data.require_cosigner && setOptionalSteps([2]);
            if (customPayload.data.valid) {
              dispatch(stepSuccess({ ...customPayload.data, ...params }));
              const digitalData = customPayload.data.digital_sale_data;
              if (digitalData.registered) {
                dispatch(
                  setDebtorIdentification(
                    digitalData.identification,
                    digitalData.type_of_identification,
                    true,
                    digitalData.digital_sale_with_pui
                  )
                );
                dispatch(stepSuccess({ ...digitalData, ...params }));
              } else {
                const process = 'digitalSaleWithouFaceId';
                dispatch(
                  setDebtorIdentification(
                    digitalData.identification,
                    digitalData.type_of_identification,
                    false,
                    digitalData.digital_sale_with_pui
                  )
                );
                dispatch(stepSuccess({ ...digitalData, ...params }));
                dispatch(setNextStep(process));
                history.push('/digital-sale/register-identity');
              }
            }
          }
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

export function initiateOlimpiaV2Process(promissoryId) {
  return dispatch => {
    return requestServiceNode.post(`promissories/initiateOlimpiaV2Process/${promissoryId}`, {}, null).then(
      payload => {
        dispatch(stepSuccess({ externalProcessId: payload.data.external_process_id }));
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        error.errors &&
          error.errors.base &&
          dispatch(toggleBaseErrorModal(true, error.errors.base[0]));
    });
  };
}

function calculateAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function checkDigitalSaleSelectContract(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestService
      .post('contracts/digital_sale_identification', params, null)
      .then(
        payload => {
          if (payload.data.valid) {
            if (payload.data.is_unmaterialized) {
              dispatch(setNextStep(process));
              dispatch(stepSuccess({ ...payload.data, ...params }));
              history.push(route);
            } else {
              const process = 'digitalSaleWithoutUUPonContract';
              dispatch(stepSuccess({ ...payload.data, ...params }));
              dispatch(setNextStep(process));
              history.push('/digital-sale/unmaterialized-promissory');
            }
          } else {
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
          }
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export function registerDigitalSalePromissory(
  params = {},
  contract,
  route,
  process
) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(
        `promissories/${contract}/createUnmaterialized`,
        convertCase({ ...params.tempPromissory, process }, Cases.camelCase),
        null
      )
      .then(
        payload => {
          dispatch(setNextStep(process));
          dispatch(stepSuccess({
            ...payload.data,
            ...params,
            promissory_id: payload.data.promissoryId,
            unmaterialized_promissory_process_id: payload.data.unmaterializedProcessId,
            unmaterialized_promissory_url: {
              debtor: payload.data.debtorSignUrl,
              cosigner: payload.data.cosignerSignUrl
            },
            promissory_pdf: null,
            isPromissoriesV2: null,
            otpV2TransaccionId: null,
            externalProcessId: null,
            external_process_id: null,
            otpValidatorDebtor: null,
            otpValidatorCosigner: null
          }));
          history.push(route);
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
            });
        }
      );
  };
}

export function digitalSaleVoucherStep(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    const formData = new FormData();
    formData.append("saleId", params.saleId);
    formData.append("isExternal", params.isExternal);
    params.files.forEach(file => formData.append("files", file, file.name));

    return requestServiceNode
      .post(`sales/add-voucher-captures`, formData, {}, true, {}, false)
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(
              stepSuccess({
                ...payload.data
              })
            );
            history.replace(`/sales/${params.saleId}`);
          } else {
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(newAlert('error', 'ERROR:', payload.errors.base[0]));
            });
          }
        })
        .catch(async error => {
          const e = await error.json();
          const errorMessage = e.errors.b2b || e.errors.base;
          dispatch(stepError(e.errors));
          e.errors &&
            dispatch(newAlert('error', 'ERROR:', errorMessage));
        });
  };
}

export function resendOlimpiaOTP(contract, params) {
  return dispatch => {
    dispatch(stepLoading());
    return requestService
      .post(`promissories/${contract}/resend_olimpia_otp`, params, null)
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(newAlert('success', 'success', 'OTP reenviado exitosamente.'));
            dispatch(stepSuccess({ ...payload.data }));
          } else {
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
          }
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export function checkDigitalSaleSignedUnmaterializedPromissory(
  params = {},
  contract,
  route,
  process
) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(
        `promissories/${contract}/checkUnmaterializedPromissoryStatus`,
        { ...params, process },
        null
      )
      .then(
        payload => {
          if (process === 'remoteDigitalSaleWithoutUUP') {
            process = 'remoteDigitalSaleWithUUP';
          } else {
            process = 'digitalSaleWithUUP';
          }
          dispatch(setNextStep(process));
          dispatch(setDebtorBirthdate(params.debtor.date_of_birth));
          dispatch(stepSuccess({
            ...convertCase(payload.data, Cases.snakeCase),
            otpValidatorDebtor: null,
            otpValidatorCosigner: null,
            otpV2TransaccionId: null,
          }));
          history.push(route);
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
            });
        }
      );
  };
}

export function checkDigitalSaleSignedUnmaterializedVoucher(
  params = {},
  contract,
  route,
  process
) {
  return dispatch => {
    dispatch(stepLoading());
    return requestService
      .post(
        `sales/${contract}/check_unmaterialized_voucher_status`,
        { ...params, process },
        null
      )
      .then(
        payload => {
          if (payload.data.id) {
            dispatch(setNextStep(process));
            dispatch(
              stepSuccess({
                promissoryDetails: params,
                saleNumber: payload.data.id,
                saleCompleted: true
              })
            );
            history.push(route);
          } else {
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
          }
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export function updateContactData(params = {}, contract, route, process) {
  return dispatch => {
    dispatch(sendRequest('VoucherV2Url'));
    dispatch(clearUrl('voucherV2'));
    dispatch(stepLoading());
    return requestService
      .post(
        `promissories/${contract}/contact_data`,
        { ...params, process },
        null
      )
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(setNextStep(process));
            dispatch(setDebtorMobile(payload.data.mobile));
            dispatch(stepSuccess({...payload.data, base64: null}));
            history.push(route);
          } else {
            dispatch(clearUrl('voucherV2'));
            const _error = payload.data;
            _error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
          }
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export const checkFirstStepRegisterPromissory = (
  params = {},
  route,
  process
) => async dispatch => {
  dispatch(stepLoading());
  try {
    const payload = await axiosRequest.post(
      'register_promissory/identification',
      params
    );
    !payload.require_cosigner && setOptionalSteps([2]);
    if (payload.data.valid) {
      dispatch(setNextStep(process));
      dispatch(stepSuccess({ ...payload.data, ...params }));
      history.push(route);
    }
  } catch (errors) {
    dispatch(stepError(errors.errors));
    _get(errors, 'errors.base') &&
      dispatch(toggleBaseErrorModal(true, errors.errors.base[0], ''));
  }
};

export function checkSecondStep(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(`register-sale/contractInformation`, params)
      .then(payload => {
        const data = snakecaseKeys(payload.data);
        if (
          params.process === 'remoteDigitalSaleWithUUP' &&
          (!data.promissory || !data.promissory.unmaterialized)
        ) {
          params.process = 'remoteDigitalSaleWithoutUUP';
        }
        dispatch(
          stepSuccess({
            ...data,
            unmaterialized_promissory_process_id: data.promissory ? data.promissory.unmaterializedProcessId : null,
            available_quota_backup: data.available_quota
          })
        );
        dispatch(setNextStep(params.process));
        return payload;
      })
      .catch(error => {
        error.json().then(data => {
          dispatch(stepError(data.errors));
          _get(data, 'errors.base') &&
            dispatch(
              toggleBaseErrorModal(
                true,
                data.errors.base,
                'Información del contrato'
              )
            );
        });
      });
  };
}

export const fetchTransferQuotas = (
  params = {},
  debtor_cache = {},
  type
) => async dispatch => {
  dispatch(sendRequest('FetchTransferQuotas'));
  try {
    if (type === 'union') {
      const customParams = camelcaseKeys(
        addQuotasSanitizer(params, debtor_cache, type),
        { deep: true }
      );
      const payload = await requestServiceNode.post(
        'register-sale/quotaTransfer/consult',
        customParams
      );
      dispatch(
        stepSuccess(
          addQuotasSetSanitizer(snakecaseKeys(payload.data.contracts), params)
        )
      );
    } else if (type === 'transfer') {
      const customParams = camelcaseKeys(
        moveQuotaSanitizer(params, debtor_cache, type),
        { deep: true }
      );
      const payload = await requestServiceNode.post(
        'register-sale/quotaTransfer/consult',
        customParams
      );
      dispatch(
        stepSuccess(
          moveQuotaSetSanitizer(
            snakecaseKeys(payload.data.contracts[0]),
            params
          )
        )
      );
    }
    dispatch(errorOnRequest('FetchTransferQuotas', {}));
  } catch (errors) {
    const e = await errors.json();
    dispatch(serverStatusError(e));
    _get(e, 'errors.base', null) &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    dispatch(
      errorOnRequest('FetchTransferQuotas', transferErrorsSanitizer(e.errors))
    );
  } finally {
    dispatch(completeRequest('FetchTransferQuotas'));
  }
};

export const addQuotas = (
  params = {},
  debtor_cache = {},
  route
) => async dispatch => {
  dispatch(sendRequest('AddQuotas'));
  try {
    const customParams = camelcaseKeys(
      addQuotasSanitizer(params, debtor_cache, 'union'),
      { deep: true }
    );
    const payload = await requestServiceNode.post(
      'register-sale/quotaTransfer/create',
      customParams
    );
    const customPayload = snakecaseKeys(payload);
    dispatch(
      newAlert('success', 's:', 'Solicitud de unión añadida a la venta.')
    );
    dispatch({
      type: RESET_PROMISSORY_DATA
    });
    dispatch(
      changeAvailableQuota(
        addAvailableQuotas(_get(customPayload, 'data.contracts'))
      )
    );
    history.push(route);
  } catch (errors) {
    const e = await errors.json();
    dispatch(serverStatusError(e));
    _get(e, 'errors.base', null) &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    dispatch(
      errorOnRequest('FetchTransferQuotas', transferErrorsSanitizer(e.errors))
    );
    dispatch(errorOnRequest('AddQuotas', e.errors.base));
  } finally {
    dispatch(completeRequest('AddQuotas'));
  }
};

export const moveQuota = (
  params = {},
  debtor_cache = {},
  route
) => async dispatch => {
  dispatch(sendRequest('MoveQuota'));
  try {
    const customParams = camelcaseKeys(
      moveQuotaSanitizer(params, debtor_cache, 'transfer'),
      { deep: true }
    );
    const payload = await requestServiceNode.post(
      'register-sale/quotaTransfer/create',
      customParams
    );
    const customPayload = snakecaseKeys(payload);
    dispatch(
      newAlert('success', 's:', 'Solicitud de traslado añadida a la venta.')
    );
    dispatch({
      type: RESET_PROMISSORY_DATA
    });
    dispatch(
      changeAvailableQuota(
        addAvailableQuotas(_get(customPayload, 'data.contracts'))
      )
    );
    history.push(route);
  } catch (errors) {
    const e = await errors.json();
    dispatch(serverStatusError(e));
    _get(e, 'errors.base', null) &&
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    dispatch(
      errorOnRequest('FetchTransferQuotas', transferErrorsSanitizer(e.errors))
    );
    dispatch(errorOnRequest('MoveQuota', e.errors.base));
  } finally {
    dispatch(completeRequest('MoveQuota'));
  }
};

export const cancelTransfer = (
  debtor_cache = {},
  cosigner_cache = {}
) => async (dispatch, getState) => {
  dispatch(sendRequest('CancelTransfer'));
  try {
    const params = {
      ...debtor_cache,
      ...cosigner_cache
    };
    await requestServiceNode.delete(
      'register-sale/quotaTransfer/remove',
      camelcaseKeys(params, { deep: true }),
      {},
      true
    );
    dispatch(
      newAlert('success', 's:', 'Solicitud de Unión/Traslado cancelada.')
    );
    dispatch(resetAddQuotasData());
    dispatch(resetMoveQuotaData());
    dispatch(restoreAvailableQuota());
    cacheFinancing(getState());
  } catch (errors) {
    const e = await errors.json();
    dispatch(errorOnRequest('CancelTransfer', e.errors));
    _get(e, 'errors.base') &&
      dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
  } finally {
    dispatch(completeRequest('CancelTransfer'));
  }
};

const changeAvailableQuota = additionalQuota => {
  return (dispatch, getState) => {
    dispatch(sendRequest('ChangeAvailableQuota'));
    dispatch({
      type: CHANGE_AV_QUOTA,
      additionalQuota
    });
    cacheFinancing(getState());
    setTimeout(() => dispatch(completeRequest('ChangeAvailableQuota')), 2000);
  };
};

export function checkThirdStep(params = {}, route, process, isSecureSale) {
  const customParams = camelcaseKeys(params, { deep: true });
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('register-sale/cosignerIdentification', customParams)
      .then(payload => {
        const data = snakecaseKeys(payload.data);
        if (!payload.data.valid) {
          dispatch(stepError(payload.errors));
        } else {
          dispatch(setNextStep(process));
          dispatch(
            stepSuccess({
              cosignerIsValid: payload.data.valid,
              ...params,
              ...data,
              cosignerContractData: payload.data.contract,
              isSecureSale
            })
          );
          history.push(route);
        }
      })
      .catch(async error => {
        const e = await error.json();
        dispatch(stepError(e.errors));
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base,
              'Información del codeudor'
            )
          );
      });
  };
}

export const checkFourthStep = (
  params = {},
  debtor = {},
  cosigner = {},
  extraData = {},
  route,
  process
) => async dispatch => {
  dispatch(sendRequest('IdentificationCheck'));
  try {
    const payload = await requestServiceNode.post(
      'register-sale/aniVerify',
      identificationSanitizer(params, debtor, cosigner)
    );
    const data = snakecaseKeys(payload);
    const debtorData = _get(data, 'data.debtor_result.data');
    const cosignerData = _get(data, 'data.cosigner_result.data');
    // Last names match correctly
    const debtorResult = debtorIdentificationSanitizer(debtorData);
    const cosignerResult = cosignerData
      ? cosignerIdentificationSanitizer(cosignerData)
      : {};
    dispatch(
      stepSuccess({
        ...debtorResult,
        ...cosignerResult,
        dcDebtorSuccess: true,
        dcDebtorErrors: {},
        dcCosignerSuccess: true,
        dcCosignerErrors: {},
        ...extraData
      })
    );
    dispatch(toggleIDModal(true));
  } catch (errors) {
    errors.json().then(e => {
      dispatch(dcDebtorError(e.errors));
      dispatch(dcCosignerError(e.errors));
      if (e.errors.debtorLastName || e.errors.cosignerLastName) {
        dispatch(stepError(e.errors));
      } else {
        _get(e, 'errors.base') &&
          (dispatch(setNextStep(process)),
            dispatch(
              toggleBaseErrorModal(
                true,
                'El sistema de registraduría no se encuentra disponible en este momento. El registro de venta continuará pero es necesario anexar un certificado de la registraduría que garantice la validez de los documentos de identificación ingresados en el proceso; de lo contrario la venta no podrá ser aprobada.',
                'Registraduría',
                route
              )
            ));
      }
    });
  } finally {
    dispatch(completeRequest('IdentificationCheck'));
  }
};

export function getSecurityQuestions(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(
        `register-sale/getSecurityQuestions`,
        { ...params, process },
        null,
        true
      )
      .then(payload => {
        if (payload.data.valid) {
          if (params.currentClient === 'debtor') {
            payload.data = {
              debtor: payload.data
            };
          } else {
            payload.data = {
              cosigner: payload.data
            };
          }
          payload.data.currentClient = 'debtor';
          const data = snakecaseKeys(payload.data);
          dispatch(stepSuccess({ ...data }));
        } else {
          dispatch(stepSuccess({ failed: true }));
          const _error = payload.data;
          if (
            _error.retry ||
            typeof _error.usingCrosscoreParams !== 'undefined'
          ) {
            dispatch(
              stepSuccess({
                retry: true,
                usingCrosscoreParams: _error.usingCrosscoreParams
              })
            );
          } else {
            dispatch(stepError(_error.errors));
          }
          _get(_error, 'errors.base') &&
            dispatch(
              toggleBaseErrorModal(
                true,
                _error.errors.base[0],
                'Preguntas de seguridad'
              )
            );
        }
      })
      .catch(async error => {
        const e = await error.json();
        dispatch(stepError(e.errors));
        dispatch(stepSuccess({ failed: true }));
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base,
              'Preguntas de seguridad'
            )
          );
      });
  };
}

export function fetchSecurityQuestionsCausals(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .get(`register-sale/securityQuestionCausal`, params, null, true)
      .then(payload => {
        dispatch(stepSuccess({ securityQuestionsCausals: payload.data }));
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              error.errors.base[0],
              'Preguntas de seguridad'
            )
          );
      });
  };
}

export function chooseCausal(params = {}, route, process, setFieldValue = null) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(`register-sale/chooseCausal`, params, null, true)
      .then(payload => {
        if (payload.data.valid) {
          dispatch(stepSuccess({ causal: payload.data.valid }));
          return true;
        } else {
          const e = payload;
          dispatch(stepError(e.errors));
          _get(e, 'errors.base') &&
            dispatch(
              toggleBaseErrorModal(true, e.errors.base, 'Registro de causal')
            );
        }
      })
      .catch(async error => {
        const e = await error.json();
        if (setFieldValue) setFieldValue('cause', '');
        dispatch(stepSuccess({ causal: false }));
        dispatch(stepError(e.errors));
        dispatch(
          newAlert(
            'error',
            'Error',
            'Error al guardar causal. Inténtelo de nuevo.'
          )
        );
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base,
              'Registro de causal'
            )
          );
      });
  };
}

export function startIdentityOtpTransaction(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('register-sale/start-otp-transaction', params, null, true)
      .then(payload => {
        if (!payload.valid) {
          dispatch(stepSuccess({ failed: true }));
          if (payload.retry) {
            dispatch(stepSuccess({ retry: true }));
          } else {
            dispatch(stepError(payload.errors));
          }

          _get(payload, 'errors.base') &&
            dispatch(
              toggleBaseErrorModal(
                true,
                payload.errors.base[0],
                'Validación Identidad'
              )
            );
        }

        dispatch(
          stepSuccess({
            identityOTPRequiresQuestions: payload.requiresQuestions,
            identityOTPtimestamp: payload.timestampOTP,
            identityOTPCode: payload.OTPCode,
            identityOTPEmailSent: payload.emailSent,
            identityOTPMobileSent: payload.mobileSent,
            identityOTPTransactionId: payload.transactionId,
            identityOTPRegValidation: payload.regValidation,
            identityOTPName: payload.name,
            identityOTPLastName: payload.lastName,
            identityOTPEmail: payload.email,
            identityOTPMobile: payload.mobile,
            usingCrosscoreParams:
              typeof payload.usingCrosscoreParams === 'undefined'
          })
        );

        if (payload.OTPCode === OTPCodes.GenerationError) {
          dispatch(
            newAlert(
              'warning',
              'Error',
              'No se pudo generar OTP. Se procederá con preguntas reto.'
            )
          );
        }
      })
      .catch(async error => {
        dispatch(stepSuccess({ failed: true }));
        const e = await error.json();
        let errorMessage = 'Ocurrió un error iniciando el proceso de OTP.';
        if (e.errors) {
          dispatch(stepError(e.errors));
          errorMessage = _get(e, 'errors.base')[0];
        }
        dispatch(newAlert('error', 'ERROR:', errorMessage));
      });
  };
}

export function verifyIdentityOtp(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('register-sale/verify-otp', { ...params, process }, null, true)
      .then(payload => {
        dispatch(stepSuccess({ identityOTPCode: payload.OTPCode }));
        switch (payload.OTPCode) {
          case OTPCodes.Approved:
            dispatch(newAlert('success', 's', '¡OTP validado correctamente!'));
            if (
              (params.cosigner == '' && params.currentClient == 'debtor') ||
              params.currentClient == 'cosigner'
            ) {
              if (payload.need_unique_unmaterialized_promissory) {
                process = 'digitalSaleWithoutUUPonContract';
              }
              dispatch(setNextStep(process));
              history.push(route);
            } else {
              payload.currentClient = 'cosigner';
              dispatch(
                stepSuccess({
                  ...payload,
                  debtor: { security_questions: { security_questions: true } }
                })
              );
            }
            break;

          case OTPCodes.Rejected:
            dispatch(
              newAlert(
                'error',
                'OTP Incorrecto',
                'El OTP ingresado no es correcto.'
              )
            );
            break;

          case OTPCodes.Expired:
            dispatch(
              newAlert(
                'warning',
                'OTP Expirado',
                'El OTP ha expirado, se procederá con las preguntas reto.'
              )
            );
            break;

          default:
            dispatch(
              newAlert('error', 'ERROR:', 'Ocurrió un error validando el OTP.')
            );
        }
      })
      .catch(async error => {
        console.error(error);
        dispatch(stepSuccess({ failed: true }));
        const e = error;
        let errorMessage = 'Ocurrió un error verificando el OTP.';
        if (e.errors) {
          dispatch(stepError(e.errors));
          errorMessage = _get(e, 'errors.base')[0];
        }
        dispatch(newAlert('error', 'ERROR:', errorMessage));
      });
  };
}

export function checkSecurityAnswers(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(`register-sale/identityCheck`, { ...params, process }, null, true)
      .then(payload => {
        if (payload.data.valid) {
          if (
            (params.cosigner == '' && params.currentClient == 'debtor') ||
            params.currentClient == 'cosigner'
          ) {
            if (payload.data.need_unique_unmaterialized_promissory) {
              process = 'digitalSaleWithoutUUPonContract';
            }
            dispatch(stepSuccess({ ...payload.data }));
            dispatch(setNextStep(process));
            history.push(route);
          } else {
            payload.data.currentClient = 'cosigner';
            dispatch(stepSuccess({ ...payload.data }));
          }
        } else {
          if (params.currentClient === 'debtor') {
            payload.data = {
              debtor: payload.data.security_questions
            };
          } else {
            payload.data = {
              cosigner: payload.data.security_questions
            };
          }
          dispatch(
            stepSuccess({
              ...payload.data,
              scrollUp: true,
              failed: true,
              questionsFailed: true,
              usingCrosscoreParams: params.usingCrosscoreParams
            })
          );
          dispatch(
            toggleBaseErrorModal(
              true,
              payload.errors && payload.errors.base
                ? payload.errors.base[0]
                : 'Validación fallida. Se deben responder nuevas preguntas.',
              'Preguntas de seguridad'
            )
          );
        }
      })
      .catch(async error => {
        dispatch(stepSuccess({ failed: true }));
        dispatch(stepError(error.errors));
        const e = await error.json();
        _get(e, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              e.errors.base,
              'preguntas de seguridad'
            )
          );
      });
  };
}

export function checkBiometricValidation(params = {}, route, process) {
  return async dispatch => {
    dispatch(stepLoading());
    let mac = '00-00-00-00-00';
    try {
      await axios.get('http://localhost:60024/mcdata').then((response) => {
        mac = response.data.Mac
      });
    } catch (err) {
      console.log(err)
    }
    console.log("mac", mac);
    return requestServiceNode
      .post(`register-sale/validateBiometrics`, { ...params, process, mac })
      .then(result => {
        const payload = snakecaseKeys(result);
        if (payload.data.valid) {
          if (payload.data.need_unique_unmaterialized_promissory) {
            process = 'digitalSaleWithoutUUPonContract';
          }
          dispatch(setNextStep(process));
          dispatch(stepSuccess({ ...payload.data }));
          if (
            (payload.data.debtor_validated && !payload.data.need_cosigner) ||
            (payload.data.debtor_validated &&
              payload.data.cosigner_validated &&
              payload.data.need_cosigner)
          ) {
            history.push(route);
          }
        } else {
          const _error = payload.data;
          _error.json().then(e => {
            dispatch(stepError(e.errors));
            _get(e, 'errors.base') &&
              dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
          });
        }
      })
      .catch(async error => {
        dispatch(stepError(error.errors));
        const e = await error.json();
        if (e.errors.error.base && e.errors.error.data) {
          if (e.errors.error.data.biometric_validation_failed_times === 5) {
            dispatch(stepSuccess({ ...e.errors.error.data, biometricError: e.errors.error.base[0] }));
          } else {
            dispatch(
              toggleBaseErrorModal(
                true,
                e.errors.error.base,
                'Validación biométrica'
              )
            );
            dispatch(stepSuccess({ ...e.errors.error.data }));
          }
        }
      });
  };
}

export function sendFacialBiometricMessage(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(`register-sale/sendFacialBiometricMessage`, params)
      .then(payload => {
        if (payload.status === 'success') {
          dispatch(stepSuccess({ ...payload.data }));
        } else {
          const _error = payload.data;
          _error.json().then(e => {
            dispatch(stepError(e.errors));
            _get(e, 'errors.base') &&
              dispatch(toggleBaseErrorModal(true, e.errors.base, 'Validación biométrica'));
          });
        }
      })
      .catch(async error => {
        dispatch(stepError(error.errors));
        const e = await error.json();
        if (e.errors.error.base && e.errors.error.data) {
          dispatch(toggleBaseErrorModal(true, e.errors.error.base, 'Validación biométrica'));
          dispatch(stepSuccess({ ...e.errors.error.data }));
        }
      });
  };
}

export function checkFacialBiometricStatus(params = {}, route, process, goNextStep = false) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(`register-sale/checkFacialBiometricStatus`, params)
      .then(payload => {
        if (payload.status === 'success') {
          dispatch(stepSuccess({ ...payload.data }));

          if (!payload.data.valid) {
            const errorMessage = payload.data.isFailed ? 'No se pudo validar la identidad' : 'No se ha validado la identidad';
            dispatch(toggleBaseErrorModal(true, `${errorMessage}. No puede continuar.`, 'Validación biométrica'));
            dispatch(stepError(payload.data));
            return;
          }

          dispatch(setNextStep(process));

          if (goNextStep) {
            dispatch(stepSuccess({ debtor_validated: false, cosigner_validated: false }));
            history.push(route);
          };
        } else {
          const _error = payload.data;
          _error.json().then(e => {
            _get(e, 'errors.base') &&
              dispatch(toggleBaseErrorModal(true, e.errors.base, 'Validación biométrica'));
          });
        }
      })
      .catch(async error => {
        dispatch(stepError(error.errors));
        const e = await error.json();
        if (e.errors) {
          if (e.errors.error && e.errors.error.base && e.errors.error.data) {
            dispatch(toggleBaseErrorModal(true, e.errors.error.base, 'Validación biométrica'));
            dispatch(stepSuccess({ ...e.errors.error.data }));
          } else {
            dispatch(toggleBaseErrorModal(true, e.errors.base, 'Validación biométrica'));
            dispatch(stepSuccess({ ...e.errors.data }));
          }
        }
      });
  };
}

export function checkEnrollmentOTP(
  params = {},
  route
) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post(
        'phone-code/validate-otp',
        validateOtpSanitizer(params),
        {},
        true
      )
      .then(payload => {
        if (payload.data.valid) {
          dispatch(newAlert('success', 's', payload.data.message));
          dispatch(stepSuccess({ ...payload.data }));
          history.push(route);
        } else {
          dispatch(stepError());
          dispatch(newAlert('error', 'ERROR:', payload.data.message));
        }
      })
      .catch(async errors => {
        const e = await errors.json();
        if (e.errors) {
          dispatch(stepError(e.errors));
          if ('base' in e.errors) {
            dispatch(newAlert('error', 'ERROR:', e.errors['base']));
          } else {
            dispatch(errorOnRequest('ValidateOtp', e.errors));
          }
        } else {
          dispatch(serverStatusError(errors));
        }
      })
      .finally(() => {
        dispatch(completeRequest('ValidateOtp'));
      });
  };
}

export function checkFifthStep(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    dispatch(setInvoiceValidationSkipped(false));

    return requestServiceNode
      .post('invoices/verify', verifyInvoiceSanitizer(params))
      .then(payload => {
        if (process) {
          dispatch(setNextStep(process));
        }
        if (params.invoice2) {
          dispatch(
            stepSuccess({
              invoice1: params.invoice1,
              invoice2: params.invoice2
            })
          );
          dispatch(setInvoicesValid(payload.data));
        } else {
          dispatch(
            stepSuccess({
              invoice1: params.invoice1
            })
          );
          dispatch(setInvoicesValid(payload.data));
        }

        if (route && params.isSkipInvoiceQuestionActive) {
          history.push(route);
        }
      },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  e.errors.base && dispatch(newAlert('error', 'ERROR:', e.errors.base));
                },
                response: e
              })(dispatch);
            });
        });
  };
}

export function skipInvoiceValidationStep(params, route, process) {
  return (dispatch, getState) => {
    return requestServiceNode
      .post('invoices/skip-step', params)
      .then(payload => {
        dispatch(setInvoiceValidationSkipped(true));
        dispatch(setNextStep(process));
        dispatch(setOriginalProcess(process));
        cacheFinancing(getState());
        history.push(route);
      })
      .catch(e => {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            e.message ||
            'Ocurrió un error omitiendo el paso de validación de facturas. Intente de nuevo o valide facturas.'
          )
        );
      });
  };
}

export function resetInvoiceValidationSkipped() {
  return dispatch => {
    dispatch(setInvoiceValidationSkipped(false));
  };
}

export function fetchQuestionsInvoice(params = {}) {
  return dispatch => {
    dispatch(sendRequest('QuestionsInvoice'));
    if (params.invoice2 !== undefined) {
      params = {
        contract: params.contract,
        invoice1: params.invoice1.id,
        invoice2: params.invoice2.id
      };
    } else {
      params = {
        contract: params.contract,
        invoice1: params.invoice1.id
      };
    }
    return requestServiceNode
      .get('invoices/questions-invoice', questionsInvoiceSanitizer(params))
      .then(payload => {
        dispatch(stepSuccess({ ...payload.data }));
        dispatch(setQuestionsInvoice(payload.data));
      },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  e.errors.base && dispatch(
                    toggleBaseErrorModal(
                      true,
                      error.errors.base[0],
                      'Control de factura',
                      history.push('/portal/validate-debtor')
                    )
                  );
                },
                response: e
              })(dispatch);
            });
        }).finally(() => dispatch(completeRequest('QuestionsInvoice')));
  };
}

export function checkInvoiceResponse(params = {}, route, process) {
  return (dispatch, getState) => {
    dispatch(stepLoading());
    dispatch(sendRequest('CheckInvoiceResponse'));
    const questionsInvoice = getState().portalFinancing.questionsInvoice;
    return axiosNodeRequest
      .post('invoices/check-response', checkInvoiceSanitizer({ ...params, questions: questionsInvoice }))
      .then(payload => {
        dispatch(setNextStep(process));
        if (params.invoice2) {
          dispatch(
            stepSuccess({
              invoices_valid: payload.valid,
              invoice1: params.invoice1,
              invoice2: params.invoice2
            })
          );
        } else {
          dispatch(
            stepSuccess({
              invoices_valid: payload.valid,
              invoice1: params.invoice1
            })
          );
        }
        history.push(route);
      }).catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              error.errors.base[0],
              'Control de factura'
            )
          );
      })
      .finally(() => {
        dispatch(completeRequest('CheckInvoiceResponse'));
      });
  };
}

export const checkSixthStep = (params = {}, extraData = {}, route, process) => async dispatch => {
  try {
    dispatch(stepLoading());
    dispatch(resetBaseErrorModal());
    await requestServiceNode.post('register-sale/supplierData', { ...params, process });
    dispatch(setNextStep(process));
    dispatch(
      stepSuccess({
        point_of_sale: params.pointOfSale,
        sale_channel: params.saleChannel,
        validationMethod: params.validationMethod,
        ...extraData
      })
    );
    history.push(route);
  } catch (error) {
    const errorsRes = await error.json();
    dispatch(stepError(errorsRes.errors));
    if (errorsRes.errors.quota) {
      dispatch(newAlert('error', 'ERROR:', errorsRes.errors.quota));
    } else {
      _get(errorsRes, 'errors.base') &&
        dispatch(
          toggleBaseErrorModal(
            true,
            errorsRes.errors.base,
            'Validación del proveedor'
          )
        );
    }
  }
};

export const getScoringCategories = (params = {}) => async dispatch => {
  try {
    const payload = await requestServiceNode
      .get('register-sale/scoringCategories',
        {
          contractId: params.contractId,
          supplierId: params.supplierId,
        }
      );
    dispatch(
      stepSuccess({
        scoring_exclusion_categories: snakecaseKeys(payload.data),
      })
    );
  } catch (error) {
    const errorsRes = await error.json();
    _get(errorsRes, 'errors.base') &&
      dispatch(
        toggleBaseErrorModal(
          true,
          errorsRes.errors.base,
          'Validación categoría Scoring'
        )
      );
  }
};

export const getMaxFinancialPlanbyCategories = (params = {}) => async dispatch => {
  try {
    const payload = await requestServiceNode
      .post('register-sale/maxFinancialPlanbyCategories',
        {
          contractId: params.contractId,
          saleChannelId: params.saleChannelId,
          categoriesId: params.categoriesId,
        }
      );
    dispatch(
      stepSuccess({
        article_installments: snakecaseKeys(payload.data),
      })
    );
  } catch (error) {
    const errorsRes = await error.json();
    _get(errorsRes, 'errors.base') &&
      dispatch(
        toggleBaseErrorModal(
          true,
          errorsRes.errors.base,
          'Validación de planes de financiación'
        )
      );
  }
};

export const getVoluntaryInsuranceLayerTwoInfo = (params = {}) => async dispatch => {
  try {
    const payload = await requestServiceNode
      .post('contracts/getVoluntaryInsuranceLayerTwoInfo',
        params
      );
    dispatch(stepSuccess({ voluntaryInsurance: payload.data }));
  } catch (error) {
    const errorsRes = await error.json();
    _get(errorsRes, 'errors.base') &&
      dispatch(toggleBaseErrorModal(true, errorsRes.errors.base, 'Validación de seguro voluntario'));
  }
};

export const getMegaQuota = (params = {}) => async dispatch => {
  dispatch(sendRequest('MegaQuota'));
  try {
    const payload = await requestServiceNode.post('check-mega-quota-promigas', params);

    const { megaQuotas, usedQuotaByClient, scoringQuota, isScoringUsed, scoringResponseId, minInstallmentMegaQuota, maxInstallmentMegaQuota } = payload.data;

    dispatch(stepSuccess({
      megaQuotaInfo: megaQuotas,
      usedQuotaByClient,
      scoringQuotaByMegaquota: scoringQuota,
      isScoringUsed,
      scoringResponseId,
      minInstallmentMegaQuota,
      maxInstallmentMegaQuota,
    }));

    return megaQuotas;
  } catch (error) {
    const errorsRes = await error.json();
    _get(errorsRes, 'errors.base') &&
      dispatch(toggleBaseErrorModal(true, errorsRes.errors.base, 'Validación de Megacupo'));
  } finally {
    dispatch(completeRequest('MegaQuota'));
  }
};

export const setMegaQuota = megaQuota => {
  return (dispatch, getState) => {
    dispatch(sendRequest('ChangeAvailableQuota'));
    dispatch({
      type: SET_MEGA_QUOTA,
      megaQuota
    });
    cacheFinancing(getState());
    setTimeout(() => dispatch(completeRequest('ChangeAvailableQuota')), 2000);
  };
};

export function resetMegaQuota() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_MEGA_QUOTA
    });
    cacheFinancing(getState());
  };
}

export function validateSale(params = {}, data = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return axiosRequest
      .post('sales/validate', { ...params, process })
      .then(() => {
        dispatch(setNextStep(process));
        dispatch(stepSuccess({
          validatedSaleDetails: params,
          isPromissoriesV2: null,
          otpV2TransaccionId: null,
          voucherProcessId: null,
          voucher_process_id: null,
          otpValidatorDebtor: null,
          otpValidatorCosigner: null,
          base64Promissory: null,
          base64: null,
          voucherV2Url: null
        }));
        dispatch(handleArticles(data, route));
      })
      .catch(error => {
        handleRequestError({
          beforeHandle: () => dispatch(stepError(error.errors)),
          handleRequest: () => {
            if (error.errors.quota) {
              dispatch(newAlert('error', 'ERROR:', error.errors.quota[0]));
            } else {
              error.errors.base
                ? dispatch(toggleBaseErrorModal(true, error.errors.base[0]))
                : error.errors.total_value &&
                dispatch(
                  toggleBaseErrorModal(
                    true,
                    error.errors.total_value[0],
                    'Validación de artículos'
                  )
                );
            }
          },
          response: error
        })(dispatch);
      });
  };
}

//* FETCH CONTRACT EXTRA QUOTAS *
function fetchExtraQuotasSuccess(items) {
  return {
    type: EXTRA_QUOTAS_SET,
    data: items
  };
}

export const fetchExtraQuotas = (contract, params) => async dispatch => {
  dispatch(sendRequest('ExtraQuotas'));
  try {
    const response = await requestServiceNode.post(
      `extra-quota/getValidExtraQuota`,
      { contractId: contract }
    );
    const payload = snakecaseKeys(response);
    dispatch(fetchExtraQuotasSuccess(payload.data));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    errors.json().then(e => {
      e.errors &&
        dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
    });
  } finally {
    dispatch(completeRequest('QuotaTransfer'));
  }
};
//* FETCH CONTRACT EXTRA QUOTAS *

export function registerSale(params = {}, route, process) {
  return dispatch => {
    dispatch(stepLoading());
    return axiosRequest
      .post('/sales', params)
      .then(payload => {
        dispatch(setNextStep(process));
        dispatch(
          stepSuccess({
            promissoryDetails: params,
            saleNumber: payload.data.id,
            saleCompleted: true
          })
        );
        history.push(route);
      })
      .catch(error => {
        handleRequestError({
          beforeHandle: () => dispatch(stepError(error.errors)),
          handleRequest: () => {
            _get(error, 'errors.base') &&
              dispatch(
                toggleBaseErrorModal(
                  true,
                  error.errors.base[0],
                  'Validación del pagaré'
                )
              );
          },
          response: error
        })(dispatch);
      });
  };
}

export function registerPromissory(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return axiosRequest
      .post('/promissories', params)
      .then(payload => {
        dispatch(stepSuccess({}));
        dispatch(
          newAlert('success', 'Success:', 'Pagaré único creado exitosamente.')
        );
        history.push(`/promissories/${payload.data.id}`);
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(
            toggleBaseErrorModal(
              true,
              error.errors.base[0],
              'Validación del pagaré'
            )
          );
      });
  };
}

export function fetchGlobalParameters(params = {}) {
  return dispatch => {
    dispatch(sendRequest('FetchGlobalParameters'));
    return requestServiceNode
      .get('register-sale/parameters', params)
      .then(payload => {
        const data = snakecaseKeys(payload.data);
        dispatch(stepSuccess({ ...data }));
      })
      .catch(error => {
        error &&
          error.json().then(e => {
            dispatch(stepError(e.errors));
            _get(e, 'errors.base') &&
              dispatch(toggleBaseErrorModal(true, e.errors.base));
          });
      })
      .finally(() => {
        dispatch(completeRequest('FetchGlobalParameters'));
      });
  };
}

export function fetchSellers(params = {}) {
  return dispatch => {
    return requestServiceNode
      .post('register-sale/sellers', params)
      .then(payload => {
        dispatch(stepSuccess({ sellers: snakecaseKeys(payload.data) }));
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(toggleBaseErrorModal(true, error.errors.base[0]));
      });
  };
}

export function fetchProfessions(params = {}) {
  return dispatch => {
    return requestServiceNode
      .get('professions/list', params)
      .then(payload => {
        dispatch(stepSuccess({ professions: payload.data }));
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        error.errors &&
          error.errors.base &&
          dispatch(toggleBaseErrorModal(true, error.errors.base[0]));
      });
  };
}

export function searchCacheSaleResponse(contractId) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .get(`sales/saleCache/${contractId}`)
      .then(
        payload => {
          dispatch(stepSuccess({ saleCacheResponse: payload.data }));
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

export function resetBiometricProps(params = {}) {
  return dispatch => {
    dispatch(
      stepSuccess({
        causal: false,
        biometricValidationFailedTimes: 0,
        currentClient: 'debtor'
      })
    );
  };
}

export function resetScrollProp(params = {}) {
  return dispatch => {
    dispatch(
      stepSuccess({
        scrollUp: false
      })
    );
  };
}

export function resetFailedProp(params = {}) {
  return dispatch => {
    dispatch(
      stepSuccess({
        failed: false
      })
    );
  };
}

export function resetRetryProp(params = {}) {
  return dispatch => {
    dispatch(
      stepSuccess({
        retry: false
      })
    );
  };
}

export function resetProp(props = {}) {
  return dispatch => {
    dispatch(stepSuccess(props));
  };
}

export function resetOTPCodeProp() {
  return dispatch => {
    dispatch(resetOtpCode(''));
  };
}

export function resetLayerTwoInfo() {
  return dispatch => {
    dispatch(
      stepSuccess({
        voluntaryInsurance: {}
      })
    );
  };
}

export function fetchPOS(params = {}) {
  return dispatch => {
    return requestServiceNode
      .get('register-sale/pointOfSalesRegisterSales', params)
      .then(payload => {
        dispatch(
          stepSuccess({
            portalPOS: payload.data,
            debtor_validated: false
          })
        );
      })
      .catch(error => {
        dispatch(stepError(error.errors));
        _get(error, 'errors.base') &&
          dispatch(toggleBaseErrorModal(true, error.errors.base[0]));
      });
  };
}

export function getScoring(params = {}, process) {
  return dispatch => {
    dispatch(sendRequest('Scoring'));
    return requestServiceNode
      .post('scoring/scoringConsultsRegisterSale', { ...scoringInRegisterSaleFilterSanitizer(params), process })
      .then(payload => {
        payload.data = snakecaseKeys(payload.data);
        const data = {
          scoringQuota: payload.data.new_available_quota,
          minimumScoringQuota: payload.data.minimum_new_available_quota,
          scoringResponseId: payload.data.debtor_response_id,
          ...params.cache_params
        };
        const cosignerContractData = _get(
          payload.data,
          'cosigner_contract',
          null
        );
        if (cosignerContractData != null) {
          data['cosignerContractData'] = cosignerContractData;
        }
        dispatch(stepSuccess(data));
        dispatch(toggleScoringModal(true));
      })
      .catch(async error => {
        const e = await error.json();
        dispatch(stepError(e.errors));

        const errorMessage = e.errors.base || e.errors.contract;

        if (errorMessage) dispatch(toggleBaseErrorModal(true, errorMessage));
      })
      .finally(() => {
        dispatch(completeRequest('Scoring'));
      });
  };
}

export const setScoringQuota = scoringQuota => {
  return (dispatch, getState) => {
    dispatch(sendRequest('ChangeAvailableQuota'));
    dispatch({
      type: SET_SCORING_QUOTA,
      scoringQuota
    });
    cacheFinancing(getState());
    setTimeout(() => dispatch(completeRequest('ChangeAvailableQuota')), 2000);
  };
};

export function resetScoring() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_SCORING
    });
    cacheFinancing(getState());
  };
}

export function setApplyScoring(value) {
  const cache = sessionStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    data.applyScoring = value;
    sessionStorage.setItem(CACHE_KEY, JSON.stringify(data));
  }
  return {
    type: SET_APPLY_SCORING,
    data: value
  };
}

export const resetOtpV2InfoResponse = () => {
  return {
    type: RESET_OTP_V2_INFO_RESPONSE
  };
};

export function sendOtpV2(params = {}, documentType = 'Voucher') {
  return dispatch => {
    dispatch(sendRequest('SendV2Otp'));
    return requestServiceNode
      .post(`my-promissories-v2/sendOtp${documentType}`, params, null)
      .then(
        payload => {
          dispatch(completeRequest('SendV2Otp'));
          if (payload.data.valid && payload.data.transaccionId) {
            dispatch(
              newAlert('success', 'Success:', 'OTP enviado exitosamente.')
            );
            const data = { otpV2TransaccionId: payload.data.transaccionId };
            dispatch(stepSuccess(data));
            return true;
          }
        },
        error => {
          dispatch(completeRequest('SendV2Otp'));
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

export function reSendOtpV2(params = {}) {
  return dispatch => {
    dispatch(sendRequest('SendV2Otp'));
    return requestServiceNode
      .post('my-promissories-v2/reSendOtp', params, null)
      .then(
        payload => {
          dispatch(completeRequest('SendV2Otp'));
          if (payload.data.valid) {
            dispatch(
              newAlert('success', 'success', 'OTP reenviado exitosamente.')
            );
            return true;
          }
        },
        error => {
          dispatch(completeRequest('SendV2Otp'));
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(newAlert('error', 'ERROR:', e.errors.base));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
};

export function validateOtpV2(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .post('my-promissories-v2/validateOtp', params, {}, true)
      .then(
        payload => {
          if (params.signer == "deudor") {
            dispatch(stepSuccess({
              otpValidatorDebtor: payload.data.valid
            }));
          } else {
            dispatch(stepSuccess({
              otpValidatorCosigner: payload.data.valid
            }));
          }

          if (payload.data.valid) {
            dispatch(newAlert('success', 's', payload.data.response));
          } else {
            dispatch(newAlert('error', 'ERROR:', payload.data.message));
          }
          return {
            valid: payload.data.valid,
          };
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(newAlert('error', 'ERROR:', e.errors.base));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
};

export function validateSignedDocumentV2(params = {}) {
  return dispatch => {
    dispatch(stepLoading());
    return requestServiceNode
      .get('my-promissories-v2/documentState', params)
      .then(
        payload => {
          if (payload.data.valid) {
            dispatch(stepSuccess({
              documentSerialV2: payload.data.documentSerial
            }));
            return {
              valid: true,
              voucherSerial: payload.data.documentSerial
            };
          }
        },
        error => {
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
};

export function previewDocumentV2(body = {}, documentType, isRemoteTeleshopping = false) {
  return dispatch => {
    dispatch(stepLoading());
    dispatch(sendRequest('PreviewDocument'));
    return requestServiceNode
      .post(
        `generate-pdf/pdfV2Preview${documentType}`,
        documentType === 'Promissory' ? previewPromissorySanitizer(body) : body,
        {},
        {},
        {},
        true,
        true
      )
      .then(response => response.blob())
      .then(
        payload => {
          dispatch(completeRequest('PreviewDocument'));
          if (documentType !== 'Promissory') {
            dispatch(setUrlFromBlob(isRemoteTeleshopping ? 'voucherV2Ts' : 'voucherV2', payload));
            dispatch(stepSuccess({}))
            return;
          }
          dispatch(stepSuccess({ promissory_url: URL.createObjectURL(payload) }))
        },
        error => {
          dispatch(completeRequest('PreviewDocument'));
          dispatch(stepSuccess({ promissory_url: URL.createObjectURL(new Blob([])) }))
          error &&
            error.json().then(e => {
              handleRequestError({
                beforeHandle: () => dispatch(stepError(e.errors)),
                handleRequest: () => {
                  _get(e, 'errors.base') &&
                    dispatch(toggleBaseErrorModal(true, e.errors.base, ''));
                },
                response: e
              })(dispatch);
            });
        }
      );
  };
}

import {
  PROMISSORIES_SET,
  SET_PROMISSORY,
  PROMISSORY_RANGES,
  PROMISSORY_RANGES_MODAL,
  SAVED_PROMISSORY,
  CANCELLED_PROMISSORY,
  REVOKED_PROMISSORY,
  APPROVED_PROMISSORY
} from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import RequestService from '../services/request_service';
import {
  promissoryFiltersSanitizer,
  promissoryRangesFilterSanitizer
} from '../helpers/sanitizers/promissoryFilters';
import {
  editPromissorySanitizer,
  cancelPromissorySanitizer,
  approvePromissorySanitizer
} from '../helpers/sanitizers/promissorySanitizer';
import { setUrl, clearUrl } from './file_actions';
import AxiosRequest from '../services/axios_request';
import { addComment } from './comment_actions';
import { objectToString } from '../helpers/mappings/map';
import { salesDetail } from './sales_actions';

const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
const axiosRequest = new AxiosRequest();
const nodeRequest = new RequestService('node');

//* PURE ACTION FUNCTIONS
function success(items, pagi) {
  return {
    type: PROMISSORIES_SET,
    data: items,
    meta: pagi
  };
}

function setRanges(items, pagi) {
  return {
    type: PROMISSORY_RANGES,
    data: items,
    meta: pagi
  };
}

function setPromissory(info) {
  return {
    type: SET_PROMISSORY,
    data: info
  };
}
function setModal(open, isEditing, selected) {
  return {
    type: PROMISSORY_RANGES_MODAL,
    open: open,
    data: selected,
    isEditing: isEditing
  };
}
//* VIEW ACTION FUNCTIONS
export const fetchPromissories = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/promissories?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'promissories',
      promissoryFiltersSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    // console.log('Errors in L. Promissory:', errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const fetchPromissory = id => async dispatch => {
  dispatch(sendRequest('FetchPromissory'));
  try {
    const payload = await requestService.get(`/promissories/${id}`);
    dispatch(setPromissory(payload.data));
    dispatch(pdfPromissory(id));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('FetchPromissory'));
  }
};

//* INDEX *
export const fetchPromissoryRanges = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/promissory-ranges?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get(
      'promissory-ranges',
      promissoryRangesFilterSanitizer(params)
    );
    const { data, meta } = payload.data;
    dispatch(setRanges(data, meta));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest());
  }
};

// * OPEN/CLOSE MODAL *
export function toggleModal(open, isEditing, selected) {
  return dispatch => {
    dispatch(setModal(open, isEditing, selected));
  };
}
//* CREATE *
  export const createRange = (params = {}) => async dispatch => {
    dispatch(sendRequest('Range-Create'));
    const { corporation_id, enable, max_value, min_value } = params.promissory_range;
    const body = {
      corporationId: corporation_id,
      enable: enable,
      maxValue: max_value,
      minValue: min_value,
    }
    try {
      const payload = await requestServiceNode.post(`promissory-ranges`, body, {}, true);
      dispatch(completeRequest('Range-Create'));
      
      if (payload.status === 'success') {
        dispatch(newAlert('success', 'success', payload.data.message));
      }
      
      return { success: true };
    } catch (error) {
      dispatch(completeRequest('Range-Create'));
      dispatch(serverStatusError(error));
      const e = await error.json();
      dispatch(newAlert('error', 'success', e.errors.base));
      return e;
    }
  };

//* UPDATE *
export const updateRange = (id, params = {}) => async dispatch => {
  dispatch(sendRequest('Range-Create'));
  const { corporation_id, enable, max_value, min_value } = params.promissory_range;
  const body = {
    corporationId: corporation_id,
    enable: enable,
    maxValue: max_value,
    minValue: min_value,
  }
  try {
    const payload = await requestServiceNode.put(`promissory-ranges/${id}`, body, {}, true);
    dispatch(completeRequest('Range-Create'));
    
    if (payload.status === 'success') {
      dispatch(newAlert('success', 'success', payload.data.message));
    }
    
    return { success: true };
  } catch (error) {
    dispatch(completeRequest('Range-Create'));
    dispatch(serverStatusError(error));
    const e = await error.json();
    if (e.errors.base) {
      dispatch(newAlert('error', 'success', e.errors.base));
    }
    return e;
  }
};

//* APPROVE PROMISSORY *
function approvedPromissory() {
  return {
    type: APPROVED_PROMISSORY
  };
}

export const approvePromissory = (
  params = {},
  id,
  saleId
) => async dispatch => {
  dispatch(sendRequest('ApprovePromissory'));
  try {
    await requestService.put(
      `/document_revisions/promissories/${id}/approve`,
      { promissory: { ...approvePromissorySanitizer(params) } },
      {}
    );
    dispatch(
      newAlert('success', 'success', 'Pagaré está en proceso de aprobación')
    );
    dispatch(approvedPromissory());
    if (saleId) {
      dispatch(salesDetail(saleId));
    } else {
      dispatch(fetchPromissory(id));
    }
  } catch (errors) {
    dispatch(serverStatusError(errors));
    const e = await errors.json();
    if (e.errors || (e.data && e.data.message)) {
      e.errors &&
        e.errors.base &&
        dispatch(newAlert('error', 'error', e.errors.base[0]));
      e.data &&
        e.data.message &&
        dispatch(newAlert('error', 'ERROR:', e.data.message));
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Error procesando su solicitud, por favor comuníquese con su administrador.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('ApprovePromissory'));
  }
};

//* REVOKE PROMISSORY *
function rejectedPromissory() {
  return {
    type: REVOKED_PROMISSORY
  };
}

export const rejectPromissory = (params = {}, id, saleId) => async dispatch => {
  dispatch(sendRequest('RejectPromissory'));
  try {
    await requestService.put(
      `/document_revisions/promissories/${id}/reject`,
      { promissory: { ...approvePromissorySanitizer(params) } },
      {}
    );
    dispatch(newAlert('success', 's', 'Pagaré está en proceso de rechazo'));
    dispatch(rejectedPromissory());
    if (saleId) {
      dispatch(salesDetail(saleId));
    } else {
      dispatch(fetchPromissory(id));
    }
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (errors) {
      const e = await errors.json();
      if (e.errors || (e.data && e.data.message)) {
        e.errors &&
          e.errors.base &&
          dispatch(newAlert('error', 'error', e.errors.base[0]));
        e.data &&
          e.data.message &&
          dispatch(newAlert('error', 'ERROR:', e.data.message));
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Error procesando su solicitud, por favor comuníquese con su administrador.'
          )
        );
      }
    }
  } finally {
    dispatch(completeRequest('RejectPromissory'));
  }
};

// * ADD COMMENT TO PROMISSORY *
export const addPromissoryComment = (body = {}) => async dispatch => {
  try {
    dispatch(sendRequest('Add-Comment'));
    const payload = await requestService.post(
      `/promissories/${body.promissory_id}/add_comments`,
      body,
      {},
      true
    );
    dispatch(addComment(body.comment));
    dispatch(newAlert('success', 'success', payload.status));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    errors &&
      errors.json().then(e => {
        e.errors &&
          dispatch(newAlert('error', 'ERROR:', objectToString(e.errors)));
      });
  } finally {
    dispatch(completeRequest('Add-Comment'));
  }
};

//* EDIT UNIQUE PROMISSORY *
function savedPromissory() {
  return {
    type: SAVED_PROMISSORY
  };
}

export const editPromissory = (params = {}, id) => async dispatch => {
  dispatch(sendRequest('EditPromissory'));
  try {
    await requestService.put(
      `promissories/${id}`,
      { promissory: { ...editPromissorySanitizer(params) } },
      {}
    );
    dispatch(newAlert('success', 's', 'Pagaré en proceso de edición'));
    dispatch(savedPromissory());
    dispatch(fetchPromissory(id));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (errors) {
      const e = await errors.json();
      if (e.errors) {
        dispatch(errorOnRequest('EditPromissory', e.errors));

        if (e.errors.base && e.errors.base[0]) dispatch(newAlert('error', 'ERROR:', e.errors.base[0]));
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Error procesando su solicitud, por favor comuníquese con su administrador.'
          )
        );
      }
    }
  } finally {
    dispatch(completeRequest('EditPromissory'));
  }
};

//* CANCEL UNIQUE PROMISSORY *
function cancelledPromissory() {
  return {
    type: CANCELLED_PROMISSORY
  };
}

export const cancelPromissory = (params = {}, id) => async dispatch => {
  dispatch(sendRequest('CancelPromissory'));
  try {
    const payload = await requestService.delete(
      `promissories/${id}/revoke`,
      { promissory: { ...cancelPromissorySanitizer(params) } },
      {}
    );
    payload.status && dispatch(newAlert('success', 'success', payload.status));
    dispatch(cancelledPromissory());
    dispatch(fetchPromissory(id));
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (errors) {
      const e = await errors.json();
      if (e.errors) {
        e.errors &&
          e.errors.base &&
          dispatch(newAlert('error', 'error', e.errors.base[0]));
      } else {
        dispatch(
          newAlert(
            'error',
            'ERROR:',
            'Error procesando su solicitud, por favor comuníquese con su administrador.'
          )
        );
      }
    }
  } finally {
    dispatch(completeRequest('CancelPromissory'));
  }
};

export const pdfPromissory = id => async dispatch => {
  dispatch(sendRequest('PromissoryUrl'));
  try {
    dispatch(clearUrl('promissory'));
    const payload = await nodeRequest.post(
      `generate-pdf/promissory`,
      { promissoryId: id },
      null
    );
    dispatch(setUrl('promissory', payload));
  } catch (error) {
    dispatch(
      newAlert(
        'error',
        'errors',
        'Error al generar Pagaré. Contácte a su administrador.'
      )
    );
  } finally {
    dispatch(completeRequest('PromissoryUrl'));
  }
};
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import { newAlert } from './alert_actions';

import docRevisionFilters from '../helpers/sanitizers/docRevisionFilters';

import { LIST_DOC_REVISIONS_REQUEST } from '../constants/doc_revision_constants';

const requestServiceNode = new RequestService('node');

function success(data, meta) {
  return {
    type: LIST_DOC_REVISIONS_REQUEST,
    data,
    meta
  };
}

export const fetchDocRevisions = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/doc-revision?${stringifyQuery(params)}`);

  try {
    const payload = await requestServiceNode.get(
      'doc-revisions',
      docRevisionFilters(params),
      true
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    dispatch(newAlert('error', 'ERROR:', 'Ocurrió un error al cargar las revisiones de documentos'));
  } finally {
    dispatch(completeRequest());
  }
};

export const approveDocRevision = (data = {}, queryParams = {}) => async dispatch => {
  dispatch(sendRequest('ApproveDocRevision'));
  try {
    const payload = await requestServiceNode.put(
      `doc-revisions/approve`,
      data,
      true
    );

    dispatch(newAlert('success', 's', payload.data.message));
    dispatch(fetchDocRevisions(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
  } finally {
    dispatch(completeRequest('ApproveDocRevision'));
  }
};

export const revokeDocRevision = (data = {}, queryParams = {}) => async dispatch => {
  dispatch(sendRequest('RevokeDocRevision'));
  try {
    const payload = await requestServiceNode.put(
      `doc-revisions/revoke`,
      data,
      true
    );

    dispatch(newAlert('success', 's', payload.data.message));
    dispatch(fetchDocRevisions(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    }
  } finally {
    dispatch(completeRequest('RevokeDocRevision'));
  }
};

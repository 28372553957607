import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import ArticleFilters from './ArticleFilters';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import _isEmpty from 'lodash/isEmpty';
import { history } from '../../../helpers/history';
import { stringifyQuery } from '../../../helpers/parseQuerystring';
import { articleFilterSanitizerNode } from '../../../helpers/sanitizers/articleSanitizer';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import FormModal from './modals/Form';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

const NEW_ITEM = {
  active: true,
  isNew: true,
  suppliers: [],
  sellers: []
};

class ArticleList extends Component {
  state = {
    rowsPerPage: sessionStorage.getItem('BrillaRowsPerPage')
      ? parseInt(sessionStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isOpenedForm: false,
    item: {
      ...NEW_ITEM
    }
  };

  componentDidMount() {
    if (
      !this.props.categories ||
      !this.props.concepts ||
      !this.props.brands ||
      !this.props.suppliers
    ) {
      this.props.fetchOrgData();
    }
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.articleSaved !== this.props.articleSaved &&
      this.props.articleSaved
    ) {
      this.setState({ isOpenedForm: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    const queryParams = {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    };

    history.replace(
      `/articles?${stringifyQuery(
        articleFilterSanitizerNode(queryParams),
        false
      )}`
    );
    this.props.fetchArticles(queryParams);
  };

  _handleRowClick = (item, canUpdateArticles) => {
    if (canUpdateArticles) {
      this.setState({
        item: {
          ...this.state.item,
          isNew: false,
          ...item,
        },
        isOpenedForm: true
      });
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleCloseForm = () => this.setState({ isOpenedForm: false });

  _handleOpenForm = () => {
    this.setState({
      isOpenedForm: true,
      item: {
        ...NEW_ITEM
      }
    });
  };

  _handleSave = item => {
    const query = parseQuerystring(this.props) || {};
    if (item.isNew) {
      this.props.createArticle(item, query);
    } else {
      this.props.updateArticle(item, query);
    }
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    sessionStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleCloseForm = () => this.setState({ isOpenedForm: false });

  _handleOpenForm = () => {
    this.setState({
      isOpenedForm: true,
      item: {
        ...NEW_ITEM
      }
    });
  };

  _handleSave = item => {
    const query = parseQuerystring(this.props) || {};
    if (item.isNew) {
      this.props.createArticle(item, query);
    } else {
      this.props.updateArticle(item, query);
    }
  };

  _excelReportDownloadButtonIsVisible = () => {
    return (
      !this._supplierFilterIsEmpty() &&
      this._currentUserHasPermissionToExportArticlesToExcel() &&
      !_isEmpty(this.props.articles)
    );
  };

  _supplierFilterIsEmpty = () => {
    const filters = parseQuerystring(this.props) || {};
    return _isEmpty(filters.supplier);
  };

  _currentUserHasPermissionToExportArticlesToExcel = () => {
    return this.props.canExportArticlesToExcel;
  };

  _fetchExcelReportFile = () => {
    const filters = parseQuerystring(this.props) || {};
    this.props.fetchArticleListExcelReportFile({
      ...filters,
      page: filters.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  render() {
    const {
      classes,
      articles,
      meta = {},
      articleTypes,
      requestArticleListSend = true,
      requestErrorsOnSaveArticle,
      requestSaveArticleSend,
      canCreateArticles,
      canUpdateArticles,
      fetchingExcelReportFile
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Artículos'}
          </h1>
          {canCreateArticles && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenForm}
            >
              {'Crear Artículo'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <ArticleFilters
              onChangeFilter={this._fetchItems}
              categories={this.props.categories}
              brands={this.props.brands}
              articleTypes={this.props.articleTypes}
              enableSupplierFilter={true}
              suppliers={this.props.suppliers}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Artículos'}
            columns={columns}
            data={mapData(articles)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateArticles)
            }
            totalCount={articles.length}
            loading={requestArticleListSend}
            withDownload={this._excelReportDownloadButtonIsVisible()}
            onDownloadIconClick={this._fetchExcelReportFile}
            loadingDownload={fetchingExcelReportFile}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
        {this.state.isOpenedForm && (
          <FormModal
            onClose={this._handleCloseForm}
            item={this.state.item}
            suppliers={this.props.suppliers}
            concepts={this.props.concepts}
            brands={this.props.brands}
            categories={this.props.categories.filter(
              c => c.depth === 1 && c.active === true
            )}
            onSave={this._handleSave}
            errors={requestErrorsOnSaveArticle}
            isSubmitting={requestSaveArticleSend}
            articleTypes={articleTypes}
            canSetMaxItemsPerArticle={this.props.canSetMaxItemsPerArticle}
            errorOnRequest={this.props.errorOnRequest}
          />
        )}
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle
  ),
  { withTheme: true }
)(ArticleList);

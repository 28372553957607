//Auth action types:
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const TOGGLE_LOADING_CURRENT_USER = 'TOGGLE_LOADING_CURRENT_USER';
export const SET_STATE_SIGN_IN_DATA = 'SET_STATE_SIGN_IN_DATA';
export const RESET_APP = 'RESET_APP';
export const SET_COURSE_DETAIL = 'SET_COURSE_DETAIL';
//Roles action types:
export const ROLES_SET = 'ROLES_SET';
export const ROLES_SET_LIST = 'ROLES_SET_LIST';
export const ROLES_CLEAR = 'ROLES_CLEAR';
//Alert action types:
export const ALERT_NEW = 'NEW_ALERT';
export const ALERT_CLOSE = 'CLOSE_ALERT';
export const ALERT_CLEAR = 'ALERT_CLEAR';
//SALE action types:
export const SALE_TOGGLE_ACTION = 'SALE_TOGGLE_ACTION';
//Promissory action types:
export const PROMISSORIES_SET = 'PROMISSORIES_SET';
export const PROMISSORIES_CLEAR = 'PROMISSORIES_CLEAR';
export const SET_PROMISSORY = 'SET_PROMISSORY';
export const CLEAR_PROMISSORY = 'CLEAR_PROMISSORY';
export const PROMISSORY_RANGES = 'PROMISSORY_RANGES';
export const PROMISSORY_RANGES_MODAL = 'PROMISSORY_RANGES_MODAL';
export const SAVED_PROMISSORY = 'SAVED_PROMISSORY';
export const CANCELLED_PROMISSORY = 'CANCELLED_PROMISSORY';
export const APPROVED_PROMISSORY = 'APPROVED_PROMISSORY';
export const REVOKED_PROMISSORY = 'REVOKED_PROMISSORY';
//Client action types:
export const CLIENTS_SET = 'CLIENTS_SET';
export const CLIENTS_CLEAR = 'CLIENTS_CLEAR';
export const CLIENT_INFO_SET = 'CLIENT_INFO_SET';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';
//User action types:
export const USERS_SET = 'USERS_SET';
export const USER_NAMES_SET = 'USER_NAMES_SET';
export const USERS_CLEAR = 'USERS_CLEAR';
export const USER_INFO_SET = 'USER_INFO_SET';
export const USER_CREATE_INFO_SET = 'USER_CREATE_INFO_SET';
export const USER_INFO_CLEAR = 'USER_INFO_CLEAR';
export const USER_CREATE_INFO_CLEAR = 'USER_CREATE_INFO_CLEAR';
export const USER_CREATE = 'USER_CREATE';
export const USER_DELETE = 'USER_DELETE';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';
export const USER_COURSE_DETAIL = 'USER_COURSE_DETAIL';
//Article action types:
export const ARTICLES_SET = 'ARTICLES_SET';
export const ARTICLES_CLEAR = 'ARTICLES_CLEAR';
export const ARTICLES_INFO_CLEAR = 'ARTICLES_INFO_CLEAR';
export const ARTICLE_CREATE = 'ARTICLE_CREATE';
export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export const SAVED_ARTICLE = 'SAVED_ARTICLE';
//Category action types:
export const CATEGORIES_SET = 'CATEGORIES_SET';
export const CATEGORY_SAVED = 'CATEGORY_SAVED';
//Brand action types: BRAND
export const BRANDS_SET = 'BRANDS_SET';
export const BRANDS_CLEAR = 'BRANDS_CLEAR';
export const BRAND_SHOW = 'BRAND_SHOW';
//Supplier action types:
export const SUPPLIERS_SET = 'SUPPLIERS_SET';
export const SUPPLIER_INFO_SET = 'SUPPLIER_INFO_SET';
export const CREATED_SUPPLIER = 'CREATED_SUPPLIER';
export const UPDATED_SUPPLIER = 'UPDATED_SUPPLIER';
export const SUPPLIER_UPDATE_REQUEST = 'SUPPLIER_UPDATE_REQUEST';
export const SUPPLIER_UPDATE_COMPLETE = 'SUPPLIER_UPDATE_COMPLETE';
//Invoice action types:
export const SET_QUESTIONS_INVOICE = 'SET_QUESTIONS_INVOICE';
export const INVOICES_VALID = 'INVOICES_VALID';
//Corporation action types:
export const LIST_CORPORATIONS_REQUEST = 'LIST_CORPORATIONS_REQUEST';
export const CORPORATIONS_INFO_REQUEST = 'CORPORATIONS_INFO_REQUEST';
export const LIST_CORPORATION_NAMES_REQUEST = 'LIST_CORPORATION_NAMES_REQUEST';
//Point of sale action types:
export const LIST_POINTS_OF_SALE = 'LIST_POINTS_OF_SALE';
export const LIST_POINTS_OF_SALE_NAMES = 'LIST_POINTS_OF_SALE_NAMES';
export const FETCH_POINT_OF_SALE = 'FETCH_POINT_OF_SALE';
export const FETCH_SUPPLIER_POINTS_OF_SALE = 'FETCH_SUPPLIER_POINTS_OF_SALE';
export const SELECTED_SELLERS_SET = 'SELECTED_SELLERS_SET';
export const LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS =
  'LIST_POINTS_OF_SALE_NAMES_ASSOCIATIONS';
export const LIST_POINTS_OF_SALE_HOME_VISITS =
  'LIST_POINTS_OF_SALE_HOME_VISITS';
export const SET_POINT_OF_SALE_SALE_CHANNELS =
  'SET_POINT_OF_SALE_SALE_CHANNELS';

//Commissions action types:
export const SAVED_COMMISSION = 'SAVED_COMMISSION';
export const LIST_COMMISSIONS = 'LIST_COMMISSIONS';
export const DATA_TO_CREATE_COMMISSIONS = 'DATA_TO_CREATE_COMMISSIONS';
//Request state action types:
export const REQUEST_SEND = 'REQUEST_SEND';
export const REQUEST_COMPLETE = 'REQUEST_COMPLETE';
export const REQUEST_ERRORS = 'REQUEST_ERRORS';
export const REQUEST_FORM_COMPLETED = 'REQUEST_FORM_COMPLETED';
export const REQUEST_FORM_INIT = 'REQUEST_FORM_INIT';
//Financial plan action types:
export const FINANCIAL_PLANS_SET = 'FINANCIAL_PLANS_SET';
export const LOCATION_NAMES_SET = 'LOCATION_NAMES_SET';
export const LOCATION_MAPPED_NAMES_SET = 'LOCATION_MAPPED_NAMES_SET';
export const DETAILED_PLANS_SET = 'DETAILED_PLANS_SET';
export const SAVED_DETAILED_PLAN = 'SAVED_DETAILED_PLAN';
//Supplier articles action types:
export const FETCH_SUPPLIER_ARTICLES = 'FETCH_SUPPLIER_ARTICLES';
//Portal financing action types:
export const PORTAL_FINANCING_CLEAR = 'PORTAL_FINANCING_CLEAR';
export const PORTAL_FINANCING_PERSIST = 'PORTAL_FINANCING_PERSIST';
export const PORTAL_FINANCING_VALIDATED_INFORMATION =
  'PORTAL_FINANCING_VALIDATED_INFORMATION';
export const STEP_LOADING = 'FIRST_STEP_LOADING';
export const STEP_NOT_LOADING = 'STEP_NOT_LOADING';
export const PORTAL_LOADING = 'PORTAL_LOADING';
export const STEP_SUCCESS = 'STEP_SUCCESS';
export const STEP_ERROR = 'STEP_ERROR';
export const TOGGLE_BASE_MODAL = 'TOGGLE_BASE_MODAL';
export const RESET_BASE_MODAL = 'RESET_BASE_MODAL';
export const TOGGLE_ID_MODAL = 'TOGGLE_ID_MODAL';
export const DC_DEBTOR_LOADING = 'DC_DEBTOR_LOADING';
export const DC_COSIGNER_LOADING = 'DC_COSIGNER_LOADING';
export const DC_DEBTOR_ERROR = 'DC_DEBTOR_ERROR';
export const DC_COSIGNER_ERROR = 'DC_COSIGNER_ERROR';
export const RESET_COSIGNER_DATA = 'RESET_COSIGNER_DATA';
export const RESET_PROMISSORY_DATA = 'RESET_PROMISSORY_DATA';
export const HANDLE_ARTICLES = 'HANDLE_ARTICLES';
export const VOUCHER_LOADING = 'VOUCHER_LOADING';
export const AMORTIZATION_LOADING = 'AMORTIZATION_LOADING';
export const CHANGE_AV_QUOTA = 'CHANGE_AV_QUOTA';
export const EXTRA_QUOTAS_SET = 'EXTRA_QUOTAS_SET';
export const RESET_ADD_QUOTAS = 'RESET_ADD_QUOTAS';
export const RESET_MOVE_QUOTA = 'RESET_MOVE_QUOTA';
export const TOGGLE_SCORING_MODAL = 'TOGGLE_SCORING_MODAL';
export const SET_AUTO_SKIP_STEP = 'SET_AUTO_SKIP_STEP';
export const RESTORE_AV_QUOTA = 'RESTORE_AV_QUOTA';
export const SET_DEBTOR_IDENTIFICATION = 'SET_DEBTOR_IDENTIFICATION';
export const RESET_DEBTOR_IS_ON_FACIAL_DB = 'RESET_DEBTOR_IS_ON_FACIAL_DB';
export const SET_DEBTOR_BIRTHDATE = 'SET_DEBTOR_BIRTHDATE';
export const SET_DEBTOR_MOBILE = 'SET_DEBTOR_MOBILE';
export const SET_INVOICE_VAL_STEP_SKIPPED = 'SET_INVOICE_VAL_STEP_SKIPPED';
//Stepper action types:
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_STEPS = 'SET_STEPS';
export const SET_OPTIONAL_STEPS = 'SET_OPTIONAL_STEPS';
export const SET_FAILED_STEPS = 'SET_FAILED_STEPS';
export const STEPPER_NEXT = 'STEPPER_NEXT';
export const STEPPER_PREVIOUS = 'STEPPER_PREVIOUS';
export const STEPPER_SKIP = 'STEPPER_SKIP';
export const RESET_STEPPER = 'RESET_STEPPER';
export const SET_NEXT_STEP = 'SET_NEXT_STEP';
export const STEPPER_PERSIST = 'STEPPER_PERSIST';
export const RESET_NEXT_STEPS = 'RESET_NEXT_STEPS';
export const SET_PROCESS = 'SET_PROCESS';
export const UPDATE_STEPPER_ROUTES = 'UPDATE_STEPPER_ROUTES';
export const SET_ORIGINAL_PROCESS = 'SET_ORIGINAL_PROCESS';
//Comments action types:
export const NEW_COMMENTS = 'NEW_COMMENTS';
export const CLEAR_NEW_COMMENTS = 'CLEAR_NEW_COMMENTS';
//Manual Quota types:
export const SET_MANUAL_QUOTA_LIST = 'SET_MANUAL_QUOTA_LIST';
export const TOGGLE_MANUAL_QUOTA_MODAL = 'TOGGLE_MANUAL_QUOTA_MODAL';
export const MANUAL_QUOTA_CREATE_MODAL = 'MANUAL_QUOTA_CREATE_MODAL';
//File action types:
export const FILE_ERROR_SET = 'FILE_ERROR_SET';
export const FILE_URL_SET = 'FILE_URL_SET';
//Contract action types:
export const CONTRACT_DETAIL_SET = 'CONTRACT_DETAIL_SET';
export const CONTRACT_DETAIL_CLEAR = 'CONTRACT_DETAIL_CLEAR';
//Phone action types:
export const PHONE_INFO_SET = 'PHONE_INFO_SET';
export const PHONE_INFO_CLEAR = 'PHONE_INFO_CLEAR';
//Home visits action types:
export const SET_HOME_VISIT_LIST = 'SET_HOME_VISIT_LIST';
export const UPDATED_HOME_VISIT = 'UPDATED_HOME_VISIT';
export const SET_HOME_VISIT_ARTICLES_LIST = 'SET_HOME_VISIT_ARTICLES_LIST';
//Point user association actions:
export const SET_POINT_USER_ASSOCIATION_LIST =
  'SET_POINT_USER_ASSOCIATION_LIST';
export const UPDATED_POINT_USER_ASSOCIATION = 'UPDATED_POINT_USER_ASSOCIATION';
//Causal action types:
export const SET_CAUSALS = 'SET_CAUSALS';
//Announcement action types:
export const ANNOUNCEMENT_TOGGLE_ACTION = 'ANNOUNCEMENT_TOGGLE_ACTION';
//Motoscoring action types:
export const SET_MOTOSCORING_QUOTA = 'SET_MOTOSCORING_QUOTA';
export const RESET_MOTOSCORING = 'RESET_MOTOSCORING';
export const SET_APPLY_MOTOSCORING = 'SET_APPLY_MOTOSCORING';
//Scoring action types:
export const SET_SCORING_QUOTA = 'SET_SCORING_QUOTA';
export const RESET_SCORING = 'RESET_SCORING';
export const SET_APPLY_SCORING = 'SET_APPLY_SCORING';
export const TELESHOPPING_PERSIST = 'TELESHOPPING_PERSIST';
export const TELESHOPPING_SET_NEXT_STEP = 'TELESHOPPING_SET_NEXT_STEP';
export const TELESHOPPING_STEP_SUCCESS = 'TELESHOPPING_STEP_SUCCESS';
export const TELESHOPPING_TOGGLE_BASE_MODAL = 'TELESHOPPING_TOGGLE_BASE_MODAL';
export const TELESHOPPING_STEP_ERROR = 'TELESHOPPING_STEP_ERROR';
export const TELESHOPPING_CLEAR = 'TELESHOPPING_CLEAR';
//
export const RESULT_CHANGE_PASSWORD = 'RESULT_CHANGE_PASSWORD';
export const RESULT_SPECS_PASSWORD = 'RESULT_SPECS_PASSWORD';

//Insurances action types:
export const SET_INSURANCE_INFO = 'SET_INSURANCE_INFO';
export const CLEAR_INSURANCE_INFO = 'CLEAR_INSURANCE_INFO';
export const SET_INSURANCE_ERROR = 'SET_INSURANCE_ERROR';
export const CLEAR_INSURANCE_ERROR = 'CLEAR_INSURANCE_ERROR';
export const SET_INSURANCE_CONTRACT_VALID = 'SET_INSURANCE_CONTRACT_VALID';
export const SET_INSURANCE_CLIENT_VALID = 'SET_INSURANCE_CLIENT_VALID';
export const INSURANCE_TOGGLE_BASE_MODAL = 'INSURANCE_TOGGLE_BASE_MODAL';
export const INSURANCES_SET = 'INSURANCES_SET';
export const SET_INSURANCE_DETAIL = 'SET_INSURANCE_DETAIL';
export const SET_INSURANCE_CLIENT_DETAIL = 'SET_INSURANCE_CLIENT_DETAIL';
export const SET_INSURANCE_MOVEMENTS_DETAILS =
  'SET_INSURANCE_MOVEMENTS_DETAILS';
export const SET_INSURANCE_CANCELLATION_CAUSALS =
  'SET_INSURANCE_CANCELLATION_CAUSALS';
export const INSURANCE_PERSIST = 'INSURANCE_PERSIST';
export const SET_INSURANCE_AFFILIATES_DETAIL =
  'SET_INSURANCE_AFFILIATES_DETAIL';
export const SET_INSURANCE_SECONDINSURED_DETAIL =
  'SET_INSURANCE_SECONDINSURED_DETAIL';
export const SET_INSURANCE_BENEFICIARIES_DETAIL =
  'SET_INSURANCE_BENEFICIARIES_DETAIL';
//AsignedQuotas action types:
export const SET_ASSIGNED_QUOTAS_LIST_DATA = 'SET_ASSIGNED_QUOTAS_LIST_DATA';
export const OPEN_ASSIGNED_QUOTAS_FORM_MODAL =
  'OPEN_ASSIGNED_QUOTAS_FORM_MODAL';
export const CLOSE_ASSIGNED_QUOTAS_FORM_MODAL =
  'CLOSE_ASSIGNED_QUOTAS_FORM_MODAL';
//Policy paramteres action types:
export const SET_POLICY_PARAMETERS_LIST_DATA =
  'SET_POLICY_PARAMETERS_LIST_DATA';
export const OPEN_POLICY_PARAMETERS_FORM_MODAL =
  'OPEN_POLICY_PARAMETERS_FORM_MODAL';
export const CLOSE_POLICY_PARAMETERS_FORM_MODAL =
  'CLOSE_POLICY_PARAMETERS_FORM_MODAL';
// Segmentation action types:
export const SET_SUBSEGMENTS_LIST_DATA = 'SET_SUBSEGMENTS_LIST_DATA';
export const OPEN_SUBSEGMENTS_FORM_MODAL = 'OPEN_SUBSEGMENTS_FORM_MODAL';
export const CLOSE_SUBSEGMENTS_FORM_MODAL = 'CLOSE_SUBSEGMENTS_FORM_MODAL';
//Scoring Consults List
export const LIST_SCORING_RESPONSES = 'LIST_SCORING_RESPONSES';
// Bulk load action types:
export const BULK_LOAD_SET = 'BULK_LOAD_SET';
//Secure Product Movements action types:
export const SET_SECURE_PRODUCT_MOVEMENTS = 'SET_SECURE_PRODUCT_MOVEMENTS';
export const SET_PRODUCT_IDS = 'SET_PRODUCT_IDS';
// Identity Otp action types:
export const RESET_IDENTITY_OTP_CODE = 'RESET_IDENTITY_OTP_CODE';

export const SET_MEGA_QUOTA = 'SET_MEGA_QUOTA';
export const RESET_MEGA_QUOTA = 'RESET_MEGA_QUOTA';
// My promissories v2 data
export const OTP_SEND_TRANSACTION_ID = 'OTP_SEND_TRANSACTION_ID';
export const OTP_RE_SEND_TRANSACTION_ID = 'OTP_RE_SEND_TRANSACTION_ID';

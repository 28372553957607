import moment from 'moment';
import formatMoney from '../../../helpers/formatMoney';

export const columns = [
  {
    title: 'Fecha de Consulta',
    name: 'date'
  },
  {
    title: 'Cupo total',
    name: 'totalQuota'
  },
  {
    title: 'Cupo scoring',
    name: 'finalValue'
  },
  {
    title: 'Identificación',
    name: 'identification'
  },
  {
    title: 'Contrato',
    name: 'contract'
  },
  {
    title: 'Celular',
    name: 'mobile'
  }
];

export const mapData = data =>
  data.map(datum => {
    return {
      ...datum,
      date: moment(datum.solicitudeDate).format('DD/MM/YYYY HH:mm') || '--',
      totalQuota: formatMoney(datum.totalQuota) || '--',
      finalValue: formatMoney(datum.grantedQuota) || '--',
      identification: datum.identification || '--',
      contract: datum.contractId || '--',
      mobile: datum.mobile || '--'
    };
  });

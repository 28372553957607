import {
  ARTICLES_SET,
  SAVED_ARTICLE
} from '../constants/action_constants';
import { newAlert, clearAlerts, serverStatusError } from './alert_actions';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest, errorOnRequest } from './request_state';
import {
  articleFilterSanitizer,
  articleFilterSanitizerNode,
  articleSaveSanitizer
} from '../helpers/sanitizers/articleSanitizer';
import AxiosRequest from '../services/axios_request';
import MIMETYPES from '../constants/mimetypes';

const requestNodeService = new RequestService('node');

//* PURE ACTION FUNCTIONS
function success(data, meta) {
  return {
    type: ARTICLES_SET,
    data,
    meta
  };
}
function savedArticle() {
  return {
    type: SAVED_ARTICLE
  };
}

//* VIEW ACTION FUNCTIONS
export const fetchArticles = (params = {}) => async dispatch => {
  dispatch(sendRequest('ArticleList'));
  try {
    const payload = await requestNodeService.get(
      'articles',
      articleFilterSanitizerNode(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
  } finally {
    dispatch(completeRequest('ArticleList'));
  }
};

export const createArticle = (params = {}, queryParams) => async dispatch => {
  dispatch(sendRequest('SaveArticle'));
  try {
    const payload = await requestNodeService.post(
      'articles',
      articleSaveSanitizer(params),
      {}
    );
    dispatch(savedArticle());
    dispatch(fetchArticles(queryParams));
    dispatch(newAlert('success', 's', payload.data.message));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(errorOnRequest('SaveArticle', e.errors));
      dispatch(clearAlerts());
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('SaveArticle'));
  }
};

export const updateArticle = (params = {}, queryParams) => async dispatch => {
  dispatch(sendRequest('SaveArticle'));
  try {
    await requestNodeService.put(`articles/${params.id}`, articleSaveSanitizer(params), {});
    dispatch(savedArticle());
    dispatch(fetchArticles(queryParams));
  } catch (errors) {
    const e = await errors.json();
    if (e.errors) {
      dispatch(errorOnRequest('SaveArticle', e.errors[0]));
      dispatch(clearAlerts());
    } else {
      dispatch(
        newAlert(
          'error',
          'ERROR:',
          'Hubo un error procesando su solicitud, por favor comuniquese con sistemas.'
        )
      );
    }
  } finally {
    dispatch(completeRequest('SaveArticle'));
  }
};

export const fetchArticleListExcelReportFile = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest('ArticlesListExcelReportFile'));
  const axiosRequest = new AxiosRequest();
  try {
    const payload = await axiosRequest.get(
      'articles/excel_reports/index',
      articleFilterSanitizer(params, true),
      {
        responseType: 'blob'
      }
    );
    const file = new Blob([payload], { type: MIMETYPES['xlsx'] });
    const fileUrl = URL.createObjectURL(file);
    window.location.href = fileUrl;
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest('ArticlesListExcelReportFile'));
  }
};

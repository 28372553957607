export const statusOptions = [
  { value: 'all', title: 'Todas' },
  { value: 'true', title: 'Activas' },
  { value: 'false', title: 'Inactivas' }
];

export const commissionTypes = [
  { value: 'all', title: 'Todas' },
  { value: 'sale', title: 'Venta' },
  { value: 'advertisement', title: 'Publicidad' }
];